/* @flow */

import * as React from "react"
import cn from "classnames"
import Text from "components/Text"
import Button from "components/Button"
import Icon from "components/Icon"
import styles from "./styles.module.scss"

export type Props = {
  borderColor: "primary" | "grey",
  borderStyle: string,
  buttonText: string,
  descriptionText: string,
  headerText: string,
  iconName: string | null,
  imageUrl: string | null,
  onClick: () => void,
}

export default function WidgetBoxBase(props: Props): React.Element<"div"> {
  return (
    <div className={cn(styles.box, styles[`border-${props.borderColor}`])} style={{ borderStyle: props.borderStyle }}>
      {props.imageUrl && <img alt="empty" className={styles.img} src={props.imageUrl} />}
      {props.iconName && (
        <span className={styles.icon}>
          <Icon color="grey" size="xl" type={props.iconName} />
        </span>
      )}
      <div className={styles.details}>
        <Text bold color="text" type="pageHeader">
          {props.headerText}
        </Text>
        <div className={styles.description}>
          <Text color="lightest" type="small">
            {props.descriptionText}
          </Text>
        </div>
        <Button label={props.buttonText} onClick={props.onClick} type="theme" />
      </div>
    </div>
  )
}

WidgetBoxBase.defaultProps = {
  borderStyle: "solid",
  borderColor: "primary",
  iconName: null,
  imageUrl: null,
}
