/* @flow */

import moment from "moment"
import _, { flatten, sortBy, uniqBy } from "lodash"

import { createSelector } from "reselect"
import type { ShiftSummary } from "time_off/Modal/types"
import * as Shift from "rosters/overview/models/shift"
import { type Schema as Modals } from "rosters/overview/models/modal"
import * as DemandData from "rosters/overview/models/demandData"
import * as Time from "helpers/time"
import * as C from "rosters/WebpackRosters/consts"
import type {
  DepartmentWithStatsOnDateType,
  DepartmentWithStatsType,
  DepartmentType,
  CognitiveSettingsType,
  BusinessHoursConfig,
  BusinessHoursType as DayViewBusinessHoursType,
} from "day_view/types"
import type { GenericChartDataType, ChartTypes } from "components/Chart/types"
import DayViewConstants from "day_view/helpers/constants"
import { FINISH_TIME, START_TIME } from "time_off/Modal/edit_daily_breakdown/helpers/constants"
import { t as globalT } from "helpers/i18n"
import * as User from "rosters/overview/models/static/user"
import * as Schedule from "rosters/overview/models/schedule"
import * as Team from "rosters/overview/models/static/team"
import * as Cognitive from "rosters/overview/models/cognitive"
import { TEMPLATE_TYPE } from "rosters/overview/models/template"
import { merge_and_add_stats } from "rosters/WebpackRosters/DemandData/helpers"
import { getAICLength } from "rosters/overview/models/aic"
import type {
  ScheduleType,
  GlobalState,
  UserToDepartmentMap,
  RowType,
  RDORubyType,
  DateData,
  GroupType,
  SortStaffOptions,
  DefaultValidationFieldSettings,
  CustomValidationSettings,
  CustomValidationObject,
  UserCustomValidationMap,
  UserType,
  SelectedScheduleContainerDataType,
  RequestsInProgressType,
  UserQualificationRubyType,
  UserTrainingLogRubyType,
  PublishedScheduleType,
  QualificationRubyType,
  DepartmentQualificationRubyType,
  ScheduleDataUserCache,
  GroupInfoType,
  SelectOption,
  ShiftDetailRubyType,
  DailyScheduleType,
  UserDailyScheduleJoinType,
  UserDailyScheduleJoinRubyType,
  RosterValidationType,
  ScheduleStats,
  AllowanceType,
  CommentRubyType,
  StatType,
  ShiftType,
  ValidationErrorType,
  VisibleHoursType,
  AwardType,
  CrossDepartmentProficiency,
  ScheduleValidationType,
  TemplateRubyType,
  SimpleScheduleType,
  LocationRubyType,
  StatFormatFunc,
  ShiftSlotType,
  CognitiveCreatorConfigurationType,
  LeaveRequestRubyType,
  AICRubyType,
  CognitiveDemandConfigType,
  CognitiveDemandConfigMomentizedType,
  BusinessHoursType,
  TeamGroupType,
  CustomEventRubyType,
  AllDataStreamData,
  UnavailabilityRubyType,
  SpanOfHoursType,
  TeamRubyType,
  OncostConfigurationType,
  DataStreamJoinRubyType,
  HeadCountMapRubyType,
  DataStreamRubyType,
  TeamGroupRubyType,
  PublicHolidayType,
  SalesTargetType,
  WeatherType,
  TemplateUserJoinRubyType,
  ScheduleSwapPlanRubyType,
  RosterRubyType,
  LocationDataCache,
  PredictionModifierType,
  EarningsThresholdPeriodType,
  PositionType,
  PositionGroupType,
  EmploymentConditionSetRubyType,
} from "../types"
import type { PayCheckType } from "../models/pay_checks"
import * as HelperFunc from "./functions"
import * as Constants from "./constants"
import * as ValidationHelpers from "./validations/validationHelpers"
import * as RuledValidations from "./validations/ruledValidations"

const t = (key, ...args) => globalT(`js.rosters.rosters_overview.${key}`, ...args)
const EMPTY_ARR = []

// https://stackoverflow.com/questions/8086375/what-character-to-use-to-put-an-item-at-the-end-of-an-alphabetic-list
const LAST_LETTER_IN_UNICODE_SORT = ""

export const rostersByTemplate: (state: GlobalState) => { [template_id: string]: Array<RosterRubyType> } =
  createSelector(
    (state) => state.rosters.rosters,
    (rosters: Array<RosterRubyType>) => _.groupBy(rosters, (r) => String(r.roster_template_id))
  )

export const rosterTemplates: (state: GlobalState) => Array<TemplateRubyType> = createSelector(
  (state) => state.templates,
  (templates: Array<TemplateRubyType>) => templates.filter((t) => t.template_type === TEMPLATE_TYPE.RosterTemplate)
)

export const templateByID: (state: GlobalState) => { [template_id: string]: TemplateRubyType } = createSelector(
  (state) => state.templates,
  (templates: Array<TemplateRubyType>) =>
    _.mapValues(
      _.groupBy(templates, (t) => t.id),
      (ts) => ts[0]
    )
)

export const getRHoW: (state: GlobalState) => Array<TemplateRubyType> = createSelector(
  (state) => state.templates,
  (templates: Array<TemplateRubyType>) => templates.filter((t) => t.template_type === TEMPLATE_TYPE.RegularHours)
)

export const templateUserJoinsByEmploymentConditionSetId: (state: GlobalState) => {
  [employment_contract_id: string]: TemplateUserJoinRubyType,
} = createSelector(
  (state) => state.template_user_joins,
  (template_user_joins: Array<TemplateUserJoinRubyType>) =>
    _.mapValues(
      _.groupBy(template_user_joins, (template_join) => String(template_join.employment_contract_id)),
      (template_user_joins) => template_user_joins[0] // there should only be one template user join per employment condition set
    )
)
export const templateUserJoinsByTemplate: (state: GlobalState) => {
  [template_id: string]: Array<TemplateUserJoinRubyType>,
} = createSelector(
  (state) => state.template_user_joins,
  (template_joins: Array<TemplateUserJoinRubyType>) => _.groupBy(template_joins, (tj) => tj.roster_template_id)
)
export const templatesJoinsByUser: (state: GlobalState) => { [user_id: string]: Array<TemplateUserJoinRubyType> } =
  createSelector(
    (state) => state.template_user_joins,
    (template_joins: Array<TemplateUserJoinRubyType>) => _.groupBy(template_joins, (tj) => tj.user_id)
  )
export const templatesByUser: (state: GlobalState) => { [template_id: string]: Array<TemplateRubyType> } =
  createSelector(
    templatesJoinsByUser,
    templateByID,
    (
      template_joins_by_user: { [template_id: string]: Array<TemplateUserJoinRubyType> },
      template_by_id: { [template_id: string]: TemplateRubyType }
    ) =>
      _.mapValues(template_joins_by_user, (template_joins) =>
        template_joins.map((tj) => template_by_id[String(tj.roster_template_id)]).filter(Boolean)
      )
  )

export const getRHoWByUser: (state: GlobalState) => { [user_id: string]: Array<TemplateRubyType> } = createSelector(
  templatesByUser,
  (templates_by_user: { [template_id: string]: Array<TemplateRubyType> }) =>
    _.mapValues(templates_by_user, (templates) =>
      templates.filter((t) => t.template_type === TEMPLATE_TYPE.RegularHours)
    )
)

export const getRosters: (state: GlobalState) => Array<RosterRubyType> = createSelector(
  (state) => state.settings.template_id,
  (state) => state.rosters.rosters,
  (template_id: ?number, rosters: Array<RosterRubyType>) =>
    rosters.filter((r) => r.roster_template_id === template_id && (template_id != null || r.template === false))
)

export const getLeaveRequests: (state: GlobalState) => Array<LeaveRequestRubyType> = createSelector(
  (state) => state.settings.template_id,
  (state) => state.time_off.leave_requests,
  (template_id: ?number, leave_requests: Array<LeaveRequestRubyType>) => (template_id == null ? leave_requests : [])
)

export const getUnavailability: (state: GlobalState) => Array<UnavailabilityRubyType> = createSelector(
  (state) => state.settings.template_id,
  (state) => state.time_off.unavailability,
  (template_id: ?number, unavailability: Array<UnavailabilityRubyType>) => (template_id == null ? unavailability : [])
)

export const getRDOs: (state: GlobalState) => Array<RDORubyType> = createSelector(
  (state) => state.settings.template_id,
  (state) => state.rdos,
  (template_id: ?number, rdos: Array<RDORubyType>) => (template_id == null ? rdos : [])
)

export const getCurrentTemplate: (state: GlobalState) => ?TemplateRubyType = createSelector(
  (state) => state.settings.template_id,
  (state) => state.templates,
  (template_id: ?number, templates: Array<TemplateRubyType>) => templates.find((t) => t.id === template_id)
)

export const getCurrentTemplateRange: (state: GlobalState) => ?Array<moment> = createSelector(
  getCurrentTemplate,
  (template: ?TemplateRubyType) =>
    template == null
      ? null
      : HelperFunc.getAllDates(
          moment(template.start_date, C.DATE_FMT),
          moment(template.start_date, C.DATE_FMT).add(template.length - 1, "days")
        )
)

export const getCurrentTemplateRangeStr: (state: GlobalState) => ?Array<string> = createSelector(
  getCurrentTemplate,
  (template: ?TemplateRubyType) =>
    template == null
      ? null
      : HelperFunc.getAllDatesStr(
          moment(template.start_date, C.DATE_FMT),
          moment(template.start_date, C.DATE_FMT).add(template.length - 1, "days")
        )
)

export const dailySchedulesByTemplate: (state: GlobalState) => { [template_id: string]: Array<DailyScheduleType> } =
  createSelector(
    rostersByTemplate,
    (state) => state.rosters.daily_schedules,
    (
      rosters_by_template_id: { [template_id: string]: Array<RosterRubyType> },
      daily_schedules: Array<DailyScheduleType>
    ) =>
      _.mapValues(rosters_by_template_id, (rosters) => {
        const r_ids = rosters.map((r) => r.id)
        return daily_schedules.filter((ds) => r_ids.includes(ds.roster_id))
      })
  )

export const getDailySchedules: (state: GlobalState) => Array<DailyScheduleType> = createSelector(
  getRosters,
  (state) => state.rosters.daily_schedules,
  (rosters: Array<RosterRubyType>, daily_schedules: Array<DailyScheduleType>) => {
    const ids = rosters.map((r) => r.id)
    return daily_schedules.filter((ds) => ids.includes(ds.roster_id))
  }
)

export const dailySchedulesByID: (state: GlobalState) => { [ds_id: string]: DailyScheduleType } = createSelector(
  getDailySchedules,
  (daily_schedules: Array<DailyScheduleType>) =>
    _.mapValues(
      _.groupBy(daily_schedules, (ds) => ds.id),
      (dss) => dss[0]
    )
)

export const dateByDailyScheduleID: (state: GlobalState) => { [ds_id: string]: string } = createSelector(
  dailySchedulesByID,
  (daily_schedules_by_id: { [ds_id: string]: DailyScheduleType }) =>
    _.mapValues(daily_schedules_by_id, (dss) => dss.date)
)

export const allAwardTags: (state: GlobalState) => Array<string> = createSelector(
  (state) => state.static.users,
  (users: Array<UserType>) => _.uniq(_.flatten(users.map((u) => u.award_tags)))
)

export const getSchedules: (state: GlobalState) => Array<ScheduleType> = createSelector(
  (state) => state.schedules,
  (state) => state.settings.print_mode,
  getDailySchedules,
  (schedules: Array<ScheduleType>, print_mode: boolean, dss: Array<DailyScheduleType>) => {
    const ds_ids: Array<number> = dss.map((ds) => ds.id)
    const scheds = schedules.filter((s) => ds_ids.includes(s.daily_schedule_id))
    // Filter out vacant schedules with no times and no team when printing
    return print_mode
      ? scheds.filter(
          (s) =>
            s.user_id !== Constants.DEFAULT_USER.id ||
            (s.start != null && s.finish != null && s.department_id !== Constants.DEFAULT_TEAM.id)
        )
      : scheds
  }
)

export const getSchedulesForAutoBuildPeriod: (
  state: GlobalState,
  start_date: moment,
  finish_date: moment
) => Array<ScheduleType> = createSelector(
  (state, _) => state.schedules,
  (state, _) => state.settings.print_mode,
  (state, _) => state.rosters.daily_schedules,
  (state, start_date, _) => start_date,
  (state, _, finish_date) => finish_date,
  (state, _) => state.config.roster_week_start_day,
  (
    schedules: Array<ScheduleType>,
    print_mode: boolean,
    dss: Array<DailyScheduleType>,
    start_date: moment,
    finish_date: moment,
    week_start: number
  ) => {
    const start = start_date.clone().day(week_start - 1)
    const finish = finish_date.clone().day(week_start + 7)

    const preciseDates = _.range(finish.diff(start, "days") + 1).map((offset) =>
      start.clone().add({ days: offset }).format(Time.Formats.Date)
    )
    const dailySchedulesRange = dss.filter((ds) => preciseDates.includes(ds.date))

    const ds_ids: Array<number> = dailySchedulesRange.map((ds) => ds.id)
    const scheds = schedules.filter((s) => ds_ids.includes(s.daily_schedule_id))
    // Filter out vacant schedules with no times and no team when printing
    return print_mode
      ? scheds.filter(
          (s) =>
            s.user_id !== Constants.DEFAULT_USER.id ||
            (s.start != null && s.finish != null && s.department_id !== Constants.DEFAULT_TEAM.id)
        )
      : scheds
  }
)

export const getShifts: (state: GlobalState) => Array<ShiftType> = createSelector(
  (state) => state.shifts,
  (shifts: Array<ShiftType>) => shifts
)

export const getLeaveShifts: (state: GlobalState) => Array<ShiftType> = createSelector(
  getShifts,
  (shifts: Array<ShiftType>) => shifts.filter((s) => s.leave_request_id != null)
)

export const getScheduleSwapPlans: (state: GlobalState) => Array<ScheduleSwapPlanRubyType> = createSelector(
  (state) => state.schedule_swap_plans,
  (scheduleSwapPlans: Array<ScheduleSwapPlanRubyType>) => scheduleSwapPlans
)

export const scheduleSwapPlanByScheduleId: (state: GlobalState) => {
  [schedule_id: string]: Array<ScheduleSwapPlanRubyType>,
} = createSelector(getScheduleSwapPlans, (scheduleSwapPlans: Array<ScheduleSwapPlanRubyType>) =>
  _.groupBy(scheduleSwapPlans, (s) => s.schedule_id)
)

export const depsThatUsersCanWorkIn: (state: GlobalState) => { [user_id: string]: Array<number> } = createSelector(
  (state) => state.config.deps_users_can_work_in,
  (state) => state.static.teams,
  (deps_users_can_work_in: Array<UserToDepartmentMap>, teams: Array<TeamRubyType>) => {
    const obj: { [user_id: string]: Array<number> } = _.mapValues(
      _.groupBy(deps_users_can_work_in, (d) => d.user_id),
      (rec: Array<UserToDepartmentMap>) => rec.map((r) => r.team_id)
    )
    return {
      ..._.mapValues(obj, (v: Array<number>) => [...v, Constants.DEFAULT_TEAM.id]),
      [Constants.DEFAULT_USER.id]: [...teams.map((t) => t.id), Constants.DEFAULT_TEAM.id],
    }
  }
)

export const teamByID: (state: GlobalState) => { [team_id: string]: TeamRubyType } = createSelector(
  (state) => state.static.teams,
  (teams: Array<TeamRubyType>) =>
    _.mapValues(
      _.groupBy(teams, (t) => t.id),
      (ts) => ts[0]
    )
)

export const managedTeams: (state: GlobalState) => Array<TeamRubyType> = createSelector(
  (state) => state.config.managed_team_ids,
  teamByID,
  (managed_team_ids: Array<number>, team_by_id: { [team_id: string]: TeamRubyType }) =>
    managed_team_ids.map((t_id) => team_by_id[String(t_id)]).filter(Boolean)
)

export const viewableTeams: (state: GlobalState) => Array<TeamRubyType> = createSelector(
  (state) => state.config.enable_full_location_rosters,
  (state) => state.static.teams,
  managedTeams,
  (enable_full_location_rosters: boolean, teams: Array<TeamRubyType>, managed_teams: Array<TeamRubyType>) =>
    enable_full_location_rosters ? teams : managed_teams
)

export const teamsByLocation: (state: GlobalState) => { [loc_id: string]: Array<TeamRubyType> } = createSelector(
  (state) => state.static.teams,
  (teams: Array<TeamRubyType>) => _.groupBy(teams, (t) => t.location_id)
)

export const viewableTeamsByLocation: (state: GlobalState) => { [loc_id: string]: Array<TeamRubyType> } =
  createSelector(viewableTeams, (teams: Array<TeamRubyType>) => _.groupBy(teams, (t) => t.location_id))

export const allLocationIds: (state: GlobalState) => Array<number> = createSelector(
  (state) => state.static.locations,
  (locations: Array<LocationRubyType>) => locations.map((l) => l.id)
)

export const getCurrentLocationsTeams: (state: GlobalState) => Array<TeamRubyType> = createSelector(
  (state) => state.settings.selected_location_ids,
  teamsByLocation,
  (loc_ids: Array<number>, loc_to_teams: { [loc_id: string]: Array<TeamRubyType> }) =>
    _.flatten(loc_ids.map((loc_id) => loc_to_teams[String(loc_id)] || []))
)

export const demandConfigByID: (state: GlobalState) => { [id: string]: CognitiveDemandConfigType } = createSelector(
  (state) => state.cognitive.demand_config,
  (dcs: Array<CognitiveDemandConfigType>) =>
    _.mapValues(
      _.groupBy(dcs, (dc) => dc.id),
      (dc) => dc[0]
    )
)

export const getCurrentLocationsTeamsIds: (state: GlobalState) => Array<number> = createSelector(
  getCurrentLocationsTeams,
  (current_location_teams: Array<TeamRubyType>) => current_location_teams.map((t) => t.id)
)

export const archivedTeamByID: (state: GlobalState) => { [team_id: string]: TeamRubyType } = createSelector(
  (state) => state.static.archived_teams,
  (teams: Array<TeamRubyType>) =>
    _.mapValues(
      _.groupBy(teams, (t) => t.id),
      (ts) => ts[0]
    )
)

export const dailyScheduleByID: (state: GlobalState) => { [id: string]: DailyScheduleType } = createSelector(
  getDailySchedules,
  (daily_schedules: Array<DailyScheduleType>) =>
    _.mapValues(
      _.groupBy(daily_schedules, (ds) => ds.id),
      (dss) => dss[0]
    )
)

export const getVisibleTeams: (state: GlobalState) => Array<TeamRubyType> = createSelector(
  (state) => state.settings.selected_team_ids,
  teamByID,
  (team_ids: Array<number>, team_by_id: { [team_id: string]: TeamRubyType }) =>
    Team.sort(team_ids.map((t) => team_by_id[String(t)]).filter(Boolean))
)

export const getVisibleTeamsWithDefault: (state: GlobalState) => Array<TeamRubyType> = createSelector(
  getVisibleTeams,
  (teams: Array<TeamRubyType>) => [...teams, Constants.DEFAULT_TEAM]
)

export const getVisibleTeamIds: (state: GlobalState) => Array<number> = createSelector(
  getVisibleTeams,
  (teams: Array<TeamRubyType>) => teams.map((t) => t.id)
)

export const getVisibleTeamIdsWithDefault: (state: GlobalState) => Array<number> = createSelector(
  getVisibleTeamsWithDefault,
  (teams: Array<TeamRubyType>) => teams.map((t) => t.id)
)

export const getFilterLocationsAndTeamsForURL: (state: GlobalState) => {
  location_ids: Array<number>,
  team_ids: Array<number>,
} = createSelector(
  getVisibleTeams,
  teamsByLocation,
  (teams: Array<TeamRubyType>, teamsByLocation: { [loc_id: string]: Array<TeamRubyType> }) => {
    const filteredTeamsByLocation = _.groupBy(teams, (t) => t.location_id)
    const pairs = _.toPairs(filteredTeamsByLocation)
    const filtered = pairs.map(([location_id, teams]) =>
      (teamsByLocation[location_id] || []).length === teams.length ? [location_id, []] : [location_id, teams]
    )
    const all_loc_ids = filtered.map(([location_id, teams]) => location_id).map(Number)
    const all_team_ids = filtered.flatMap(([location_id, teams]) => teams).map((t) => t.id)
    return {
      location_ids: all_loc_ids,
      team_ids: all_team_ids,
    }
  }
)

export const getUsers: (state: GlobalState) => Array<UserType> = createSelector(
  (state) => state.config.managed_team_ids,
  (state) => state.static.users,
  (state) => state.view_options.custom_sort.user,
  depsThatUsersCanWorkIn,
  getVisibleTeams,
  (state) => state.settings.user_sort_order,
  (
    managed_team_ids: Array<number>,
    users: Array<UserType>,
    custom_sort: { [u_id: string]: ?number },
    deps_users_can_work_in: { [user_id: string]: Array<number> },
    loc_teams: Array<TeamRubyType>,
    user_sort_order: { [user_id: string]: ?number }
  ) =>
    _.sortBy(
      users.map((user) => {
        const formatted_user = {
          ...user,
          sort_order: custom_sort[String(user.id)] != null ? custom_sort[String(user.id)] : user.sort_order,
        }

        const loc_team_ids = loc_teams.map((t) => t.id)
        const can_work_in_reporting_team_and_team_is_manageable =
          (deps_users_can_work_in[String(formatted_user.id)] || []).includes(formatted_user.report_department_id) &&
          managed_team_ids.includes(formatted_user.report_department_id)

        if (
          can_work_in_reporting_team_and_team_is_manageable &&
          loc_team_ids.includes(Number(formatted_user.report_department_id))
        ) {
          return formatted_user
        }

        const teams_which_are_manageable_and_also_workable = (
          deps_users_can_work_in[String(formatted_user.id)] || []
        ).filter((d) => managed_team_ids.includes(d))
        const teams_which_are_manageable_and_also_workable_and_in_current_loc =
          teams_which_are_manageable_and_also_workable.filter((d) => loc_team_ids.includes(d))
        const new_default_team: number =
          teams_which_are_manageable_and_also_workable_and_in_current_loc[0] ||
          teams_which_are_manageable_and_also_workable[0] ||
          Constants.DEFAULT_TEAM.id

        return {
          ...formatted_user,
          report_department_id: new_default_team,
        }
      }),
      (user) => {
        const name = user.id !== Constants.DEFAULT_USER.id ? user.name : LAST_LETTER_IN_UNICODE_SORT

        return String(user_sort_order[String(user.id)] || 0) + name
      }
    )
)

export const userIdsThatWorkInDeps: (state: GlobalState) => { [dep_id: string]: Array<number> } = createSelector(
  (state) => state.config.deps_users_can_work_in,
  getUsers,
  (deps_users_can_work_in: Array<UserToDepartmentMap>, users: Array<UserType>) => {
    const obj: { [dep_id: string]: Array<number> } = _.mapValues(
      _.groupBy(deps_users_can_work_in, (d) => d.team_id),
      (rec: Array<UserToDepartmentMap>) => rec.map((r) => r.user_id)
    )
    return {
      ...obj,
      [Constants.DEFAULT_TEAM.id]: users.map((u) => u.id),
    }
  }
)

export const positionIdsGroupedByPositionGroupId: (state: GlobalState) => {
  [position_group_id: string]: Array<string>,
} = createSelector(
  (state) => state.static.positions,
  (positions: Array<PositionType>) => {
    const noPositionGroup = { "-1": ["-1"] }
    return _.merge(
      _.mapValues(
        _.groupBy(positions, ({ id, position_group_id }) =>
          !position_group_id ? String("no-position-group" + id) : String(position_group_id)
        ),
        (result: Array<PositionType>) => result.map((pos) => String(pos.id))
      ),
      noPositionGroup
    )
  }
)

export const userIdsByPositionId: (state: GlobalState) => { [position_id: string]: Array<string> } = createSelector(
  (state) => state.employment_condition_sets,
  (employment_condition_sets: Array<EmploymentConditionSetRubyType>) => {
    const position_id_to_user_ids = _.mapValues(
      _.groupBy(employment_condition_sets, ({ position_id }) => (!position_id ? "-1" : String(position_id))),
      (result: Array<EmploymentConditionSetRubyType>) => result.map((emp_cnd) => String(emp_cnd.user_id))
    )
    const position_id_to_unique_user_ids: { [position_id: string]: Array<string> } = _.mapValues(
      position_id_to_user_ids,
      (result: Array<string>) => [...new Set(result)]
    )
    return position_id_to_unique_user_ids
  }
)

export const userIdsByPositionGroupId: (state: GlobalState) => { [position_group_id: string]: Array<string> } =
  createSelector(
    positionIdsGroupedByPositionGroupId,
    userIdsByPositionId,
    (
      position_grouped_by_group_id: { [position_group_id: string]: Array<string> },
      user_ids_by_position_id: { [position_id: string]: Array<string> }
    ) =>
      _.mapValues(position_grouped_by_group_id, (positionIds: Array<string>) =>
        _.flatMap(positionIds, (positionId) => user_ids_by_position_id[positionId] || [])
      )
  )

export const usersThatHavePositions: (state: GlobalState) => { [position_id: string]: Array<UserType> } =
  createSelector(
    userIdsByPositionGroupId,
    getUsers,
    (user_ids_by_position_ids: { [position_id: string]: Array<string> }, users: Array<UserType>) => {
      const obj: { [position_id: string]: Array<UserType> } = _.mapValues(
        user_ids_by_position_ids,
        (result: Array<string>) => result.map((user_id: string) => users.find(({ id }) => id === Number(user_id)))
      )
      return obj
    }
  )

export const isDayView: (state: GlobalState) => boolean = createSelector(
  (state) => state.settings.start_date,
  (state) => state.settings.finish_date,
  (start_date, finish_date) => start_date.isSame(finish_date, "day")
)

export const allVisibleDates: (state: GlobalState) => Array<moment> = createSelector(
  (state) => state.settings.start_date,
  (state) => state.settings.finish_date,
  (state) => state.config.show_weekends,
  (start_date, finish_date, show_weekends) => {
    const dates = _.range(0, finish_date.diff(start_date, "days") + 1, 1).map((i) => start_date.clone().add(i, "days"))
    // Filters out weekends if the config doesn't want them
    const weekend_filter_dates = dates.filter((d) => d.isoWeekday() < 6 || show_weekends)
    // Only return the filtered dates if there are any
    return weekend_filter_dates.length > 1 ? weekend_filter_dates : dates
  }
)

export const allVisibleDatesStr: (state: GlobalState) => Array<string> = createSelector(
  allVisibleDates,
  (dates: Array<moment>) => dates.map((d) => d.format(C.DATE_FMT))
)

export const allVisibleDatesStrToMoment: (context: GlobalState) => { [date_str: string]: moment } = createSelector(
  allVisibleDates,
  (dates: Array<moment>) => dates.reduce((acc, d) => ({ ...acc, [d.format(C.DATE_FMT)]: d }), ({}: $Shape<{||}>))
)

export const publicHolidaysForCurrentLocations: (state: GlobalState) => Array<string> = createSelector(
  (state) => state.settings.selected_location_ids,
  (state) => state.config.public_holidays,
  (loc_ids: Array<number>, public_holidays: PublicHolidayType) =>
    _.uniq([...public_holidays.all, ..._.flatten(loc_ids.map((loc_id) => public_holidays[String(loc_id)] || []))])
)
export const weatherWithCombinedData: (state: GlobalState) => WeatherType = createSelector(
  (state) => state.weather,
  (weather: Array<WeatherType>) =>
    _.mapValues(
      _.groupBy(weather, (w) => w.location_id),
      (ws) =>
        _.mapValues(
          _.groupBy(ws, (w) => w.date),
          (ws_for_loc) => ws_for_loc[0]
        )
    )
)

export const rawWeatherByDateByLocation: (state: GlobalState) => {
  [location_id: string]: { [date: string]: WeatherType },
} = createSelector(
  (state) => state.weather,
  (weather: Array<WeatherType>) =>
    _.mapValues(
      _.groupBy(weather, (w) => w.location_id),
      (ws) =>
        _.mapValues(
          _.groupBy(ws, (w) => w.date),
          (ws_for_loc) => ws_for_loc[0]
        )
    )
)

// We sneakily add the next days data into each object here.
// This is so that we can have weather data going into the next morning for overnight businesses
export const weatherByDateByLocation: (state: GlobalState) => {
  [location_id: string]: { [date: string]: WeatherType },
} = createSelector(
  rawWeatherByDateByLocation,
  (weatherByDateByLoc: { [location_id: string]: { [date: string]: WeatherType } }) =>
    _.mapValues(weatherByDateByLoc, (weatherByDate: { [date: string]: WeatherType }) =>
      _.mapValues(weatherByDate, (weather: WeatherType, date: string) => {
        const nextDate = HelperFunc.addDaysToDate(date, 1)
        const nextData = weatherByDate[nextDate]
        return {
          ...weather,
          data: {
            ...weather.data,
            hourly: {
              ...weather.data.hourly,
              data: [...weather.data.hourly.data, ...(nextData?.data?.hourly?.data || [])],
            },
          },
        }
      })
    )
)

export const weatherByDateForCurrentLocation: (state: GlobalState) => { [date: string]: WeatherType } = createSelector(
  (state) => state.settings.selected_location_ids,
  weatherByDateByLocation,
  (loc_ids: Array<number>, weather_by_date_by_loc: { [location_id: string]: { [date: string]: WeatherType } }) =>
    loc_ids.length > 1 ? {} : weather_by_date_by_loc[String(loc_ids[0])] || {}
)

export const publicHolidaysForCurrentLocationsAndVisibleDates: (state: GlobalState) => Array<string> = createSelector(
  publicHolidaysForCurrentLocations,
  allVisibleDatesStr,
  (public_holidays: Array<string>, dates: Array<string>) => _.uniq(public_holidays.filter((ph) => dates.includes(ph)))
)

export const publicHolidaysForCurrentLocationsByDate: (state: GlobalState) => { [date: string]: Array<string> } =
  createSelector(publicHolidaysForCurrentLocations, (public_holidays: Array<string>) =>
    _.groupBy(public_holidays, (d) => d)
  )

export const locationOptions: (state: GlobalState) => $ReadOnlyArray<SelectOption> = createSelector(
  (state) => state.static.locations,
  (locations: Array<LocationRubyType>) =>
    _.sortBy(
      locations.map((l) => ({ label: l.name, value: l.id })),
      (lo) => lo.label
    )
)

export const locationByID: (state: GlobalState) => { [loc_id: string]: LocationRubyType } = createSelector(
  (state) => state.static.locations,
  (locations: Array<LocationRubyType>) =>
    _.mapValues(
      _.groupBy(locations, (l) => l.id),
      (ls) => ls[0]
    )
)

export const getCurrentLocations: (state: GlobalState) => Array<LocationRubyType> = createSelector(
  (state) => state.settings.selected_location_ids,
  locationByID,
  (loc_ids: Array<number>, loc_by_id: { [loc_id: string]: LocationRubyType }) => {
    const locs = loc_ids.map((loc_id) => loc_by_id[String(loc_id)] || Constants.DEFAULT_LOCATION)
    if (locs.length === 0) {
      return [Constants.DEFAULT_LOCATION]
    } else {
      return locs
    }
  }
)

export const getVisibleLocations = getCurrentLocations

export const getVisibleLocationsWithDefault: (state: GlobalState) => Array<LocationRubyType> = createSelector(
  getCurrentLocations,
  (locations: Array<LocationRubyType>) => [...locations, Constants.DEFAULT_LOCATION]
)

export const allLocationsWithDefault: (state: GlobalState) => Array<LocationRubyType> = createSelector(
  (state) => state.static.locations,
  (locations: Array<LocationRubyType>) => [...locations, Constants.DEFAULT_LOCATION]
)

export const rosterByID: (state: GlobalState) => { [roster_id: string]: RosterRubyType } = createSelector(
  getRosters,
  (rosters: Array<RosterRubyType>) =>
    _.mapValues(
      _.groupBy(rosters, (r) => r.id),
      (rs) => rs[0]
    )
)

export const existingPredictionHashes: (state: GlobalState) => Array<string> = createSelector(
  (state) => state.demand_data.predicted,
  (demand_datas: Array<DateData>) => demand_datas.map(DemandData.getUniqKey)
)

export const teamGroupsRubyTypeByID: (state: GlobalState) => { [tg_id: string]: TeamGroupRubyType } = createSelector(
  (state) => state.static.team_groups,
  (team_groups: Array<TeamGroupRubyType>) =>
    _.mapValues(
      _.groupBy(team_groups, (t) => t.id),
      (ts) => ts[0]
    )
)
export const teamGroups: (state: GlobalState) => Array<TeamGroupType> = createSelector(
  (state) => state.static.teams,
  teamGroupsRubyTypeByID,
  (teams: Array<TeamRubyType>, team_group_by_id: { [tg_id: string]: TeamGroupRubyType }) =>
    _.uniqBy<TeamGroupType>(
      teams
        .filter((team) => team.department_group_id != null)
        .map((team) => {
          const tg = team_group_by_id[String(team.department_group_id)]
          return {
            sort_order: tg?.sort_order,
            location_id: tg?.location_id || Constants.DEFAULT_LOCATION.id,
            id: team.department_group_id || Constants.DEFAULT_TEAM_GROUP.id,
            default_team_id: team.id || Constants.DEFAULT_TEAM_GROUP.id,
            name: team.department_group_name || Constants.DEFAULT_TEAM_GROUP.name,
            colour: team.colour || Constants.DEFAULT_TEAM_COLOR,
          }
        }),
      (tg) => tg.id
    )
)

export const teamsByTeamGroupID: (state: GlobalState) => { [team_group_id: string]: Array<TeamRubyType> } =
  createSelector(
    (state) => state.static.teams,
    (teams: Array<TeamRubyType>) => _.groupBy(teams, (t) => t.department_group_id || Constants.DEFAULT_TEAM_GROUP.id)
  )

export const awardById: (state: GlobalState) => { [award_id: string]: AwardType } = createSelector(
  (state) => state.config.awards,
  (awards: Array<AwardType>) =>
    _.mapValues(
      _.groupBy(awards, (aw) => aw.id),
      (aw) => aw[0]
    )
)

export const shiftSlotById: (state: GlobalState) => { [shift_slot_id: string]: ShiftSlotType } = createSelector(
  (state) => state.config.shift_slots,
  (shift_slots: Array<ShiftSlotType>) =>
    _.mapValues(
      _.groupBy(shift_slots, (ss) => ss.id),
      (ss) => ss[0]
    )
)

export const allowanceById: (state: GlobalState) => { [allowance_id: string]: AllowanceType } = createSelector(
  (state) => state.config.allowances,
  (allowances: Array<AllowanceType>) =>
    _.mapValues(
      _.groupBy(allowances, (al) => al.id),
      (al) => al[0]
    )
)

export const teamGroupById: (state: GlobalState) => { [team_group: string]: TeamGroupType } = createSelector(
  teamGroups,
  (team_groups: Array<TeamGroupType>) =>
    _.mapValues(
      _.groupBy(team_groups, (tg) => tg.id),
      (tg) => tg[0]
    )
)

export const teamByTeamGroupID: (state: GlobalState) => { [team_group: string]: Array<TeamRubyType> } = createSelector(
  (state) => state.static.teams,
  (teams: Array<TeamRubyType>) => _.groupBy(teams, (t) => t.department_group_id)
)

export const shiftDetailByID: (state: GlobalState) => { [shift_detail_id: string]: ShiftDetailRubyType } =
  createSelector(
    (state) => state.static.shift_details,
    (shift_details: Array<ShiftDetailRubyType>) =>
      _.mapValues(
        _.groupBy(shift_details, (t) => t.id),
        (ts) => ts[0]
      )
  )

export const shiftDetailsByTeam: (state: GlobalState) => { [team_id: string]: Array<ShiftDetailRubyType> } =
  createSelector(
    (state) => state.static.shift_details,
    (shift_details: Array<ShiftDetailRubyType>) => _.groupBy(shift_details, (sd) => sd.department_id)
  )

export const scheduleByID: (state: GlobalState) => { [schedule_id: string]: ScheduleType } = createSelector(
  getSchedules,
  (schedules: Array<ScheduleType>) =>
    _.mapValues(
      _.groupBy(schedules, (s) => s.id),
      (ss) => ss[0]
    )
)

export const rdoByID: (state: GlobalState) => { [rdo_id: string]: RDORubyType } = createSelector(
  getRDOs,
  (rdos: Array<RDORubyType>) =>
    _.mapValues(
      _.groupBy(rdos, (rdo) => rdo.id),
      (rdos) => rdos[0]
    )
)

export const userByID: (state: GlobalState) => { [user_id: string]: UserType } = createSelector(
  getUsers,
  (users: Array<UserType>) =>
    _.mapValues(
      _.groupBy(users, (u) => u.id),
      (us) => us[0]
    )
)

export const teamToLocation: (state: GlobalState) => { [team_id: string]: LocationRubyType } = createSelector(
  locationByID,
  (state) => state.static.teams,
  (locations_by_id: { [loc_id: string]: LocationRubyType }, teams: Array<TeamRubyType>) =>
    teams.reduce((acc, t) => {
      acc[String(t.id)] = locations_by_id[String(t.location_id)] || Constants.DEFAULT_LOCATION
      return acc
    }, {})
)

export const qualificationsById: (state: GlobalState) => { [qual_id: string]: QualificationRubyType } = createSelector(
  (state) => state.static.qualifications,
  (qualifications: Array<QualificationRubyType>) =>
    _.mapValues(
      _.groupBy(qualifications, (uq) => uq.id),
      (us) => us[0]
    )
)

export const qualificationsByTeam: (state: GlobalState) => { [department_id: string]: Array<QualificationRubyType> } =
  createSelector(
    (state) => state.static.department_qualifications,
    qualificationsById,
    (
      department_qualifications: Array<DepartmentQualificationRubyType>,
      qual_by_id: { [qual_id: string]: QualificationRubyType }
    ) =>
      _.mapValues(
        _.groupBy(department_qualifications, (uq) => uq.department_id),
        (dep_quals) => dep_quals.map((dep_qual) => qual_by_id[String(dep_qual.qualification_id)]).filter(Boolean)
      )
  )

export const departmentQualificationsByQualification: (state: GlobalState) => {
  [qualification_id: string]: Array<DepartmentQualificationRubyType>,
} = createSelector(
  (state) => state.static.department_qualifications,
  (department_qualifications: Array<DepartmentQualificationRubyType>) =>
    _.groupBy(department_qualifications, (dq) => dq.qualification_id)
)

export const qualificationsForAllTeams: (state: GlobalState) => Array<QualificationRubyType> = createSelector(
  (state) => state.static.qualifications,
  departmentQualificationsByQualification,
  qualificationsById,
  (
    qualifications: Array<QualificationRubyType>,
    department_qualifications_by_qualification: { [qualification_id: string]: Array<DepartmentQualificationRubyType> },
    qual_by_id: { [qual_id: string]: QualificationRubyType }
  ) => qualifications.filter((q) => department_qualifications_by_qualification[String(q.id)] == null)
)

export const usersThatWorkInDeps: (state: GlobalState) => { [dep_id: string]: Array<UserType> } = createSelector(
  (state) => state.config.deps_users_can_work_in,
  getUsers,
  userByID,
  (
    deps_users_can_work_in: Array<UserToDepartmentMap>,
    users: Array<UserType>,
    userByID: { [user_id: string]: UserType }
  ) => {
    const obj: { [dep_id: string]: Array<UserType> } = _.mapValues(
      _.groupBy(deps_users_can_work_in, (d) => d.team_id),
      (rec: Array<UserToDepartmentMap>) => rec.map((r) => userByID[String(r.user_id)] || Constants.DEFAULT_USER)
    )
    return {
      ...obj,
      [Constants.DEFAULT_TEAM.id]: users,
    }
  }
)

export const usersThatWorkInLocations: (state: GlobalState) => { [dep_id: string]: Array<UserType> } = createSelector(
  teamsByLocation,
  (state) => state.static.locations,
  userIdsThatWorkInDeps,
  getUsers,
  userByID,
  (
    teams_by_location: { [loc_id: string]: Array<TeamRubyType> },
    locations: Array<LocationRubyType>,
    dep_to_users: { [dep_id: string]: Array<number> },
    users: Array<UserType>,
    userByID: { [user_id: string]: UserType }
  ) => {
    const obj: { [dep_id: string]: Array<UserType> } = _.mapValues(
      locations.reduce((acc, l) => {
        acc[String(l.id)] = _.uniq(
          (teams_by_location[String(l.id)] || []).reduce(
            (acc: Array<number>, t) => [...acc, ...(dep_to_users[String(t.id)] || [])],
            []
          )
        )
        return acc
      }, {}),
      (users) => users.map((u) => userByID[String(u)]).filter(Boolean)
    )
    return {
      ...obj,
      [Constants.DEFAULT_TEAM.id]: users,
    }
  }
)

export const userIdsThatWorkInLocations: (state: GlobalState) => { [dep_id: string]: Array<number> } = createSelector(
  usersThatWorkInLocations,
  (users_by_location: { [dep_id: string]: Array<UserType> }) =>
    _.mapValues(users_by_location, (users) => users.map((u) => u.id))
)

export const usersThatWorkInTeamGroup: (state: GlobalState) => { [team_group_id: string]: Array<UserType> } =
  createSelector(
    usersThatWorkInDeps,
    teamsByTeamGroupID,
    teamGroups,
    (
      deps_users_can_work_in: { [dep_id: string]: Array<UserType> },
      team_by_id: { [team_group_id: string]: Array<TeamRubyType> },
      team_groups: Array<TeamGroupType>
    ) =>
      team_groups.reduce((acc, team_group) => {
        const id = String(team_group.id)
        const teams = team_by_id[id] || []
        acc[id] = _.uniq(teams.flatMap((t) => deps_users_can_work_in[String(t.id)] || []))
        return acc
      }, {})
  )

export const activeUserIdsForCurrentPeriod: (state: GlobalState) => Array<number> = createSelector(
  getUsers,
  allVisibleDatesStr,
  (users: Array<UserType>, visible_dates: Array<string>) =>
    users
      .filter((u) => u.is_active || (!u.is_active && visible_dates.some((date) => date < u.updated_at)))
      .map((u) => u.id)
)

export const largeCards: (state: GlobalState) => boolean = createSelector(
  (state) => state.settings.start_date,
  (state) => state.settings.finish_date,
  (start_date: moment, finish_date: moment) => finish_date.diff(start_date, "days") < 7
)

export const userQualificationsByUser: (state: GlobalState) => { [user_id: string]: Array<UserQualificationRubyType> } =
  createSelector(
    (state) => state.static.user_qualifications,
    (user_qualifications: Array<UserQualificationRubyType>) => _.groupBy(user_qualifications, (uq) => uq.user_id)
  )

export const trainingLogsByUser: (state: GlobalState) => { [user_id: string]: Array<UserTrainingLogRubyType> } =
  createSelector(
    (state) => state.static.training_logs,
    (training_logs: Array<UserTrainingLogRubyType>) => _.groupBy(training_logs, (ut) => ut.user_id)
  )

export const visibleDailySchedules: (state: GlobalState) => Array<DailyScheduleType> = createSelector(
  getDailySchedules,
  (state) => state.settings.start_date,
  (state) => state.settings.finish_date,
  (daily_schedules: Array<DailyScheduleType>, start_date: moment, finish_date: moment) => {
    const start_str: string = start_date.format(C.DATE_FMT)
    const finish_str: string = finish_date.format(C.DATE_FMT)
    return daily_schedules.filter((ds) => ds.date <= finish_str && ds.date >= start_str)
  }
)

export const visibleDailyScheduleIds: (state: GlobalState) => Array<number> = createSelector(
  visibleDailySchedules,
  (daily_schedules: Array<DailyScheduleType>) => daily_schedules.map((ds) => ds.id)
)

export const schedulesByUser: (state: GlobalState) => { [user_id: string]: Array<ScheduleType> } = createSelector(
  getSchedules,
  (schedules: Array<ScheduleType>) => _.groupBy(schedules, (u) => u.user_id)
)

export const vacantSchedules: (state: GlobalState) => Array<ScheduleType> = createSelector(
  schedulesByUser,
  (schedules_by_user: { [user_id: string]: Array<ScheduleType> }) => schedules_by_user["-1"] || []
)

export const vacantScheduleIds: (state: GlobalState) => Array<number> = createSelector(
  vacantSchedules,
  (vacant_schedules: Array<ScheduleType>) => vacant_schedules.map((s) => s.id)
)

export const vacantSchedulesForCurrentTeams: (state: GlobalState) => Array<ScheduleType> = createSelector(
  getCurrentLocationsTeamsIds,
  vacantSchedules,
  visibleDailyScheduleIds,
  (
    current_location_team_ids: Array<number>,
    vacant_schedules: Array<ScheduleType>,
    visible_daily_schedule_ids: Array<number>
  ): Array<ScheduleType> =>
    vacant_schedules.filter(
      (s) =>
        visible_daily_schedule_ids.includes(s.daily_schedule_id) &&
        (current_location_team_ids.includes(s.department_id) || s.department_id === -1)
    )
)

export const schedulesByDateByUser: (state: GlobalState) => {
  [user_id: string]: { [date_str: string]: Array<ScheduleType> },
} = createSelector(schedulesByUser, (schedules_by_user: { [user_id: string]: Array<ScheduleType> }) =>
  _.mapValues(schedules_by_user, (ss) => _.groupBy(ss, (s) => s.date))
)

const groupDailyScheduleUserJoinByDateByUser = (
  user_daily_schedule_joins: Array<UserDailyScheduleJoinType>
): { [user_id: string]: { [date: string]: UserDailyScheduleJoinType } } =>
  _.mapValues(
    _.groupBy(user_daily_schedule_joins, (usdj) => usdj.user_id),
    (udsjs) =>
      _.mapValues(
        _.groupBy(udsjs, (udsj) => udsj.date),
        (udsjs) => udsjs[0]
      )
  )

export const getUserDailyScheduleJoins: (state: GlobalState) => Array<UserDailyScheduleJoinType> = createSelector(
  (state) => state.rosters.user_daily_schedule_joins,
  dateByDailyScheduleID,
  (
    user_daily_schedule_joins: Array<UserDailyScheduleJoinRubyType>,
    date_by_daily_schedule_id: { [ds_id: string]: string }
  ) =>
    user_daily_schedule_joins.map((udsj) => ({
      ...udsj,
      date: date_by_daily_schedule_id[String(udsj.daily_schedule_id)],
    }))
)

export const dailyScheduleUserJoinByDateByUser: (state: GlobalState) => {
  [user_id: string]: { [date: string]: UserDailyScheduleJoinType },
} = createSelector(getUserDailyScheduleJoins, groupDailyScheduleUserJoinByDateByUser)

export const shiftsByUser: (state: GlobalState) => { [user_id: string]: Array<ShiftType> } = createSelector(
  (state) => state.shifts,
  (shifts: Array<ShiftType>) => _.groupBy(shifts, (shift) => shift.user_id)
)

export const shiftsByDateByUser: (state: GlobalState) => { [user_id: string]: { [date: string]: Array<ShiftType> } } =
  createSelector(shiftsByUser, (shifts_by_user: { [user_id: string]: Array<ShiftType> }) =>
    _.mapValues(shifts_by_user, (shifts) => _.groupBy(shifts, (shift) => shift.date))
  )

export const rdosByUser: (state: GlobalState) => { [user_id: string]: Array<RDORubyType> } = createSelector(
  getRDOs,
  (rdos: Array<RDORubyType>) => _.groupBy(rdos, (rdo) => rdo.user_id)
)

export const rdosByDateByUser: (state: GlobalState) => {
  [user_id: string]: { [date_str: string]: Array<RDORubyType> },
} = createSelector(rdosByUser, (rdos_by_user: { [user_id: string]: Array<RDORubyType> }) =>
  _.mapValues(rdos_by_user, (rdos) => _.groupBy(rdos, (rdo) => rdo.date))
)

export const teamInCurrentFiltersMap: (state: GlobalState) => { [team_id: string]: boolean } = createSelector(
  (state) => state.settings.selected_team_ids,
  (state) => state.static.teams,
  (team_ids: Array<number>, teams: Array<TeamRubyType>) => ({
    ...teams.reduce((acc, t) => {
      acc[String(t.id)] = team_ids.includes(t.id)
      return acc
    }, ({}: $Shape<{||}>)),
    [Constants.DEFAULT_TEAM.id]: true,
    null: true,
  })
)

export const usersInCurrentLocations: (state: GlobalState) => Array<UserType> = createSelector(
  depsThatUsersCanWorkIn,
  getUsers,
  (state) => state.settings.selected_location_ids,
  teamToLocation,
  (
    deps_users_can_work_in: { [user_id: string]: Array<number> },
    users: Array<UserType>,
    loc_ids: Array<number>,
    team_to_loc: { [team_id: string]: LocationRubyType }
  ) =>
    users.filter(
      (u) =>
        (deps_users_can_work_in[String(u.id)] || []).filter((d) => loc_ids.includes((team_to_loc[String(d)] || {}).id))
          .length > 0
    )
)

export const filteredAwardTags: (state: GlobalState) => Array<string> = createSelector(
  allAwardTags,
  (state) => state.settings.selected_award_tags,
  (all: Array<string>, selected: Array<string>) => selected.filter((at) => all.includes(at))
)

export const visibleSchedules: (state: GlobalState) => Array<ScheduleType> = createSelector(
  (state) => state.view_options.only_show_time_off,
  getSchedules,
  getVisibleTeamIdsWithDefault,
  allVisibleDatesStr,
  (
    only_show_time_off: boolean,
    schedules: Array<ScheduleType>,
    team_ids: Array<number>,
    visible_dates: Array<string>
  ) => {
    if (only_show_time_off) {
      return []
    }

    return schedules
      .filter((s) => !(s.user_id === Constants.DEFAULT_USER.id && !team_ids.includes(s.department_id)))
      .filter((s) => visible_dates.includes(s.date))
  }
)

export const publishedSchedules: (state: GlobalState) => Array<PublishedScheduleType> = createSelector(
  (state) => state.published_schedules,
  (publishedSchedules: Array<PublishedScheduleType>) => publishedSchedules
)

export const publishedScheduleByScheduleId: (state: GlobalState) => { [schedule_id: string]: ?PublishedScheduleType } =
  createSelector(
    (state) => state.published_schedules,
    (publishedSchedules: Array<PublishedScheduleType>) =>
      _.mapValues(
        _.groupBy(publishedSchedules, (s) => s.schedule_id),
        (ss) => ss[0]
      )
  )

export const usersInVisibleSchedulesInCurrentFilters: (state: GlobalState) => Array<UserType> = createSelector(
  (state) => state.settings.selected_team_ids,
  visibleSchedules,
  userByID,
  (team_ids: Array<number>, schedules: Array<ScheduleType>, user_by_id: { [user_id: string]: UserType }) =>
    _.uniq(schedules.filter((s) => team_ids.includes(s.department_id)).map((s) => s.user_id))
      .filter((u_id) => u_id !== Constants.DEFAULT_USER.id)
      .map((u_id) => user_by_id[String(u_id)])
      .filter(Boolean)
)

export const usersInCurrentFilters: (state: GlobalState) => Array<UserType> = createSelector(
  depsThatUsersCanWorkIn,
  getUsers,
  usersInVisibleSchedulesInCurrentFilters,
  (state) => state.settings.selected_team_ids,
  filteredAwardTags,
  allVisibleDatesStr,
  (state) => state.settings.template_id,
  (state) => state.settings.selected_staff_types,
  (
    deps_users_can_work_in: { [user_id: string]: Array<number> },
    users: Array<UserType>,
    working_users: Array<UserType>,
    team_ids: Array<number>,
    filtered_award_tags: Array<string>,
    visible_dates: Array<string>,
    template_id: ?number,
    selected_staff_types: Array<string>
  ) => {
    const active_users_in_departments = users
      .filter((u) => (deps_users_can_work_in[String(u.id)] || []).filter((d) => team_ids.includes(d)).length > 0)
      .filter((u) => {
        if (u.is_active) {
          return true
        } else if (template_id != null) {
          // If we're in template mode, only show deactivated staff if they had shifts in that template
          return working_users.includes(u)
        } else if (u.employment_end_date != null) {
          // If we're in regular mode and employment_end_date exists and is within the filter dates, show the staff member
          const employment_end_date = u.employment_end_date
          return visible_dates.some((date) => moment(date).isBefore(employment_end_date))
        } else {
          // Default to showing the staff member if they were active (had schedules) during the visible dates
          return working_users.includes(u)
        }
      })
      .filter(
        (u) =>
          template_id != null ||
          u.employment_start_date == null ||
          u.employment_start_date <= visible_dates[visible_dates.length - 1]
      )
      .filter((u) => template_id != null || u.employment_end_date == null || u.employment_end_date >= visible_dates[0])
      .filter((u) => {
        if (selected_staff_types.length !== 1) {
          return true
        } else if (selected_staff_types.length === 1 && selected_staff_types[0] === t("temporary_employee")) {
          return u.temporary_employee
        } else {
          return !u.temporary_employee
        }
      })
    return filtered_award_tags.length === 0
      ? active_users_in_departments
      : active_users_in_departments.filter(
          (u) => filtered_award_tags.filter((at) => u.award_tags.includes(at)).length > 0
        )
  }
)

export const readableUsersInCurrentFilters: (state: GlobalState) => Array<UserType> = createSelector(
  usersInCurrentFilters,
  (state) => state.config.visible_user_ids,
  (users_in_current_filters: Array<UserType>, visible_user_ids: Array<number>) =>
    users_in_current_filters.filter((u) => visible_user_ids.includes(u.id))
)

export const userIDsInCurrentLocations: (state: GlobalState) => Array<number> = createSelector(
  usersInCurrentLocations,
  (users: Array<UserType>) => users.map((u) => u.id)
)

export const pptCompliantUserIDsInCurrentLocations: (state: GlobalState) => Array<number> = createSelector(
  usersInCurrentLocations,
  (users: Array<UserType>) => _.compact(users.map((u) => (u.applies_to_ppt_compliance && u.id) || null))
)

export const rhowForCurrentLocationUsers: (state: GlobalState) => Array<TemplateRubyType> = createSelector(
  userIDsInCurrentLocations,
  getRHoWByUser,
  (user_ids: Array<number>, rhow_by_user: { [user_id: string]: Array<TemplateRubyType> }) =>
    user_ids.flatMap((u) => rhow_by_user[String(u)] || [])
)

export const timeNotWorkedAics: (state: GlobalState) => Array<AICRubyType> = createSelector(
  (state) => state.aics,
  // The only Shift aics we pull in are aics for shifts that are linked to TimeNotWorkedSchedules (aka RDOs).
  // TimeNotWorkedSchedules don't get costed at all, so this is the only way we can get their associated cost info.
  (aics: Array<AICRubyType>) => aics.filter((aic) => aic.awardable_type === "Shift")
)

export const timeNotWorkedScheduleToAICS: (state: GlobalState) => {
  [time_not_worked_sched_id: string]: Array<AICRubyType>,
} = createSelector(
  getRDOs,
  timeNotWorkedAics,
  (time_not_worked: Array<RDORubyType>, time_not_worked_aics: Array<AICRubyType>) => {
    const timeNotWorkedShiftIdsToIds = _.fromPairs(
      time_not_worked.map((time_not_worked_schedule) => [
        time_not_worked_schedule.shift_id,
        time_not_worked_schedule.id,
      ])
    )
    return _.groupBy(
      time_not_worked_aics.filter((aic) =>
        Object.keys(timeNotWorkedShiftIdsToIds).includes(aic.awardable_id.toString())
      ),
      (aic) => timeNotWorkedShiftIdsToIds[aic.awardable_id]
    )
  }
)

export const scheduleAics: (state: GlobalState) => Array<AICRubyType> = createSelector(
  (state) => state.aics,
  (aics: Array<AICRubyType>) => aics.filter((aic) => aic.awardable_type === "Schedule")
)

export const scheduleToAICS: (state: GlobalState) => { [sched_id: string]: Array<AICRubyType> } = createSelector(
  getSchedules,
  scheduleAics,
  (schedules: Array<ScheduleType>, schedule_aics: Array<AICRubyType>) => _.groupBy(schedule_aics, (s) => s.awardable_id)
)

export const overtimeAics: (state: GlobalState) => Array<AICRubyType> = createSelector(
  scheduleAics,
  awardById,
  (schedule_aics: Array<AICRubyType>, award_by_id: { [award_id: string]: AwardType }) =>
    schedule_aics.filter(
      (aic) =>
        aic.ruleable_type === "Award" &&
        award_by_id[String(aic.ruleable_id)] != null &&
        !award_by_id[String(aic.ruleable_id)]?.is_ord_hours
    )
)

export const scheduleToOvertimeAICS: (state: GlobalState) => { [sched_id: string]: Array<AICRubyType> } =
  createSelector(overtimeAics, (schedule_aics: Array<AICRubyType>) => _.groupBy(schedule_aics, (s) => s.awardable_id))

export const userToEstimatedMonthlyCost: (state: GlobalState) => { [user_id: string]: number } = createSelector(
  getUsers,
  getSchedules,
  scheduleToAICS,
  allVisibleDatesStr,
  (
    users: Array<UserType>,
    schedules: Array<ScheduleType>,
    scheds_to_aics: { [sched_id: string]: Array<AICRubyType> },
    visible_dates: Array<string>
  ) => {
    // We specifically only want to use aics within the date range for super calcs as this is how we calculate
    // who gets super in Reports in app/models/workforce_report/cost_by_location_and_team.rb#fetch_costs
    // if you are making changes here, please make the corresponding changes there too
    const multiplier_to_month_period = 28.0 / visible_dates.length

    return users.reduce((acc, user) => {
      const schedule_ids = schedules.filter((sched) => sched.user_id === user.id).map((sched) => sched.id)
      const aics = schedule_ids
        .flatMap((sched_id) => scheds_to_aics[sched_id.toString()])
        .filter(Boolean)
        .filter((aic) => visible_dates.includes(aic.date.toString()))

      const total_cost_for_viewed_period = aics.reduce((acc, aic) => (Number(aic.cost) || 0) + acc, 0)
      const estimated_monthly_wage_cost_for_current_period = total_cost_for_viewed_period * multiplier_to_month_period

      acc[user.id] = estimated_monthly_wage_cost_for_current_period
      return acc
    }, {})
  }
)

export const earningsThresholdPeriodsForPensionOncosts: (state: GlobalState) => Array<EarningsThresholdPeriodType> =
  createSelector(
    (state) => state.config.organisation.country,
    (country: ?string) => {
      if (country === "United Kingdom") {
        return Constants.EARNINGS_THRESHOLDS_FOR_PENSION_ONCOSTS_UK
      } else {
        return []
      }
    }
  )

export const scheduleToOnCostConfigurations: (state: GlobalState) => {
  [sched_id: string]: Array<OncostConfigurationType>,
} = createSelector(
  getSchedules,
  (state) => state.oncost_configurations,
  userByID,
  teamToLocation,
  userToEstimatedMonthlyCost,
  earningsThresholdPeriodsForPensionOncosts,
  (
    schedules: Array<ScheduleType>,
    oncost_configurations: Array<OncostConfigurationType>,
    user_by_id: { [user_id: string]: UserType },
    team_to_location: { [team_id: string]: LocationRubyType },
    user_to_estimated_monthly_cost: { [user_id: string]: number },
    earnings_thresholds: Array<EarningsThresholdPeriodType>
  ) =>
    schedules.reduce((acc, schedule) => {
      const user = user_by_id[String(schedule.user_id)] || Constants.DEFAULT_USER
      const location = team_to_location[String(schedule.department_id)] || Constants.DEFAULT_LOCATION
      const earnings_threshold_period_for_date = earnings_thresholds.find(
        (threshold_period: EarningsThresholdPeriodType, index: number) =>
          (threshold_period.start_date >= schedule.date && threshold_period.finish_date <= schedule.date) ||
          index === earnings_thresholds.length - 1 // grab the last (most recent) one if no matches
      )

      const user_age = HelperFunc.ageFromDOB(user.date_of_birth, schedule.date)
      const earnings_threshold_for_date =
        user_age == null || user_age >= 21
          ? earnings_threshold_period_for_date?.threshold || 0
          : earnings_threshold_period_for_date?.threshold_for_juniors || 0
      const pension_oncosts_apply =
        (user_to_estimated_monthly_cost[user.id.toString()] || 0) > earnings_threshold_for_date

      const configs_that_apply = oncost_configurations.filter((config) => {
        const awardTagsMatch =
          config.employee_tags.length === 0 ||
          _.some(user.award_tags, (award_tag) => config.employee_tags.includes(award_tag))
        const locationTagsMatch = config.location_tags.length === 0 || config.location_tags.includes(location.name)

        return awardTagsMatch && locationTagsMatch && (pension_oncosts_apply || !config.pension_fund)
      })

      // If multiple specific configs apply, we will use all of them
      acc[schedule.id] = configs_that_apply

      return acc
    }, {})
)

export const scheduleToAICSWithOnCostMultiplierAdded: (state: GlobalState) => {
  [sched_id: string]: Array<AICRubyType>,
} = createSelector(
  scheduleToAICS,
  scheduleToOnCostConfigurations,
  awardById,
  allowanceById,
  (
    schedule_to_aics: { [sched_id: string]: Array<AICRubyType> },
    schedule_to_on_cost_configurations: { [sched_id: string]: Array<OncostConfigurationType> },
    award_by_id: { [award_id: string]: AwardType },
    allowance_by_id: { [allowance_id: string]: AllowanceType }
  ) =>
    _.mapValues(schedule_to_aics, (aics: Array<AICRubyType>, schedule_id: string) => {
      const oncost_configs = schedule_to_on_cost_configurations[schedule_id] || []
      return aics.map((aic) => {
        const total_multiplier = oncost_configs.reduce((total, oncost_config) => {
          const is_ord_hours_award_or_allowance =
            award_by_id[String(aic.ruleable_id)]?.is_ord_hours || allowance_by_id[String(aic.ruleable_id)] != null
          const should_apply_multiplier = Boolean(
            !oncost_config?.only_applies_to_ordinary_hours || is_ord_hours_award_or_allowance
          )
          const multiplier = should_apply_multiplier ? Number(oncost_config.config_value || 0) : 0
          return total + multiplier
        }, 0)
        return {
          ...aic,
          cost: String(Number(aic.cost) * (1 + total_multiplier / 100)),
        }
      })
    })
)

export const startStr: (state: GlobalState) => string = createSelector(
  (state) => state.settings.start_date,
  (start_date: moment) => start_date.format(C.DATE_FMT)
)
export const startWeekday: (state: GlobalState) => number = createSelector(
  (state) => state.settings.start_date,
  (start_date: moment) => start_date.isoWeekday() % 7
)

export const finishStr: (state: GlobalState) => string = createSelector(
  (state) => state.settings.finish_date,
  (finish_date: moment) => finish_date.format(C.DATE_FMT)
)

export const visibleUsers: (state: GlobalState) => Array<UserType> = createSelector(
  usersInCurrentFilters,
  (state) => state.view_options.sort_staff,
  (users: Array<UserType>, sort: SortStaffOptions) =>
    User.sort(
      uniqBy([...users, Constants.DEFAULT_USER], (u) => u.id),
      sort
    )
)

export const visibleUserIds: (state: GlobalState) => Array<number> = createSelector(
  visibleUsers,
  (users: Array<UserType>) => users.map((u) => u.id)
)

export const visibleShifts: (state: GlobalState) => Array<ShiftType> = createSelector(
  (state) => state.view_options.only_show_time_off,
  getShifts,
  visibleUserIds,
  getVisibleTeams,
  allVisibleDatesStr,
  (
    only_show_time_off: boolean,
    shifts: Array<ShiftType>,
    user_ids: Array<number>,
    teams: Array<TeamRubyType>,
    visible_dates: Array<string>
  ) => {
    if (only_show_time_off) {
      return []
    }
    const team_ids: Array<number> = [...teams.map((t) => t.id), Constants.DEFAULT_TEAM.id]
    return shifts
      .filter((s) => !(s.user_id === Constants.DEFAULT_USER.id && !team_ids.includes(s.department_id)))
      .filter((s) => user_ids.includes(s.user_id))
      .filter((s) => visible_dates.includes(s.date))
  }
)

// We need visible shifts for calculating view-period leave hours. The visibleShifts selector above
// seems to return an empty array if a setting called "only show time off" is true, likely because
// there was no point in figuring out the visible shifts if only leave is visible. Now, however,
// there are use cases where we need visible shifts AND leave.
// Instead of refactoring a pretty vital selector and causing unintended consequences, I've created a
// new, slightly modified selector.
export const getVisibleLeaveShifts: (state: GlobalState) => Array<ShiftType> = createSelector(
  getShifts,
  visibleUserIds,
  startStr,
  finishStr,
  getVisibleTeams,
  allVisibleDatesStr,
  (
    shifts: Array<ShiftType>,
    user_ids: Array<number>,
    start_date: string,
    finish_date: string,
    teams: Array<TeamRubyType>,
    visible_dates: Array<string>
  ) => {
    const team_ids: Array<number> = [...teams.map((t) => t.id), Constants.DEFAULT_TEAM.id]
    return shifts
      .filter((s) => s.date <= finish_date && s.date >= start_date)
      .filter((s) => !(s.user_id === Constants.DEFAULT_USER.id && !team_ids.includes(s.department_id)))
      .filter((s) => user_ids.includes(s.user_id))
      .filter((s) => s.leave_request_id != null)
      .filter((s) => visible_dates.includes(s.date))
  }
)

export const getVisibleLeaveShiftsByDate: (state: GlobalState) => { [date: string]: Array<ShiftType> } = createSelector(
  getVisibleLeaveShifts,
  (visible_leave_shifts: Array<ShiftType>) => _.groupBy(visible_leave_shifts, (shift) => shift.date)
)

export const getVisibleLeaveShiftByLeaveRequestIdByDate: (state: GlobalState) => {
  [date: string]: { [leave_request_id: string]: ShiftType },
} = createSelector(
  getVisibleLeaveShiftsByDate,
  (get_visible_leave_shifts_by_date: { [date: string]: Array<ShiftType> }) =>
    _.mapValues(get_visible_leave_shifts_by_date, (shifts) =>
      _.mapValues(
        _.groupBy(shifts, (shift) => shift.leave_request_id),
        (ss) => ss[0]
      )
    )
)

export const visibleScheduleByID: (state: GlobalState) => { [schedule_id: string]: ScheduleType } = createSelector(
  visibleSchedules,
  (schedules: Array<ScheduleType>) =>
    _.mapValues(
      _.groupBy(schedules, (s) => s.id),
      (ss) => ss[0]
    )
)

export const visibleSchedulesThatAreFillable: (state: GlobalState) => Array<ScheduleType> = createSelector(
  visibleSchedules,
  (schedules: Array<ScheduleType>) =>
    schedules.filter((s) => s.user_id === Constants.DEFAULT_USER.id && s.department_id !== Constants.DEFAULT_TEAM.id)
)

export const visibleUsersByGroupId: (state: GlobalState) => { [team_group_id: string]: Array<UserType> } =
  createSelector(
    (state) => state.view_options.group,
    usersInCurrentFilters,
    usersThatWorkInDeps,
    usersThatWorkInTeamGroup,
    usersThatWorkInLocations,
    shiftSlotById,
    usersThatHavePositions,
    (
      group: GroupType,
      users: Array<UserType>,
      users_by_team: { [team_id: string]: Array<UserType> },
      users_by_team_group: { [team_group_id: string]: Array<UserType> },
      users_by_location: { [location_id: string]: Array<UserType> },
      shiftSlotById: { [shift_slot: string]: ShiftSlotType },
      users_by_position_id: { [position_id: string]: Array<UserType> }
    ) => {
      const user_ids = users.map((u) => u.id)
      switch (group) {
        case "location":
          return _.mapValues(users_by_location, (us: Array<UserType>) => us.filter((u) => user_ids.includes(u.id)))
        case "team_group":
          return _.mapValues(users_by_team_group, (us: Array<UserType>) => us.filter((u) => user_ids.includes(u.id)))
        case "team":
          return _.mapValues(users_by_team, (us: Array<UserType>) => us.filter((u) => user_ids.includes(u.id)))
        case "shift":
          return _.mapValues(shiftSlotById, () => [...users])
        case "position":
          return _.mapValues(users_by_position_id, (us: Array<UserType>) =>
            us.filter((user: UserType) => user_ids.includes(user.id))
          )
        default:
          return {
            null: users,
          }
      }
    }
  )

export const visibleUsersThatNeedData: (state: GlobalState) => Array<UserType> = createSelector(
  usersInCurrentFilters,
  (state) => state.cache.schedule_user_data,
  allVisibleDatesStr,
  (state) => state.settings.template_id,
  (
    users: Array<UserType>,
    data_cache: ScheduleDataUserCache,
    all_visible_dates_str: Array<string>,
    template_id: ?number
  ) => {
    const cache = data_cache[String(template_id)]
    return users.filter(
      (user) => !_.every(all_visible_dates_str.map((date) => cache?.[String(user.id)]?.[date] != null))
    )
  }
)

export const visibleUsersIdsThatNeedData: (state: GlobalState) => Array<number> = createSelector(
  visibleUsersThatNeedData,
  (users: Array<UserType>) => users.map((u) => u.id)
)

export const visibleSchedulesSimpleType: (state: GlobalState) => Array<SimpleScheduleType> = createSelector(
  startStr,
  getSchedules,
  (date: string, schedules: Array<ScheduleType>) =>
    schedules.map((s) => Schedule.transformToSimpleDS(s, HelperFunc.dateTimeToMin(date + " 00:00:00")))
)

export const simpleScheduleByID: (state: GlobalState) => { [sched_id: string]: SimpleScheduleType } = createSelector(
  visibleSchedulesSimpleType,
  (schedules: Array<SimpleScheduleType>) =>
    _.mapValues(
      _.groupBy(schedules, (sched) => sched.id),
      (scheds) => scheds[0]
    )
)

export const visibleSchedulesStrID: (context: GlobalState) => Array<string> = createSelector(
  visibleSchedules,
  (schedules: Array<ScheduleType>) => schedules.map((s) => String(s.id))
)

export const disableHotkeys: (state: GlobalState) => boolean = createSelector(
  (state) => state.modal,
  (modals: Modals) => Object.values(modals).some((v) => v === true)
)

export const visibleRDOs: (state: GlobalState) => Array<RDORubyType> = createSelector(
  getRDOs,
  (state) => state.settings.start_date,
  (state) => state.settings.finish_date,
  (rdos: Array<RDORubyType>, start_date: moment, finish_date: moment) => {
    const start_str: string = start_date.format(C.DATE_FMT)
    const finish_str: string = finish_date.format(C.DATE_FMT)
    return rdos.filter((rdo) => rdo.date <= finish_str && rdo.date >= start_str)
  }
)

export const leaveRequestsByUserId: (state: GlobalState) => { [user_id: string]: Array<LeaveRequestRubyType> } =
  createSelector(getLeaveRequests, (leave_requests: Array<LeaveRequestRubyType>) =>
    _.groupBy(leave_requests, (lr) => lr.user_id)
  )

export const visibleLeaveRequests: (state: GlobalState) => Array<LeaveRequestRubyType> = createSelector(
  getLeaveRequests,
  (state) => state.settings.start_date,
  (state) => state.settings.finish_date,
  (leave_requests: Array<LeaveRequestRubyType>, start_date: moment, finish_date: moment) => {
    const start_str: string = start_date.format(C.DATE_FMT)
    const finish_str: string = finish_date.format(C.DATE_FMT)
    return leave_requests.filter((lr) => lr.start <= finish_str && lr.finish >= start_str)
  }
)

export const visibleUnavailabilitys: (state: GlobalState) => Array<UnavailabilityRubyType> = createSelector(
  getUnavailability,
  (state) => state.settings.start_date,
  (state) => state.settings.finish_date,
  (unavailabilitys: Array<UnavailabilityRubyType>, start_date: moment, finish_date: moment) => {
    const start_str: string = start_date.format(C.DATE_FMT)
    const finish_str: string = finish_date.format(C.DATE_FMT)
    // unavailability range is in between visible range
    return unavailabilitys.filter(
      (un) =>
        un.start.split(" ")[0] <= finish_str && // unavailability start is before range finish
        un.end.split(" ")[0] >= start_str // unavailability end is after range start
    )
  }
)

export const visibleUnavailabilitysByDate: (state: GlobalState) => { [date: string]: Array<UnavailabilityRubyType> } =
  createSelector(
    visibleUnavailabilitys,
    allVisibleDatesStr,
    (unavailabilitys: Array<UnavailabilityRubyType>, dates: Array<string>) =>
      dates.reduce((acc, date) => {
        // unavailability range includes date
        acc[date] = unavailabilitys.filter(
          (un) =>
            un.start.split(" ")[0] <= date && // unavailability start before/equal to date
            un.end.split(" ")[0] > date // unavailability end is after date
        )
        return acc
      }, {})
  )

export const visibleLeaveByUser: (state: GlobalState) => { [user_id: string]: Array<LeaveRequestRubyType> } =
  createSelector(visibleLeaveRequests, (visible_leave_requests: Array<LeaveRequestRubyType>) =>
    _.groupBy(visible_leave_requests, (lr) => lr.user_id)
  )

export const visibleLeaveByDate: (state: GlobalState) => { [date: string]: Array<LeaveRequestRubyType> } =
  createSelector(
    visibleLeaveRequests,
    allVisibleDatesStr,
    (leaves: Array<LeaveRequestRubyType>, dates: Array<string>) =>
      dates.reduce((acc, date) => {
        acc[date] = leaves.filter((lr) => lr.start <= date && lr.finish >= date)
        return acc
      }, {})
  )

// This is a bit hacky, but the leave requests we return here are slightly modified based on the date.
// Since we have the date, we can check the leave request's daily breakdown for more detailed information,
// which we inject into the LR. The start time and finish time here may not be the same as the actual leave request's.
// We cannot change visibleLeaveByUser to use shift summary times because that selector does not have the context of
// date - a leave request can only get times from a shift summary if we're also asking about a particular date.
export const visibleLeaveByDateByUser: (state: GlobalState) => {
  [user_id: string]: { [date: string]: Array<LeaveRequestRubyType> },
} = createSelector(
  visibleLeaveByUser,
  getLeaveShifts,
  allVisibleDatesStr,
  (leaveByUser: { [user_id: string]: Array<LeaveRequestRubyType> }, shifts: Array<ShiftType>, dates: Array<string>) =>
    _.mapValues(leaveByUser, (leaves: Array<LeaveRequestRubyType>) =>
      dates.reduce((acc, date) => {
        acc[date] = flatten(
          leaves
            .filter((lr) => lr.start <= date && lr.finish >= date)
            .map((lr) => {
              let summariesOnDate
              // This path will be taken if it's old leave with daily breakdown from the database column
              if (lr.daily_breakdown != null && lr.daily_breakdown.length > 0) {
                summariesOnDate = lr.daily_breakdown
                  .filter((shift_summary: ShiftSummary) => shift_summary.date === date)
                  .map((shift_summary: ShiftSummary) => ({
                    ...lr,
                    finish_time: shift_summary[FINISH_TIME],
                    start_time: shift_summary[START_TIME],
                  }))
              } else {
                // This path will be taken if it's new leave
                summariesOnDate = sortBy(
                  shifts
                    .filter((s) => s.date === date)
                    .filter((s) => s.leave_request_id === lr.id)
                    .map((s) => ({
                      ...lr,
                      finish_time: s.end,
                      start_time: s.start,
                    })),
                  ["start_time"]
                )
              }

              if (summariesOnDate.length === 0) {
                return lr
              }
              return summariesOnDate
            })
        )
        return acc
      }, {})
    )
)

export const visibleUnavailabilityByUser: (state: GlobalState) => { [user_id: string]: Array<UnavailabilityRubyType> } =
  createSelector(visibleUnavailabilitys, (visible_unavailabilitys: Array<UnavailabilityRubyType>) =>
    _.groupBy(visible_unavailabilitys, (un) => un.user_id)
  )

export const visibleUnavailabilityByDateByUser: (state: GlobalState) => {
  [user_id: string]: { [date: string]: Array<UnavailabilityRubyType> },
} = createSelector(
  visibleUnavailabilityByUser,
  allVisibleDatesStr,
  (unavailability_by_user: { [user_id: string]: Array<UnavailabilityRubyType> }, dates: Array<string>) =>
    _.mapValues(unavailability_by_user, (unavailabilitys: Array<UnavailabilityRubyType>) =>
      dates.reduce((acc, date) => {
        acc[date] = unavailabilitys.filter((un) => {
          const un_start_date = moment(un.start, C.DATE_TIME_FMT).format(C.DATE_FMT)
          const un_raw_end_date = moment(un.end, C.DATE_TIME_FMT)
          // all day unavailability ends at midnight, so we need to remove one day to get its actual 'finish' date
          const un_end_date = un.all_day
            ? un_raw_end_date.subtract(1, "day").format(C.DATE_FMT)
            : un_raw_end_date.format(C.DATE_FMT)
          return un_start_date <= date && un_end_date >= date
        })
        return acc
      }, {})
    )
)

export const visibleTeamGroups: (state: GlobalState) => Array<TeamGroupType> = createSelector(
  teamGroups,
  getVisibleTeams,
  (team_groups: Array<TeamGroupType>, teams: Array<TeamRubyType>) => {
    const tgs_in_current_filters = _.uniq(teams.map((t) => t.department_group_id).filter(Boolean))
    return team_groups.filter((tg) => tgs_in_current_filters.includes(tg.id))
  }
)

export const visibleScheduleAndOvertimeAics: (state: GlobalState) => Array<[number, Array<AICRubyType>]> =
  createSelector(
    visibleSchedules,
    scheduleToOvertimeAICS,
    (
      schedules: Array<ScheduleType>,
      schedule_to_overtime_aics: { [sched_id: string]: Array<AICRubyType> }
    ): Array<[number, Array<AICRubyType>]> =>
      schedules.map((schedule) => [schedule.id, schedule_to_overtime_aics[String(schedule.id)]] || [])
  )

export const statTypeByID: (state: GlobalState) => { [st_id: string]: StatType } = createSelector(
  (state) => state.stat_types,
  (sts: Array<StatType>) =>
    _.mapValues(
      _.groupBy(sts, (st) => st.id),
      (sts) => sts[0]
    )
)

export const statTypeByStatType: (state: GlobalState) => { [stat_type: string]: StatType } = createSelector(
  (state) => state.stat_types,
  (sts: Array<StatType>) =>
    _.mapValues(
      _.groupBy(sts, (st) => st.stat_type),
      (sts) => sts[0]
    )
)

export const allDemandData: (state: GlobalState) => Array<DateData> = createSelector(
  (state) => state.demand_data.actual,
  (state) => state.demand_data.predicted,
  (actual: Array<DateData>, predicted: Array<DateData>) => [...actual, ...predicted]
)

export const allDemandDataByDataStream: (state: GlobalState) => { [ds_id: string]: Array<DateData> } = createSelector(
  allDemandData,
  (all_demand_data: Array<DateData>) => _.groupBy(all_demand_data, (dd) => dd.data_stream_id)
)

// Default stat type here is the string stat type ("sales", "pizza_count")
// so the result of this selector is a mapping from a data stream id to a stat type, eg "123": "sales"
// The point of this is to figure out the most likely stat type (string) for a datastream, so if a join is missing a stat type we can
// assign it a default one.
export const dataStreamToDefaultStatType: (state: GlobalState) => { [ds_id: string]: string } = createSelector(
  (state) => state.data_streams,
  allDemandDataByDataStream,
  statTypeByID,
  (
    data_streams: Array<DataStreamRubyType>,
    demand_data_by_ds: { [ds_id: string]: Array<DateData> },
    st_by_id: { [st_id: string]: StatType }
  ) =>
    data_streams.reduce<{ [ds_id: string]: string }>((acc, ds) => {
      acc[String(ds.id)] = st_by_id[String(ds.default_stat_type_id)]?.stat_type
      if (acc[String(ds.id)] == null) {
        // This data stream does not have a default stat type so we're going to have to figure out what it should be by data we've loaded
        const counts_of_data = _.toPairs(
          (demand_data_by_ds[String(ds.id)] || []).reduce<{ [stat: string]: number }>((inner_acc, dd) => {
            inner_acc[dd.stat_type] = (inner_acc[dd.stat_type] || 0) + 1
            return inner_acc
          }, {})
        )
        const most_common_stat_type =
          counts_of_data.reduce<[string, number]>(
            (acc_2, sc: [string, number]) => (acc_2[1] > sc[1] || acc_2[0] === "sales" ? acc_2 : sc),
            ["", 0]
          )[0] || "sales"
        acc[String(ds.id)] = most_common_stat_type || "sales"
      }
      return acc
    }, {})
)

export const dataStreamJoins: (state: GlobalState) => Array<DataStreamJoinRubyType> = createSelector(
  (state) => state.data_stream_joins,
  dataStreamToDefaultStatType,
  statTypeByStatType,
  statTypeByID,
  (
    data_stream_joins: Array<DataStreamJoinRubyType>,
    data_stream_to_default_stat_type: { [ds_id: string]: string },
    st_by_st: { [st: string]: StatType },
    st_by_id: { [st: string]: StatType }
  ) =>
    data_stream_joins.map<DataStreamJoinRubyType, _>((dsj) => ({
      ...dsj,
      stat_types:
        st_by_id[String(dsj.stat_type_id)]?.stat_type ||
        data_stream_to_default_stat_type[String(dsj.data_stream_id)] ||
        "sales",
      stat_type_id:
        st_by_id[String(dsj.stat_type_id)]?.id ||
        st_by_st[data_stream_to_default_stat_type[String(dsj.data_stream_id)]]?.id ||
        st_by_st["sales"]?.id,
    }))
)

export const headCountMapByDataStreamJoins: (state: GlobalState) => { [dsj_id: string]: Array<HeadCountMapRubyType> } =
  createSelector(
    (state) => state.head_count_maps,
    (head_count_maps: Array<HeadCountMapRubyType>) => _.groupBy(head_count_maps, (hc) => hc.data_stream_join_id)
  )

export const headCountMapByDataStreamJoinByDoW: (state: GlobalState) => {
  [dsj_id: string]: { [dow: number]: Array<HeadCountMapRubyType> },
} = createSelector(
  headCountMapByDataStreamJoins,
  (head_count_maps_by_dsj: { [dsj_id: string]: Array<HeadCountMapRubyType> }) =>
    _.mapValues(head_count_maps_by_dsj, (hcm_by_dsj) => _.groupBy(hcm_by_dsj, (hcm) => hcm.day))
)

export const headCountMapByDataStreamJoinByDoWBy15Inc: (state: GlobalState) => {
  [dsj_id: string]: { [dow: number]: { [by_15_inc: number]: HeadCountMapRubyType } },
} = createSelector(
  headCountMapByDataStreamJoinByDoW,
  (head_count_maps_by_dsj_by_dow: { [dsj_id: string]: { [dow: number]: Array<HeadCountMapRubyType> } }) =>
    _.mapValues(head_count_maps_by_dsj_by_dow, (by_dsj) =>
      _.mapValues(by_dsj, (by_dow) =>
        _.groupBy(by_dow, (hcm) =>
          moment.duration(moment(hcm.day_part, "HH:mm:ss").format("HH:mm"), "HH:mm").asMinutes()
        )
      )
    )
)

export const dataStreamJoinsByDataStream: (state: GlobalState) => { [ds_id: string]: Array<DataStreamJoinRubyType> } =
  createSelector(dataStreamJoins, (dsjs: Array<DataStreamJoinRubyType>) => _.groupBy(dsjs, (dsj) => dsj.data_stream_id))

export const dataStreamToStatType: (state: GlobalState) => { [ds_id: string]: string } = createSelector(
  (state) => state.data_streams,
  dataStreamJoinsByDataStream,
  statTypeByID,
  (
    data_streams: Array<DataStreamRubyType>,
    data_stream_joins_by_data_stream: { [ds_id: string]: Array<DataStreamJoinRubyType> },
    st_by_id: { [st_id: string]: StatType }
  ) =>
    data_streams.reduce<{ [ds_id: string]: string }>((acc, ds) => {
      acc[String(ds.id)] =
        _.head(
          (data_stream_joins_by_data_stream[String(ds.id)] || [])
            .map((dsj) => st_by_id[String(dsj.stat_type_id)]?.stat_type)
            .filter(Boolean)
        ) || "sales"
      return acc
    }, {})
)

export const formatValueWithStatType: (state: GlobalState) => StatFormatFunc = createSelector(
  statTypeByStatType,
  (stat_type_by_stat_type: { [stat_type: string]: StatType }) =>
    (value: number | string, stat_type: string, round: ?boolean) => {
      const format = stat_type_by_stat_type[String(stat_type)]?.format || "decimal_number"
      return HelperFunc.formatStat(value, format, round)
    }
)

export const dataStreamJoinsByTeam: (state: GlobalState) => { [team_id: string]: Array<DataStreamJoinRubyType> } =
  createSelector(dataStreamJoins, (dsjs: Array<DataStreamJoinRubyType>) =>
    _.groupBy(
      dsjs.filter((dsj) => dsj.data_streamable_type === "Department"),
      (dsj) => dsj.data_streamable_id
    )
  )

export const getDataStreamJoinsForCurrentLocations: (state: GlobalState) => Array<DataStreamJoinRubyType> =
  createSelector(
    (state) => state.settings.selected_location_ids,
    getCurrentLocationsTeams,
    dataStreamJoins,
    (
      loc_ids: Array<number>,
      teams: Array<TeamRubyType>,
      dsjs: Array<DataStreamJoinRubyType>
    ): Array<DataStreamJoinRubyType> => {
      const team_ids = teams.map((t) => t.id)
      return dsjs.filter((dsj) => {
        if (dsj.data_streamable_type === "Location") {
          return loc_ids.includes(dsj.data_streamable_id)
        } else if (dsj.data_streamable_type === "Department") {
          return team_ids.includes(dsj.data_streamable_id)
        }
        return false
      })
    }
  )

export const dataStreamJoinsByLocation: (state: GlobalState) => {
  [location_id: string]: Array<DataStreamJoinRubyType>,
} = createSelector(
  teamsByLocation,
  (state) => state.static.locations,
  dataStreamJoins,
  (
    teams_by_location: { [location_id: string]: Array<TeamRubyType> },
    locations: Array<LocationRubyType>,
    dsjs: Array<DataStreamJoinRubyType>
  ) =>
    locations.reduce((acc: { [location_id: string]: Array<DataStreamJoinRubyType> }, location) => {
      const teams = teams_by_location[String(location.id)] || []
      const team_ids = teams.map((t) => t.id)
      acc[String(location.id)] = dsjs.filter((dsj) => {
        if (dsj.data_streamable_type === "Location") {
          return dsj.data_streamable_id === location.id
        } else if (dsj.data_streamable_type === "Department") {
          return team_ids.includes(dsj.data_streamable_id)
        }
        return false
      })
      return acc
    }, {})
)

export const dataStreamByID: (state: GlobalState) => { [ds_id: string]: DataStreamRubyType } = createSelector(
  (state) => state.data_streams,
  (data_streams: Array<DataStreamRubyType>) =>
    _.mapValues(
      _.groupBy(data_streams, (ds) => ds.id),
      (dss) => dss[0]
    )
)

export const getDataStreamsForCurrentLocations: (state: GlobalState) => Array<DataStreamRubyType> = createSelector(
  getDataStreamJoinsForCurrentLocations,
  dataStreamByID,
  (dsjs: Array<DataStreamJoinRubyType>, ds_by_id: { [ds_id: string]: DataStreamRubyType }) =>
    _.uniq(dsjs.map((dsj) => dsj.data_stream_id)).map((ds_id) => ds_by_id[String(ds_id)])
)

export const dataStreamsByLocations: (state: GlobalState) => { [location_id: string]: Array<DataStreamRubyType> } =
  createSelector(
    dataStreamJoinsByLocation,
    dataStreamByID,
    (
      dsjs_by_loc: { [location_id: string]: Array<DataStreamJoinRubyType> },
      ds_by_id: { [ds_id: string]: DataStreamRubyType }
    ) =>
      _.mapValues(dsjs_by_loc, (dsjs) =>
        _.uniq(dsjs.map((dsj) => dsj.data_stream_id)).map((ds_id) => ds_by_id[String(ds_id)])
      )
  )

export const locationIDByDataStream: (state: GlobalState) => { [location_id: string]: number } = createSelector(
  dataStreamsByLocations,
  (ds_by_loc: { [location_id: string]: Array<DataStreamRubyType> }) =>
    _.toPairs(ds_by_loc).reduce((acc, [loc_id, dss]) => {
      dss.map((ds) => {
        acc[String(ds.id)] = Number(loc_id)
      })
      return acc
    }, {})
)

export const demandDataByDataStreamJoin: (state: GlobalState) => { [dsj_id: string]: Array<DateData> } = createSelector(
  (state) => state.demand_data.actual,
  dataStreamJoins,
  (demand_data: Array<DateData>, dsjs: Array<DataStreamJoinRubyType>) =>
    dsjs.reduce((acc, dsj) => {
      acc[String(dsj.id)] = demand_data.filter(
        (dd) => dd.stat_type === dsj.stat_types && dd.data_stream_id === dsj.data_stream_id
      )
      return acc
    }, {})
)

export const actualDemandDataByDate: (state: GlobalState) => { [date: string]: Array<DateData> } = createSelector(
  (state) => state.demand_data.actual,
  (demand_data: Array<DateData>) => _.groupBy(demand_data, (dd) => dd.date)
)

export const predictedDemandDataByDate: (state: GlobalState) => { [date: string]: Array<DateData> } = createSelector(
  (state) => state.demand_data.predicted,
  (demand_data: Array<DateData>) => _.groupBy(demand_data, (dd) => dd.date)
)

export const actualDemandDataByDataStreamByDate: (state: GlobalState) => {
  [date: string]: { [dsj_id: string]: Array<DateData> },
} = createSelector(actualDemandDataByDate, (demand_data_by_date: { [date: string]: Array<DateData> }) =>
  _.mapValues(demand_data_by_date, (dds) => _.groupBy(dds, (dd) => dd.data_stream_id))
)

export const predictedDemandDataByDataStreamByDate: (state: GlobalState) => {
  [date: string]: { [dsj_id: string]: Array<DateData> },
} = createSelector(predictedDemandDataByDate, (demand_data_by_date: { [date: string]: Array<DateData> }) =>
  _.mapValues(demand_data_by_date, (dds) => _.groupBy(dds, (dd) => dd.data_stream_id))
)

export const demandDataForCurrentLocations: (state: GlobalState) => Array<DateData> = createSelector(
  demandDataByDataStreamJoin,
  getDataStreamJoinsForCurrentLocations,
  (demand_data: { [dsj_id: string]: Array<DateData> }, dsjs: Array<DataStreamJoinRubyType>) =>
    _.uniqBy(
      dsjs.reduce((acc: Array<DateData>, dsj) => [...acc, ...(demand_data[String(dsj.id)] || [])], []),
      DemandData.getUniqKey
    )
)

export const demandDataForCurrentLocationsByDate: (state: GlobalState) => { [date: string]: Array<DateData> } =
  createSelector(demandDataForCurrentLocations, (demand_data: Array<DateData>) =>
    _.groupBy(demand_data, (dd) => dd.date)
  )

export const demandDataForCurrentLocationsByStatTypeByDataStreamIdByDate: (state: GlobalState) => {
  [date: string]: { [data_stream_id: string]: { [stat_type: string]: DateData } },
} = createSelector(demandDataForCurrentLocationsByDate, (data_by_date: { [date: string]: Array<DateData> }) => {
  const by_ds_by_date = _.mapValues(data_by_date, (datas) => _.groupBy(datas, (data) => data.data_stream_id))
  const by_stat_type_by_ds_by_date = _.mapValues(by_ds_by_date, (by_ds) =>
    _.mapValues(by_ds, (datas) => _.groupBy(datas, (p) => p.stat_type))
  )
  return _.mapValues(by_stat_type_by_ds_by_date, (by_stat_type_by_ds) =>
    _.mapValues(by_stat_type_by_ds, (by_stat_type) => _.mapValues(by_stat_type, (preds) => preds[0]))
  )
})
export const visibleDemandDataForCurrentLocations: (state: GlobalState) => Array<DateData> = createSelector(
  allVisibleDatesStr,
  demandDataForCurrentLocations,
  (dates: Array<string>, demand_data: Array<DateData>) => demand_data.filter((dd) => dates.includes(dd.date))
)

export const visibleDataStreams = getDataStreamsForCurrentLocations
export const selectedShiftDetailsByTeam: (state: GlobalState) => { [team_id: string]: Array<number> } = createSelector(
  (state) => state.settings.selected_shift_details,
  shiftDetailByID,
  (selected_shift_details: Array<number>, shiftDetailByID: { [shift_detail_id: string]: ShiftDetailRubyType }) =>
    _.groupBy(selected_shift_details, (sd) => shiftDetailByID[String(sd)]?.department_id)
)

export const visibleRhowPlaceholdersForCurrentFilters: (state: GlobalState) => Array<ScheduleType> = createSelector(
  visibleSchedules,
  visibleUserIds,
  (schedules: Array<ScheduleType>, user_ids: Array<number>) =>
    schedules.filter((s) => (s.needs_acceptance || s.acceptance_status === "accepted") && !s.start && !s.finish)
)

export const visibleSchedulesForCurrentFiltersIncludingNoTeam: (state: GlobalState) => Array<ScheduleType> =
  createSelector(
    visibleSchedules,
    getVisibleTeams,
    filteredAwardTags,
    usersInCurrentFilters,
    selectedShiftDetailsByTeam,
    (
      schedules: Array<ScheduleType>,
      teams: Array<TeamRubyType>,
      filtered_award_tags: Array<string>,
      users: Array<UserType>,
      selected_shift_details_by_team: { [team_id: string]: Array<number> }
    ) => {
      const team_ids: Array<number> = [...teams.map((t) => t.id), Constants.DEFAULT_TEAM.id]
      const scheds_in_teams = schedules.filter((s) => team_ids.includes(s.department_id))
      const u_ids = [...users.map((u) => u.id), Constants.DEFAULT_USER.id]
      const scheds_in_filtered_users = scheds_in_teams.filter((s) => u_ids.includes(s.user_id))
      return scheds_in_filtered_users.filter((s) => {
        const shift_details = selected_shift_details_by_team[String(s.department_id)] || []
        return shift_details.length > 0 ? shift_details.includes(s.shift_detail_id) : true
      })
    }
  )

export const visibleScheduleIdsForCurrentFiltersIncludingNoTeam: (state: GlobalState) => Array<number> = createSelector(
  visibleSchedulesForCurrentFiltersIncludingNoTeam,
  (schedules: Array<ScheduleType>): Array<number> => schedules.map((s) => s.id)
)

export const visibleSchedulesForCurrentFilters: (state: GlobalState) => Array<ScheduleType> = createSelector(
  visibleSchedulesForCurrentFiltersIncludingNoTeam,
  (schedules: Array<ScheduleType>) => schedules.filter((s) => s.department_id != null)
)

export const visibleSchedulesForCurrentFiltersByTeam: (state: GlobalState) => { [date: string]: Array<ScheduleType> } =
  createSelector(visibleSchedulesForCurrentFilters, (schedules: Array<ScheduleType>) =>
    _.groupBy(schedules, (s) => s.department_id)
  )

export const visibleSchedulesForCurrentFiltersByDate: (state: GlobalState) => { [date: string]: Array<ScheduleType> } =
  createSelector(visibleSchedulesForCurrentFilters, (schedules: Array<ScheduleType>) =>
    _.groupBy(schedules, (s) => s.date)
  )

export const visibleSchedulesForCurrentFiltersIncludingNoTeamByDate: (state: GlobalState) => {
  [date: string]: Array<ScheduleType>,
} = createSelector(visibleSchedulesForCurrentFiltersIncludingNoTeam, (schedules: Array<ScheduleType>) =>
  _.groupBy(schedules, (s) => s.date)
)

export const visibleSchedulesForCurrentFiltersIncludingNoTeamByTeam: (state: GlobalState) => {
  [date: string]: Array<ScheduleType>,
} = createSelector(visibleSchedulesForCurrentFiltersIncludingNoTeam, (schedules: Array<ScheduleType>) =>
  _.groupBy(schedules, (s) => s.department_id)
)

export const schedulesWithOvertime: (state: GlobalState) => Array<ScheduleType> = createSelector(
  visibleSchedules,
  scheduleToOvertimeAICS,
  (
    schedules: Array<ScheduleType>,
    schedule_to_overtime_aics: { [sched_id: string]: Array<AICRubyType> }
  ): Array<ScheduleType> => schedules.filter((s) => (schedule_to_overtime_aics[String(s.id)] || []).length > 0)
)

export const scheduleIdsWithOvertime: (state: GlobalState) => Array<number> = createSelector(
  schedulesWithOvertime,
  (schedules_with_overtime: Array<ScheduleType>): Array<number> => schedules_with_overtime.map((s) => s.id)
)

export const visibleSchedulesWithOvertime: (state: GlobalState) => Array<ScheduleType> = createSelector(
  visibleScheduleIdsForCurrentFiltersIncludingNoTeam,
  schedulesWithOvertime,
  (visible_schedule_ids: Array<number>, schedules_with_overtime: Array<ScheduleType>): Array<ScheduleType> =>
    schedules_with_overtime.filter((s) => visible_schedule_ids.includes(s.id))
)

export const scheduleIdsPendingAcceptanceForVisibleUsers: (state: GlobalState) => Array<number> = createSelector(
  visibleScheduleIdsForCurrentFiltersIncludingNoTeam,
  visibleSchedules,
  visibleUserIds,
  (
    visible_schedule_ids_for_current_filters: Array<number>,
    schedules: Array<ScheduleType>,
    visible_user_ids: Array<number>
  ) =>
    schedules
      .filter(
        (s) =>
          s.needs_acceptance &&
          s.acceptance_status === "not_accepted" &&
          visible_user_ids.includes(s.user_id) &&
          s.last_published_at !== null &&
          visible_schedule_ids_for_current_filters.includes(s.id)
      )
      .map((s) => s.id)
)

export const getDataStreamIdsForCurrentLocations: (state: GlobalState) => Array<number> = createSelector(
  getDataStreamJoinsForCurrentLocations,
  (state) => state.data_streams,
  (data_stream_joins: Array<DataStreamJoinRubyType>, data_streams: Array<DataStreamRubyType>) =>
    _.uniq(data_stream_joins.map((d) => d.data_stream_id))
)

export const locationDataStreamIdsToFilterOut: (state: GlobalState) => Array<number> = createSelector(
  dataStreamJoinsByLocation,
  teamsByLocation,
  (state) => state.settings.selected_team_ids,
  (
    dataStreamJoinsByLocationId: { [location_id: string]: Array<DataStreamJoinRubyType> },
    teamsByLocation: { [location_id: string]: Array<TeamRubyType> },
    selectedTeamIds: Array<number>
  ) => {
    if (selectedTeamIds.length === 0) return []

    const dataStreamIdsToFilterOut = []
    for (const locationId in dataStreamJoinsByLocationId) {
      const teamIdsForLocation = teamsByLocation[locationId]?.map((team) => team.id) || []
      const selectedTeamIdsForLocation = selectedTeamIds.filter((team_id) => teamIdsForLocation.includes(team_id))
      const notAllLocationTeamsSelected = selectedTeamIdsForLocation.length !== teamIdsForLocation.length
      const dataStreamJoinsByDataStreamId = _.groupBy(
        dataStreamJoinsByLocationId[locationId],
        (dsj) => dsj.data_stream_id
      )
      for (const dataStreamId in dataStreamJoinsByDataStreamId) {
        const dataStreamableTypes = new Set(
          dataStreamJoinsByDataStreamId[dataStreamId].map((dsj) => dsj.data_streamable_type)
        )

        if (!dataStreamableTypes.has("Department") && notAllLocationTeamsSelected) {
          dataStreamIdsToFilterOut.push(Number(dataStreamId))
        }
      }
    }
    return dataStreamIdsToFilterOut
  }
)

// Get list of Datastream IDs that have a "Department" join that don't match the current team filter
export const getDataStreamsNotVisibleByTeamFilter: (state: GlobalState) => Array<number> = createSelector(
  getVisibleTeamIds,
  dataStreamJoinsByTeam,
  locationDataStreamIdsToFilterOut,
  (
    visibleTeams: Array<number>,
    dataStreamJoinsByTeam: { [team_id: string]: Array<DataStreamJoinRubyType> },
    locationDataStreamIdsToFilterOut: Array<number>
  ) => {
    const dataStreamIdsVisible = _.filter(dataStreamJoinsByTeam, (dsj, team_id) =>
      visibleTeams.includes(Number(team_id))
    )
      .flat()
      .map((ds) => ds.data_stream_id)
    const teamDataStreamIdsNotVisible = _.filter(
      dataStreamJoinsByTeam,
      (dsj, team_id) => !visibleTeams.includes(Number(team_id))
    )
      .flat()
      .map((ds) => ds.data_stream_id)
    const allNonVisibleDataStreams = [teamDataStreamIdsNotVisible, locationDataStreamIdsToFilterOut].flat()

    return _.difference(allNonVisibleDataStreams, dataStreamIdsVisible) // Only return datastreams that don't match ANY team that's visible
  }
)

export const currentLocationsDemandConfig: (state: GlobalState) => Array<CognitiveDemandConfigType> = createSelector(
  (state) => state.cognitive.demand_config,
  (state) => state.settings.selected_location_ids,
  (dcs: Array<CognitiveDemandConfigType>, loc_ids: Array<number>) =>
    dcs.filter((dc) => loc_ids.includes(Number(dc.location_id)))
)

export const currentLocationsVisibleDatesDemandConfig: (state: GlobalState) => Array<CognitiveDemandConfigType> =
  createSelector(
    currentLocationsDemandConfig,
    dateByDailyScheduleID,
    startStr,
    finishStr,
    (
      dcs: Array<CognitiveDemandConfigType>,
      date_by_ds_id: { [ds_id: string]: string },
      start: string,
      finish: string
    ) =>
      dcs.filter((dc) => {
        const date = date_by_ds_id[String(dc.daily_schedule_id)]
        return date >= start && date <= finish
      })
  )

export const locationDataLoadPromise: (state: GlobalState) => Promise<mixed> = createSelector(
  (state) => state.settings.selected_location_ids,
  allVisibleDatesStr,
  (state) => state.cache.location_data,
  (ids: Array<number>, dates: Array<string>, cache: LocationDataCache) =>
    Promise.all(_.flatten(ids.map((id) => dates.map((date) => cache[String(id)]?.[String(date)] || Promise.resolve()))))
)

export const currentLocationDemandConfigByDate: (state: GlobalState) => { [date: string]: CognitiveDemandConfigType } =
  createSelector(
    currentLocationsDemandConfig,
    dateByDailyScheduleID,
    (dcs: Array<CognitiveDemandConfigType>, date_by_ds_id: { [ds_id: string]: string }) =>
      _.mapValues(
        _.groupBy(dcs, (ds) => date_by_ds_id[String(ds.daily_schedule_id)]),
        (dss) => dss[0]
      )
  )
export const currentLocationsDemandConfigByDateByLocation: (state: GlobalState) => {
  [location_id: string]: { [date: string]: CognitiveDemandConfigType },
} = createSelector(
  currentLocationsDemandConfig,
  dateByDailyScheduleID,
  (dcs: Array<CognitiveDemandConfigType>, date_by_ds_id: { [ds_id: string]: string }) =>
    _.mapValues(
      _.groupBy(dcs, (dc) => dc.location_id),
      (dcs_for_loc) =>
        _.mapValues(
          _.groupBy(dcs_for_loc, (ds) => date_by_ds_id[String(ds.daily_schedule_id)]),
          (dss) => dss[0]
        )
    )
)
export const currentLocationsDemandConfigByDate: (state: GlobalState) => {
  [date: string]: Array<CognitiveDemandConfigType>,
} = createSelector(
  currentLocationsDemandConfig,
  dateByDailyScheduleID,
  (dcs: Array<CognitiveDemandConfigType>, date_by_ds_id: { [ds_id: string]: string }) =>
    _.groupBy(dcs, (ds) => date_by_ds_id[String(ds.daily_schedule_id)])
)

export const currentLocationDemandConfigByDateMomentized: (state: GlobalState) => {
  [date: string]: CognitiveDemandConfigMomentizedType,
} = createSelector(currentLocationDemandConfigByDate, (dc_by_date: { [date: string]: CognitiveDemandConfigType }) =>
  _.mapValues(dc_by_date, (dss: CognitiveDemandConfigType) => {
    const multiple_dates = dss.multiple_dates || []
    const available_dates = _.uniq([...multiple_dates, ...(dss.available_dates || [])])
    return {
      ...dss,
      multiple_dates: (dss.multiple_dates || []).map((d) => moment(d, C.DATE_FMT)),
      available_dates: available_dates.map((d) => moment(d, C.DATE_FMT)),
    }
  })
)

export const predictionModifiersByDataStream: (state: GlobalState) => {
  [ds_id: string]: Array<PredictionModifierType>,
} = createSelector(
  (state) => state.cognitive.prediction_modifiers,
  (prediction_modifiers: Array<PredictionModifierType>) => _.groupBy(prediction_modifiers, (pm) => pm.data_stream_id)
)

export const predictionModifiersByLocationIDByDate: (state: GlobalState) => {
  [date: string]: { [location_id: string]: Array<PredictionModifierType> },
} = createSelector(
  (state) => state.cognitive.prediction_modifiers,
  demandConfigByID,
  dailyScheduleByID,
  (
    prediction_modifiers: Array<PredictionModifierType>,
    dc_by_id: { [id: string]: CognitiveDemandConfigType },
    ds_by_id: { [date: string]: DailyScheduleType }
  ) =>
    prediction_modifiers.reduce<{ [date: string]: { [location_id: string]: Array<PredictionModifierType> } }>(
      (acc, pm) => {
        const demand_config = dc_by_id[String(pm.projections_table_configuration_id)]
        const location_id = demand_config?.location_id
        const date = ds_by_id[String(demand_config?.daily_schedule_id)]?.date
        if (date && demand_config) {
          acc[date] = acc[date] || {}
          acc[date][String(location_id)] = acc[date][String(location_id)] || []
          acc[date][String(location_id)].push(pm)
        }
        return acc
      },
      ({}: { [date: string]: { [location_id: string]: Array<PredictionModifierType> } })
    )
)

export const predictionModifierByDataStreamIDByDate: (state: GlobalState) => {
  [date: string]: { [ds_id: string]: Array<PredictionModifierType> },
} = createSelector(
  (state) => state.cognitive.prediction_modifiers,
  dataStreamByID,
  currentLocationsDemandConfig,
  dateByDailyScheduleID,
  (
    prediction_modifiers: Array<PredictionModifierType>,
    data_streams_by_id: { [ds_id: string]: DataStreamRubyType },
    dcs: Array<CognitiveDemandConfigType>,
    date_by_ds_id: { [ds_id: string]: string }
  ) => {
    // we need this to only show the modifiers for a specific date. Mondays Projections data has the modifiers
    // but then tuesday can see it as well. Not good
    const modifiers_by_dcs: { [ptc_id: string]: Array<PredictionModifierType> } = _.groupBy(
      prediction_modifiers,
      (pm) => pm.projections_table_configuration_id
    )
    return dcs.reduce(
      (acc, ds) => ({
        ...acc,
        [date_by_ds_id[String(ds.daily_schedule_id)]]:
          _.groupBy(modifiers_by_dcs[String(ds.id)], (m) => m.data_stream_id) || ({}: $Shape<{||}>),
      }),
      ({}: $Shape<{||}>)
    )
  }
)

export const currentLocationsBusinessHours: (state: GlobalState) => Array<BusinessHoursType> = createSelector(
  (state) => state.config.business_hours,
  (state) => state.settings.selected_location_ids,
  (bhs: Array<BusinessHoursType>, loc_ids: Array<number>) => bhs.filter((bh) => loc_ids.includes(bh.location_id))
)

export const cognitiveSettingsByTeam: (state: GlobalState) => { [dep_id: string]: CognitiveCreatorConfigurationType } =
  createSelector(
    (state) => state.cognitive.cognitive_creator_configurations,
    (state) => state.static.teams,
    (cccs: Array<CognitiveCreatorConfigurationType>, teams: Array<TeamRubyType>) => {
      const grouped = _.mapValues(
        _.groupBy(cccs, (ccc) => ccc.department_id),
        (cs) => cs[0]
      )
      return teams.reduce<{ [dep_id: string]: CognitiveCreatorConfigurationType }>((acc, t) => {
        acc[String(t.id)] = acc[String(t.id)] || {
          ...Constants.DEFAULT_COGNITIVE_CREATOR_CONFIGURATION,
          department_id: t.id,
        }
        return acc
      }, grouped)
    }
  )
export const visibleRosters: (state: GlobalState) => Array<RosterRubyType> = createSelector(
  getRosters,
  (state) => state.settings.start_date,
  (state) => state.settings.finish_date,
  (rosters: Array<RosterRubyType>, start_date: moment, finish_date: moment) => {
    const start_str: string = start_date.format(C.DATE_FMT)
    const finish_str: string = finish_date.format(C.DATE_FMT)
    return rosters.filter((r) => r.start <= finish_str && r.end >= start_str)
  }
)

export const canManage: (state: GlobalState) => boolean = createSelector(
  (state) => state.config.managed_team_ids,
  (managed_team_ids: Array<number>) => managed_team_ids.filter((t_id) => t_id !== Constants.DEFAULT_TEAM.id).length > 0
)

export const managedLocationIds: (state: GlobalState) => Array<number> = createSelector(
  managedTeams,
  (managed_teams: Array<TeamRubyType>) => _.uniq(managed_teams.map((t) => t.location_id))
)

export const managedLocations: (state: GlobalState) => Array<LocationRubyType> = createSelector(
  managedLocationIds,
  locationByID,
  (managed_locations_ids: Array<number>, location_by_id: { [location_id: string]: LocationRubyType }) =>
    managed_locations_ids.map((l) => location_by_id[String(l)])
)

export const locationsWithManagedUsersInThem: (state: GlobalState) => Array<LocationRubyType> = createSelector(
  (state) => state.static.locations,
  managedTeams,
  usersThatWorkInDeps,
  locationByID,
  (
    locations: Array<LocationRubyType>,
    managedTeams: Array<TeamRubyType>,
    usersThatWorkInDeps: { [dep_id: string]: Array<UserType> },
    locationByID: { [loc_id: string]: LocationRubyType }
  ) => {
    const usersByLocation: { [l_id: string]: Array<UserType> } = _.mapValues(
      _.groupBy(managedTeams, (t) => t.location_id),
      (teams, l_id) => _.uniq(teams.flatMap((t) => usersThatWorkInDeps[String(t.id)])).filter(Boolean)
    )
    const pairs: Array<[string, Array<UserType>]> = _.toPairs(usersByLocation)
    const locationsWithUsers: Array<[string, Array<UserType>]> = pairs.filter(([l_id, users]) => users.length > 0)
    const locationsSortedByUserCount: Array<LocationRubyType> = _.sortBy(
      locationsWithUsers,
      ([l_id, users]) => users.length * -1
    ).map(([l_id, users]) => locationByID[l_id])
    return locationsSortedByUserCount
  }
)

export const getIdealInitialLocation: (state: GlobalState) => Array<number> = createSelector(
  (state) => state.static.locations,
  locationsWithManagedUsersInThem,
  usersThatWorkInDeps,
  (locations: Array<LocationRubyType>, managedLocations: Array<LocationRubyType>) => [
    managedLocations[0]?.id || _.sortBy(locations, (l) => l.name)[0]?.id || Constants.DEFAULT_LOCATION.id,
  ]
)

export const dailySchedulesToRosterID: (state: GlobalState) => { [daily_schedule_id: string]: number } = createSelector(
  getDailySchedules,
  (daily_schedules: Array<DailyScheduleType>) =>
    daily_schedules.reduce((acc, ds) => ({ ...acc, [ds.id]: ds.roster_id }), ({}: $Shape<{||}>))
)

export const dailySchedulesByDate: (state: GlobalState) => { [date: string]: DailyScheduleType } = createSelector(
  getDailySchedules,
  (daily_schedules: Array<DailyScheduleType>) =>
    _.mapValues(
      _.groupBy(daily_schedules, (ds) => ds.date),
      (dss) => dss[0]
    )
)

export const rosterByDate: (state: GlobalState) => { [date: string]: RosterRubyType } = createSelector(
  dailySchedulesByDate,
  rosterByID,
  (dailySchedulesByDate: { [date: string]: DailyScheduleType }, rosterByID: { [roster_id: string]: RosterRubyType }) =>
    _.mapValues(dailySchedulesByDate, (ds) => rosterByID[ds.roster_id])
)

export const getRosterToVisibleSchedules: (state: GlobalState) => { [roster_id: string]: Array<ScheduleType> } =
  createSelector(
    visibleSchedules,
    visibleRosters,
    dailySchedulesToRosterID,
    dailySchedulesByDate,
    (
      visible_schedules: Array<ScheduleType>,
      visible_rosters: Array<RosterRubyType>,
      daily_schedule_to_roster_id: { [daily_schedule_id: string]: number },
      daily_schedules_by_date: { [date: string]: DailyScheduleType }
    ) => {
      const roster_to_schedules = _.groupBy(
        visible_schedules,
        (s: ScheduleType) => daily_schedule_to_roster_id[String((daily_schedules_by_date[s.date] || {}).id)]
      )
      return visible_rosters.reduce(
        (acc, r) => ({ ...acc, [r.id]: roster_to_schedules[String(r.id)] || [] }),
        ({}: $Shape<{||}>)
      )
    }
  )

export const rosterToDates: (state: GlobalState) => { [roster_id: string]: Array<string> } = createSelector(
  rosterByID,
  (rosterByID: { [roster_id: string]: RosterRubyType }) =>
    _.mapValues(rosterByID, (roster: RosterRubyType) => HelperFunc.getWeeksWorthOfDates(roster.start))
)

export const getRosterToVisibleVacantSchedules: (state: GlobalState) => { [roster_id: string]: Array<ScheduleType> } =
  createSelector(
    vacantSchedules,
    visibleRosters,
    dailySchedulesToRosterID,
    dailySchedulesByDate,
    (
      vacant_schedules: Array<ScheduleType>,
      visible_rosters: Array<RosterRubyType>,
      daily_schedule_to_roster_id: { [daily_schedule_id: string]: number },
      daily_schedules_by_date: { [date: string]: DailyScheduleType }
    ) => {
      const roster_to_schedules = _.groupBy(
        vacant_schedules,
        (s: ScheduleType) => daily_schedule_to_roster_id[String((daily_schedules_by_date[s.date] || {}).id)]
      )

      return visible_rosters.reduce(
        (acc, r) => ({ ...acc, [r.id]: roster_to_schedules[String(r.id)] || [] }),
        ({}: $Shape<{||}>)
      )
    }
  )

export const getRosterToRDOs: (state: GlobalState) => { [roster_id: string]: Array<RDORubyType> } = createSelector(
  getRDOs,
  getRosters,
  dailySchedulesToRosterID,
  dailySchedulesByDate,
  (
    rdos: Array<RDORubyType>,
    rosters: Array<RosterRubyType>,
    daily_schedule_to_roster_id: { [daily_schedule_id: string]: number },
    daily_schedules_by_date: { [date: string]: DailyScheduleType }
  ) => {
    const roster_to_rdos = _.groupBy(
      rdos,
      (rdo: RDORubyType) => daily_schedule_to_roster_id[String((daily_schedules_by_date[rdo.date] || {}).id)]
    )
    return rosters.reduce((acc, r) => ({ ...acc, [r.id]: roster_to_rdos[String(r.id)] || [] }), ({}: $Shape<{||}>))
  }
)

export const getRosterToSchedules: (state: GlobalState) => { [roster_id: string]: Array<ScheduleType> } =
  createSelector(
    getSchedules,
    getRosters,
    dailySchedulesToRosterID,
    dailySchedulesByDate,
    (
      schedules: Array<ScheduleType>,
      rosters: Array<RosterRubyType>,
      daily_schedule_to_roster_id: { [daily_schedule_id: string]: number },
      daily_schedules_by_date: { [date: string]: DailyScheduleType }
    ) => {
      const roster_to_schedules = _.groupBy(
        schedules,
        (s: ScheduleType) => daily_schedule_to_roster_id[String((daily_schedules_by_date[s.date] || {}).id)]
      )
      return rosters.reduce(
        (acc, r) => ({ ...acc, [r.id]: roster_to_schedules[String(r.id)] || [] }),
        ({}: $Shape<{||}>)
      )
    }
  )

export const visiblePublishedSchedules: (state: GlobalState) => Array<PublishedScheduleType> = createSelector(
  publishedSchedules,
  allVisibleDatesStr,
  (published_schedules: Array<PublishedScheduleType>, dates: Array<string>) =>
    published_schedules.filter((s) => dates.includes(s.date))
)

export const visiblePublishedSchedulesByUser: (state: GlobalState) => {
  [user_id: string]: Array<PublishedScheduleType>,
} = createSelector(visiblePublishedSchedules, (published_schedules: Array<PublishedScheduleType>) => {
  // Purposefully written this way for perf reasons
  // This way is approx 100x faster than traditional fp reducers
  const map: { [user_id: string]: Array<PublishedScheduleType> } = {}
  published_schedules.forEach((s) => {
    map[String(s.user_id)] = map[String(s.user_id)] || []
    map[String(s.user_id)].push(s)
  })
  return map
})

export const publishedSchedulesByUser: (state: GlobalState) => { [user_id: string]: Array<PublishedScheduleType> } =
  createSelector(publishedSchedules, (published_schedules: Array<PublishedScheduleType>) => {
    // Purposefully written this disgusting way for perf reasons
    // This way is approx 100x faster than traditional fp reducers
    const map: { [user_id: string]: Array<PublishedScheduleType> } = {}
    published_schedules.forEach((s) => {
      map[String(s.user_id)] = map[String(s.user_id)] || []
      map[String(s.user_id)].push(s)
    })
    return map
  })

export const visibleSchedulesByUser: (state: GlobalState) => { [user_id: string]: Array<ScheduleType> } =
  createSelector(visibleSchedules, (visible_schedules: Array<ScheduleType>) => {
    // Purposefully written this disgusting way for perf reasons
    // This way is approx 100x faster than traditional fp reducers
    const map: { [user_id: string]: Array<ScheduleType> } = {}
    visible_schedules.forEach((s) => {
      map[String(s.user_id)] = map[String(s.user_id)] || []
      map[String(s.user_id)].push(s)
    })
    return map
  })

export const schedulesForVisibleRosters: (state: GlobalState) => Array<ScheduleType> = createSelector(
  getRosterToSchedules,
  visibleRosters,
  (scheds_by_roster: { [roster_id: string]: Array<ScheduleType> }, visible_rosters: Array<RosterRubyType>) =>
    _.flatten(visible_rosters.map((r) => scheds_by_roster[String(r.id)] || []))
)

export const visibleSchedulesForVisibleUserIds: (state: GlobalState) => Array<ScheduleType> = createSelector(
  schedulesForVisibleRosters,
  visibleUserIds,
  (schedules: Array<ScheduleType>, user_ids: Array<number>) =>
    schedules.filter((s) => s.user_id !== -1 && user_ids.includes(s.user_id))
)

export const schedulesForVisibleRostersByUser: (state: GlobalState) => { [user_id: string]: Array<ScheduleType> } =
  createSelector(schedulesForVisibleRosters, (visible_schedules: Array<ScheduleType>) => {
    // Purposefully written this disgusting way for perf reasons
    // This way is approx 100x faster than traditional fp reducers
    const map: { [user_id: string]: Array<ScheduleType> } = {}
    visible_schedules.forEach((s) => {
      map[String(s.user_id)] = map[String(s.user_id)] || []
      map[String(s.user_id)].push(s)
    })
    return map
  })

export const visibleSchedulesForKeyStatByUser: (state: GlobalState) => { [user_id: string]: Array<ScheduleType> } =
  createSelector(
    isDayView,
    visibleSchedulesByUser,
    schedulesForVisibleRostersByUser,
    (
      is_day_view: boolean,
      visible_schedules_by_user: { [user_id: string]: Array<ScheduleType> },
      schedules_for_visible_rosters_by_user: { [user_id: string]: Array<ScheduleType> }
    ) => (is_day_view ? schedules_for_visible_rosters_by_user : visible_schedules_by_user)
  )

export const visibleSchedulesByLocation: (state: GlobalState) => { [location_id: string]: Array<ScheduleType> } =
  createSelector(
    visibleSchedules,
    teamToLocation,
    (visible_schedules: Array<ScheduleType>, team_to_location: { [team_id: string]: LocationRubyType }) => {
      // Purposefully written this disgusting way for perf reasons
      // This way is approx 100x faster than traditional fp reducers
      const map: { [location_id: string]: Array<ScheduleType> } = {}
      visible_schedules.forEach((s) => {
        const loc_id = team_to_location[String(s.department_id)] || Constants.DEFAULT_LOCATION.id
        map[String(loc_id)] = map[String(loc_id)] || []
        map[String(loc_id)].push(s)
      })
      return map
    }
  )

export const visibleSchedulesByUserByLocation: (state: GlobalState) => {
  [location_id: string]: { [user_id: string]: Array<ScheduleType> },
} = createSelector(
  visibleSchedulesByLocation,
  (schedules_by_location: { [location_id: string]: Array<ScheduleType> }) =>
    _.mapValues(schedules_by_location, (ss) => _.groupBy(ss, (s) => s.user_id))
)

export const visibleSchedulesByTeam: (state: GlobalState) => { [team_id: string]: Array<ScheduleType> } =
  createSelector(visibleSchedules, (visible_schedules: Array<ScheduleType>) =>
    _.groupBy(visible_schedules, (s) => s.department_id)
  )

export const visibleSchedulesByDateByUser: (state: GlobalState) => {
  [user_id: string]: { [date: string]: Array<ScheduleType> },
} = createSelector(visibleSchedulesByUser, (visible_schedules_by_user: { [user_id: string]: Array<ScheduleType> }) => {
  // Purposefully written this disgusting way for perf reasons
  // This way is approx 100x faster than traditional fp reducers
  const map: { [user_id: string]: { [date: string]: Array<ScheduleType> } } = {}
  Object.keys(visible_schedules_by_user).forEach((u_id) => {
    const date_map: { [date: string]: Array<ScheduleType> } = {}
    visible_schedules_by_user[u_id].forEach((s) => {
      date_map[s.date] = date_map[s.date] || []
      date_map[s.date].push(s)
    })
    map[u_id] = date_map
  })

  return map
})

export const publishedSchedulesByDateByUser: (state: GlobalState) => {
  [user_id: string]: { [date: string]: Array<PublishedScheduleType> },
} = createSelector(
  publishedSchedulesByUser,
  (published_schedules_by_user: { [user_id: string]: Array<PublishedScheduleType> }) => {
    // Purposefully written this disgusting way for perf reasons
    // This way is approx 100x faster than traditional fp reducers
    const map: { [user_id: string]: { [date: string]: Array<PublishedScheduleType> } } = {}
    Object.keys(published_schedules_by_user).forEach((u_id) => {
      const date_map: { [date: string]: Array<PublishedScheduleType> } = {}
      published_schedules_by_user[u_id].forEach((s) => {
        date_map[s.date] = date_map[s.date] || []
        date_map[s.date].push(s)
      })
      map[u_id] = date_map
    })
    return map
  }
)

export const visibleDailyScheduleJoins: (state: GlobalState) => Array<UserDailyScheduleJoinType> = createSelector(
  visibleUsers,
  visibleDailySchedules,
  dailyScheduleUserJoinByDateByUser,
  (
    users: Array<UserType>,
    daily_schedules: Array<DailyScheduleType>,
    dsuj_by_date_by_user: { [user_id: string]: { [date: string]: UserDailyScheduleJoinType } }
  ) =>
    users.flatMap((u: UserType) =>
      daily_schedules.map(
        (ds) =>
          dsuj_by_date_by_user[String(u.id)]?.[ds.date] || {
            ...Constants.DEFAULT_USER_DAILY_SCHEDULE_JOIN,
            user_id: u.id,
            date: ds.date,
            daily_schedule_id: ds.id,
          }
      )
    )
)

export const visibleInactiveUserIds: (state: GlobalState) => Array<number> = createSelector(
  visibleUsers,
  (users: Array<UserType>) => users.filter((u) => !u.is_active).map((u) => u.id)
)

export const visibleDailyScheduleJoinsThatNeedPublishing: (state: GlobalState) => Array<UserDailyScheduleJoinType> =
  createSelector(
    (state) => state.settings.selected_team_ids,
    visibleDailyScheduleJoins,
    publishedSchedulesByDateByUser,
    visibleSchedulesByDateByUser,
    visibleInactiveUserIds,
    (
      selected_team_ids: Array<number>,
      visibleDailyScheduleJoins: Array<UserDailyScheduleJoinType>,
      publishedSchedulesByDateByUser: { [user_id: string]: { [date: string]: Array<PublishedScheduleType> } },
      visibleSchedulesByDateByUser: { [user_id: string]: { [date: string]: Array<ScheduleType> } },
      visibleInactiveUserIds: Array<number>
    ) =>
      visibleDailyScheduleJoins
        .filter(
          (udsj: UserDailyScheduleJoinType) =>
            udsj.user_id !== Constants.DEFAULT_USER.id && !visibleInactiveUserIds.includes(udsj.user_id)
        )
        .filter((udsj: UserDailyScheduleJoinType) => {
          const selectedSchedules =
            ((visibleSchedulesByDateByUser[String(udsj.user_id)] || [])[String(udsj.date)] || []).filter((s) =>
              selected_team_ids.includes(s.department_id)
            ) || []

          const selectedPublishedSchedules =
            ((publishedSchedulesByDateByUser[String(udsj.user_id)] || [])[String(udsj.date)] || []).filter((s) =>
              selected_team_ids.includes(s.department_id)
            ) || []

          return Schedule.someRequirePublishing(selectedSchedules, selectedPublishedSchedules)
        })
  )

export const visibleDailyScheduleJoinsWithDeletedPublishedShifts: (
  state: GlobalState
) => Array<UserDailyScheduleJoinType> = createSelector(
  visibleDailyScheduleJoins,
  publishedSchedulesByDateByUser,
  visibleSchedulesByDateByUser,
  visibleInactiveUserIds,
  (
    visibleDailyScheduleJoins: Array<UserDailyScheduleJoinType>,
    publishedSchedulesByDateByUser: { [user_id: string]: { [date: string]: Array<PublishedScheduleType> } },
    visibleSchedulesByDateByUser: { [user_id: string]: { [date: string]: Array<ScheduleType> } },
    visibleInactiveUserIds: Array<number>
  ) =>
    visibleDailyScheduleJoins
      .filter((udsj: UserDailyScheduleJoinType) => !visibleInactiveUserIds.includes(udsj.user_id))
      .filter((udsj: UserDailyScheduleJoinType) => {
        const schedules = visibleSchedulesByDateByUser[String(udsj.user_id)]?.[String(udsj.date)] || []
        const publishedSchedules = publishedSchedulesByDateByUser[String(udsj.user_id)]?.[String(udsj.date)] || []
        return publishedSchedules.some(
          (ps) => schedules.length === 0 || schedules.some((s) => !Schedule.isPublishedSame(s, ps))
        )
      })
)

export const visibleDailyScheduleJoinsWithDeletedPublishedShiftsByDateByUser: (state: GlobalState) => {
  [user_id: string]: { [date: string]: UserDailyScheduleJoinType },
} = createSelector(visibleDailyScheduleJoinsWithDeletedPublishedShifts, groupDailyScheduleUserJoinByDateByUser)

export const visibleDailyScheduleJoinsThatNeedPublishingByDateByUser: (state: GlobalState) => {
  [user_id: string]: { [date: string]: UserDailyScheduleJoinType },
} = createSelector(visibleDailyScheduleJoinsThatNeedPublishing, groupDailyScheduleUserJoinByDateByUser)

export const visibleDailyScheduleJoinsThatNeedPublishingByUser: (state: GlobalState) => {
  [user_id: string]: Array<UserDailyScheduleJoinType>,
} = createSelector(
  visibleDailyScheduleJoinsThatNeedPublishing,
  (user_daily_schedule_joins: Array<UserDailyScheduleJoinType>) =>
    _.groupBy(user_daily_schedule_joins, (usdj) => usdj.user_id)
)

// includes vacant shifts since users can choose to publish all shifts or just assigned shifts
const filterSchedulesThatNeedPublishing = (
  offeredVacantSchedules: Array<ScheduleSwapPlanRubyType>,
  schedules: Array<ScheduleType>,
  publishedSchedules: { [user_id: string]: { [date: string]: Array<PublishedScheduleType> } }
): Array<ScheduleType> => {
  const offeredVacantScheduleIds = offeredVacantSchedules.map((s) => s.schedule_id)

  return schedules.filter((s: ScheduleType) => {
    const relevantPublishedScheds = publishedSchedules[String(s.user_id)]?.[String(s.date)] || []

    if (HelperFunc.isANotRosteredShiftCard(s)) {
      return false
    } else if (s.user_id === Constants.DEFAULT_USER.id) {
      // Vacant shifts can only be published if they're 'claimable'. So we can tell if a vacant schedule
      // needs to be published by verifying that no schedule swap plan exists in state for that sched
      return !offeredVacantScheduleIds.includes(s.id)
    } else {
      return (
        relevantPublishedScheds.length === 0 ||
        !relevantPublishedScheds.some((ps) => s.id === ps.schedule_id && Schedule.isPublishedSame(s, ps))
      )
    }
  })
}

export const visibleSchedulesThatNeedPublishing: (state: GlobalState) => Array<ScheduleType> = createSelector(
  (state) => state.schedule_swap_plans,
  visibleSchedules,
  publishedSchedulesByDateByUser,
  filterSchedulesThatNeedPublishing
)

export const visibleSchedulesInCurrentFiltersThatNeedPublishing: (state: GlobalState) => Array<ScheduleType> =
  createSelector(
    (state) => state.schedule_swap_plans,
    visibleSchedulesForCurrentFiltersIncludingNoTeam,
    publishedSchedulesByDateByUser,
    filterSchedulesThatNeedPublishing
  )

export const visibleSchedulesIdsThatNeedPublishing: (state: GlobalState) => Array<number> = createSelector(
  visibleSchedulesThatNeedPublishing,
  (schedules: Array<ScheduleType>) => schedules.map((s) => s.id)
)

export const doesVisibleScheduleNeedPublishing: (state: GlobalState) => { [sched_id: string]: boolean } =
  createSelector(visibleSchedulesThatNeedPublishing, (schedules: Array<ScheduleType>) =>
    _.mapValues(
      _.groupBy(schedules, (s) => s.id),
      (s) => true
    )
  )

export const visibleSchedulesByTeamByUser: (state: GlobalState) => {
  [user_id: string]: { [team_id: string]: Array<ScheduleType> },
} = createSelector(visibleSchedulesByUser, (visible_schedules_by_user: { [user_id: string]: Array<ScheduleType> }) => {
  // Purposefully written this disgusting way for perf reasons
  // This way is approx 100x faster than traditional fp reducers
  const map: { [user_id: string]: { [team_id: string]: Array<ScheduleType> } } = {}
  Object.keys(visible_schedules_by_user).forEach((u_id) => {
    const team_map: { [team_id: string]: Array<ScheduleType> } = {}
    visible_schedules_by_user[u_id].forEach((s) => {
      team_map[String(s.department_id)] = team_map[String(s.department_id)] || []
      team_map[String(s.department_id)].push(s)
    })
    map[u_id] = team_map
  })
  return map
})

export const visibleSchedulesByDateByTeamByUser: (state: GlobalState) => {
  [user_id: string]: { [team_id: string]: { [date: string]: Array<ScheduleType> } },
} = createSelector(
  visibleSchedulesByTeamByUser,
  (visible_schedules_by_team_by_user: { [user_id: string]: { [team_id: string]: Array<ScheduleType> } }) => {
    // Purposefully written this disgusting way for perf reasons
    // This way is approx 100x faster than traditional fp reducers
    const map: { [user_id: string]: { [team_id: string]: { [date: string]: Array<ScheduleType> } } } = {}
    Object.keys(visible_schedules_by_team_by_user).forEach((u_id) => {
      const team_map: { [team_id: string]: { [date: string]: Array<ScheduleType> } } = {}
      Object.keys(visible_schedules_by_team_by_user[u_id]).forEach((team_id) => {
        const date_map: { [date: string]: Array<ScheduleType> } = {}
        const schedules = visible_schedules_by_team_by_user[u_id][team_id]
        schedules.forEach((s) => {
          date_map[s.date] = date_map[s.date] || []
          date_map[s.date].push(s)
        })
        team_map[team_id] = date_map
      })
      map[u_id] = team_map
    })
    return map
  }
)

export const visibleRDOsByUser: (state: GlobalState) => { [user_id: string]: Array<RDORubyType> } = createSelector(
  visibleRDOs,
  (visible_rdos: Array<RDORubyType>) => {
    // Purposefully written this disgusting way for perf reasons
    // This way is approx 100x faster than traditional fp reducers
    const map: { [user_id: string]: Array<RDORubyType> } = {}
    visible_rdos.forEach((rdo) => {
      map[String(rdo.user_id)] = map[String(rdo.user_id)] || []
      map[String(rdo.user_id)].push(rdo)
    })
    return map
  }
)

export const visibleRDOsByDateByUser: (state: GlobalState) => {
  [user_id: string]: { [date: string]: Array<RDORubyType> },
} = createSelector(visibleRDOsByUser, (visible_rdos_by_user: { [user_id: string]: Array<RDORubyType> }) => {
  // Purposefully written this disgusting way for perf reasons
  // This way is approx 100x faster than traditional fp reducers
  const map: { [user_id: string]: { [date: string]: Array<RDORubyType> } } = {}
  Object.keys(visible_rdos_by_user).forEach((u_id) => {
    const date_map: { [date: string]: Array<RDORubyType> } = {}
    visible_rdos_by_user[u_id].forEach((s) => {
      date_map[s.date] = date_map[s.date] || []
      date_map[s.date].push(s)
    })
    map[u_id] = date_map
  })
  return map
})

export const visibleRDOsByDate: (state: GlobalState) => {
  [date: string]: Array<RDORubyType>,
} = createSelector(visibleRDOs, (visible_rdos: Array<RDORubyType>) => {
  // Purposefully written this disgusting way for perf reasons
  // This way is approx 100x faster than traditional fp reducers
  const map: { [date: string]: Array<RDORubyType> } = {}
  visible_rdos.forEach((rdo) => {
    map[rdo.date] = map[rdo.date] || []
    map[rdo.date].push(rdo)
  })
  return map
})

export const rdosForVisibleRosters: (state: GlobalState) => Array<RDORubyType> = createSelector(
  getRosterToRDOs,
  visibleRosters,
  (rdos_by_roster: { [roster_id: string]: Array<RDORubyType> }, visible_rosters: Array<RosterRubyType>) =>
    _.flatten(visible_rosters.map((r) => rdos_by_roster[String(r.id)] || []))
)

export const rdosForVisibleRostersByUser: (state: GlobalState) => { [user_id: string]: Array<RDORubyType> } =
  createSelector(rdosForVisibleRosters, (visible_rdos: Array<RDORubyType>) => {
    // Purposefully written this disgusting way for perf reasons
    // This way is approx 100x faster than traditional fp reducers
    const map: { [user_id: string]: Array<RDORubyType> } = {}
    visible_rdos.forEach((rdo) => {
      map[String(rdo.user_id)] = map[String(rdo.user_id)] || []
      map[String(rdo.user_id)].push(rdo)
    })
    return map
  })

export const visibleRDOsForKeyStatByUser: (state: GlobalState) => { [user_id: string]: Array<RDORubyType> } =
  createSelector(
    isDayView,
    visibleRDOsByUser,
    rdosForVisibleRostersByUser,
    (
      is_day_view: boolean,
      visible_rdos_by_user: { [user_id: string]: Array<RDORubyType> },
      rdos_for_visible_rosters_by_user: { [user_id: string]: Array<RDORubyType> }
    ) => (is_day_view ? rdos_for_visible_rosters_by_user : visible_rdos_by_user)
  )

export const schedulesByTemplate: (state: GlobalState) => { [template_id: string]: Array<ScheduleType> } =
  createSelector(
    (state) => state.schedules,
    rostersByTemplate,
    dailySchedulesByTemplate,
    templateByID,
    (
      schedules: Array<ScheduleType>,
      rosters_by_template_id: { [template_id: string]: Array<RosterRubyType> },
      ds_by_template_id: { [template_id: string]: Array<DailyScheduleType> },
      template_by_id: { [template_id: string]: TemplateRubyType }
    ) =>
      _.mapValues(ds_by_template_id, (daily_schedules, template_id) => {
        const ds_ids = daily_schedules.map((ds) => ds.id)
        const maybe_template: ?TemplateRubyType = template_by_id[template_id]
        if (maybe_template == null) {
          return schedules.filter((s) => ds_ids.includes(s.daily_schedule_id))
        }
        const template: TemplateRubyType = maybe_template
        const maybe_roster = rosters_by_template_id[template_id]?.[0]
        const start_date = maybe_roster == null ? template.start_date : maybe_roster.start
        const dates = HelperFunc.getAllDatesStr(
          moment(start_date, C.DATE_FMT),
          moment(start_date, C.DATE_FMT).add(template.length - 1, "days")
        )

        return schedules.filter((s) => ds_ids.includes(s.daily_schedule_id) && dates.includes(s.date))
      })
  )

export const scheduleForCurrentFiltersByTemplate: (state: GlobalState) => {
  [template_id: string]: Array<ScheduleType>,
} = createSelector(
  schedulesByTemplate,
  teamInCurrentFiltersMap,
  userIDsInCurrentLocations,
  (
    schedules_by_template: { [template_id: string]: Array<ScheduleType> },
    team_in_current_location: { [team_id: string]: boolean },
    user_ids: Array<number>
  ) =>
    _.mapValues(schedules_by_template, (scheds) =>
      scheds.filter((s) => {
        if (team_in_current_location[String(s.department_id)]) {
          return true
        }
        if (s.department_id === Constants.DEFAULT_TEAM.id) {
          return s.user_id === Constants.DEFAULT_USER.id || user_ids.includes(s.user_id)
        }
      })
    )
)

export const contractShiftsByTemplateByUser: (state: GlobalState) => {
  [user_id: string]: { [template_id: string]: Array<ScheduleType> },
} = createSelector(
  getRHoWByUser,
  schedulesByTemplate,
  (
    rhow_by_user: { [user_id: string]: Array<TemplateRubyType> },
    schedules_by_template: { [template_id: string]: Array<ScheduleType> }
  ) => {
    // Purposefully written this way for perf reasons
    const userMap: { [user_id: string]: { [template_id: string]: Array<ScheduleType> } } = {}

    _.toPairs(rhow_by_user).forEach(([user_id, rhow_templates]) => {
      userMap[user_id] = userMap[user_id] || {}

      rhow_templates.forEach((template) => {
        userMap[user_id][String(template.id)] = schedules_by_template[String(template.id)] || []
      })
    })

    return userMap
  }
)

export const employmentConditionSetsByUserId: (state: GlobalState) => {
  [user_id: string]: Array<EmploymentConditionSetRubyType>,
} = createSelector(
  (state) => state.employment_condition_sets,
  (employmentConditionSets: Array<EmploymentConditionSetRubyType>) =>
    _.groupBy(employmentConditionSets, (employmentConditionSet) => String(employmentConditionSet.user_id))
)

export const applicableEmploymentConditionSetsByVisibleUserIdByVisibleDates: (state: GlobalState) => {
  [date: string]: { [user_id: string]: ?EmploymentConditionSetRubyType },
} = createSelector(
  employmentConditionSetsByUserId,
  visibleUserIds,
  allVisibleDatesStr,
  (
    employmentConditionSetsByUserId: { [user_id: string]: Array<EmploymentConditionSetRubyType> },
    visibleUserIds: Array<number>,
    visibleDates: Array<string>
  ) => {
    // Purposefully written this way for perf reasons
    const employmentConditionSetsByVisibleDatesByUser = {}

    visibleDates.forEach((date) => {
      employmentConditionSetsByVisibleDatesByUser[date] = {}

      visibleUserIds.forEach((userId) => {
        const userIdStr = String(userId)
        employmentConditionSetsByVisibleDatesByUser[date][userIdStr] = (
          employmentConditionSetsByUserId[userIdStr] || []
        ).find((employmentConditionSet) =>
          HelperFunc.isDateWithinRange(date, employmentConditionSet.from_date, employmentConditionSet.to_date)
        )
      })
    })

    return employmentConditionSetsByVisibleDatesByUser
  }
)

export const userIdsByPositionGroupIdByDate: (state: GlobalState) => {
  [date: string]: { [position_group_id: string]: Array<string> },
} = createSelector(
  applicableEmploymentConditionSetsByVisibleUserIdByVisibleDates,
  positionIdsGroupedByPositionGroupId,
  (
    employment_condition_sets_by_user_id_by_date: {
      [date: string]: { [user_id: string]: ?EmploymentConditionSetRubyType },
    },
    positions_grouped_by_position_group_id: { [position_group_id: string]: Array<string> }
  ) => {
    const obj: {
      [date: string]: { [position_id: string]: Array<string> },
    } = _.mapValues(employment_condition_sets_by_user_id_by_date, (employment_condition_sets_by_user) => {
      const entries: Array<[string, EmploymentConditionSetRubyType]> = Object.keys(employment_condition_sets_by_user)
        .map((user_id) => [user_id, employment_condition_sets_by_user[user_id]])
        .filter(([_, employment_condition_set]) => !!employment_condition_set)

      const by_position: { [position: string]: Array<[string, EmploymentConditionSetRubyType]> } = _.groupBy(
        entries,
        ([_, employment_condition_set]: [string, EmploymentConditionSetRubyType]) =>
          employment_condition_set && employment_condition_set.position_id
            ? String(employment_condition_set.position_id)
            : "-1"
      )

      const user_id_by_position_id: { [position_id: string]: Array<string> } = _.mapValues(by_position, (pairs) =>
        pairs.map(([user_id, _]) => user_id)
      )
      const user_id_by_position_group_id: { [position_group_id: string]: Array<string> } = _.mapValues(
        positions_grouped_by_position_group_id,
        (positionIds: Array<string>) => _.flatMap(positionIds, (positionId) => user_id_by_position_id[positionId] || [])
      )
      return user_id_by_position_group_id
    })
    return obj
  }
)

export const contractShiftsByDateByUser: (state: GlobalState) => {
  [user_id: string]: { [date: string]: Array<ScheduleType> },
} = createSelector(
  applicableEmploymentConditionSetsByVisibleUserIdByVisibleDates,
  templateUserJoinsByEmploymentConditionSetId,
  templatesJoinsByUser,
  rostersByTemplate,
  templateByID,
  contractShiftsByTemplateByUser,
  visibleUserIds,
  allVisibleDatesStr,
  (state) => state.settings.start_date,
  (
    applicable_employment_condition_sets_by_user_id_by_visible_dates: {
      [date: string]: { [user_id: string]: ?EmploymentConditionSetRubyType },
    },
    template_user_joins_by_employment_condition_set_id: {
      [employment_contract_id: string]: TemplateUserJoinRubyType,
    },
    templates_joins_by_user: { [user_id: string]: Array<TemplateUserJoinRubyType> },
    rosters_by_template_id: { [template_id: string]: Array<RosterRubyType> },
    template_by_id: { [template_id: string]: TemplateRubyType },
    contract_shifts_by_template_by_user: { [user_id: string]: { [template_id: string]: Array<ScheduleType> } },
    visible_user_ids: Array<number>,
    visible_dates: Array<string>,
    start_date: moment
  ) => {
    // Purposefully written this way for perf reasons
    const contract_shifts_by_date_by_user: { [user_id: string]: { [date: string]: Array<ScheduleType> } } = {}

    visible_user_ids.forEach((user_id) => {
      contract_shifts_by_date_by_user[String(user_id)] = {}

      visible_dates.forEach((date) => {
        contract_shifts_by_date_by_user[String(user_id)][date] = []

        const applicable_employment_condition_set =
          applicable_employment_condition_sets_by_user_id_by_visible_dates[date][String(user_id)]
        // Although we have a contract there may not be RHW attached to it
        const applicable_template_user_join =
          template_user_joins_by_employment_condition_set_id[String(applicable_employment_condition_set?.id)] ||
          (templates_joins_by_user[String(user_id)] || []).find(
            (template_join) => template_join.employment_contract_id == null
          )
        if (applicable_template_user_join == null) {
          return
        }

        const applicable_template = template_by_id[String(applicable_template_user_join.roster_template_id)]
        const maybe_roster = rosters_by_template_id[String(applicable_template.id)]?.[0]
        const rhow_date_to_use = maybe_roster == null ? applicable_template.start_date : maybe_roster.start
        const rhow_start = moment(rhow_date_to_use, C.DATE_FMT)
        const applicable_contract_shifts =
          contract_shifts_by_template_by_user[String(user_id)]?.[
            String(applicable_template_user_join.roster_template_id)
          ] || []
        const contract_shifts_by_day: { [day: string]: Array<ScheduleType> } = _.groupBy(
          applicable_contract_shifts,
          (shift) => moment(shift.date, C.DATE_FMT).diff(rhow_start, "days")
        )

        const diff_from_start: number = start_date.diff(rhow_start, "days")
        const offset_from_anchor: number = HelperFunc.mod(diff_from_start, applicable_template.length)
        const map_dates_to_day = visible_dates.reduce(
          (accumulator, date, index) => ({
            ...accumulator,
            [date]: (index + offset_from_anchor) % applicable_template.length,
          }),
          ({}: { [date: string]: number })
        )

        contract_shifts_by_date_by_user[String(user_id)][date] = (
          contract_shifts_by_day[String(map_dates_to_day[date])] || []
        ).map((shift) => ({
          ...shift,
          user_id,
          date,
          ...Schedule.normaliseTimes(date, shift.start, shift.finish),
        }))
      })
    })

    return contract_shifts_by_date_by_user
  }
)

// "Unified" means we fuse together consecutive schedules into a single schedule object. E.g. If you have the following
// { id: 1, start: "2024-08-21 08:00:00", finish: "2024-08-21 12:30:00", schedule_breaks: [{...}] }
// { id: 2, start: "2024-08-21 12:30:00", finish: "2024-08-21 16:00:00", schedule_breaks: [{...}, {...}] }
// We would fuse them together into:
// { id: 1, start: "2024-08-21 08:00:00", finish: "2024-08-21 16:00:00", schedule_breaks: [{...}, {...}, {...}] }
// This is so you can do task based rostering and it doesn't need to exactly match the number of RHW schedules as
// long as the times are the same
export const visibleUnifiedConsecutiveSchedulesByDateByUser: (state: GlobalState) => {
  [user_id: string]: { [date: string]: Array<ScheduleType> },
} = createSelector(visibleSchedulesByUser, (schedules_by_user: { [user_id: string]: Array<ScheduleType> }) => {
  // Purposefully written this way for perf reasons
  const map: { [user_id: string]: { [date: string]: Array<ScheduleType> } } = {}

  _.toPairs(schedules_by_user).forEach(([user_id, schedules]) => {
    const consecutive_schedules = []
    schedules.forEach((schedule) => {
      const consec_sched_array_to_push_to = consecutive_schedules.find((consec_sched_array) =>
        consec_sched_array.find((consec_sched) => consec_sched.finish === schedule.start)
      )
      if (consec_sched_array_to_push_to != null) {
        consec_sched_array_to_push_to.push(schedule)
      } else {
        consecutive_schedules.push([schedule])
      }
    })

    const unified_consecutive_schedules = []
    consecutive_schedules.forEach((consec_sched_array) => {
      const sorted_scheds = _.sortBy(consec_sched_array, ["start"])
      // Also put the original schedules into the array, in case we do want to match with them exactly
      sorted_scheds.forEach((sched) => unified_consecutive_schedules.push(sched))

      unified_consecutive_schedules.push(
        sorted_scheds.reduce((unified_sched, sched) => {
          if (unified_sched == null) {
            return sched
          }
          return {
            ...unified_sched,
            finish: sched.finish,
            schedule_breaks: [...unified_sched.schedule_breaks, ...sched.schedule_breaks],
          }
        })
      )
    })

    map[user_id] = _.groupBy(unified_consecutive_schedules, (s) => s.date)
  })

  return map
})

export const unfilledContractShiftsByDateByUser: (state: GlobalState) => {
  [user_id: string]: { [date: string]: Array<ScheduleType> },
} = createSelector(
  contractShiftsByDateByUser,
  visibleUnifiedConsecutiveSchedulesByDateByUser,
  (
    contract_shifts_by_date_by_user: { [user_id: string]: { [date: string]: Array<ScheduleType> } },
    schedules_by_date_by_user: { [user_id: string]: { [date: string]: Array<ScheduleType> } }
  ) => {
    // Purposefully written this way for perf reasons
    const map: { [user_id: string]: { [date: string]: Array<ScheduleType> } } = {}

    _.toPairs(contract_shifts_by_date_by_user).forEach(([user_id, contract_shifts_by_date]) => {
      map[user_id] = map[user_id] || {}

      _.toPairs(contract_shifts_by_date).forEach(([date, contract_shifts]) => {
        map[user_id][date] = contract_shifts.filter(
          (contract_shift) =>
            !(schedules_by_date_by_user[user_id]?.[date] || []).some((existing_shift) =>
              Schedule.sameHours(existing_shift, contract_shift)
            )
        )
      })
    })

    return map
  }
)

export const contractRDOsByTemplate: (state: GlobalState) => { [templateId: string]: Array<RDORubyType> } =
  createSelector(
    (state) => state.contractRDOs,
    dailySchedulesByTemplate,
    templateByID,
    (
      contractRDOs: Array<RDORubyType>,
      dailySchedulesByTemplateId: { [templateId: string]: Array<DailyScheduleType> },
      templatesById: { [templateId: string]: TemplateRubyType }
    ) =>
      _.mapValues(dailySchedulesByTemplateId, (dailySchedules, templateId) => {
        const dailyScheduleIds = dailySchedules.map((ds) => ds.id)

        const template: ?TemplateRubyType = templatesById[templateId]
        if (template == null) {
          return contractRDOs.filter((rdo) => dailyScheduleIds.includes(rdo.daily_schedule_id))
        }

        const dates = HelperFunc.getAllDatesStr(
          moment(template.start_date, C.DATE_FMT),
          moment(template.start_date, C.DATE_FMT).add(template.length - 1, "days")
        )

        const rdosByTemplate = contractRDOs.filter(
          (rdo) => dailyScheduleIds.includes(rdo.daily_schedule_id) && dates.includes(rdo.date)
        )

        return rdosByTemplate
      })
  )

export const contractRDOsByTemplateByUser: (state: GlobalState) => {
  [user_id: string]: { [template_id: string]: Array<RDORubyType> },
} = createSelector(
  getRHoWByUser,
  contractRDOsByTemplate,
  (
    rhow_by_user: { [user_id: string]: Array<TemplateRubyType> },
    rdos_by_template: { [template_id: string]: Array<RDORubyType> }
  ) => {
    // Purposefully written this way for perf reasons
    const userMap: { [user_id: string]: { [template_id: string]: Array<RDORubyType> } } = {}

    _.toPairs(rhow_by_user).forEach(([user_id, rhow_templates]) => {
      userMap[user_id] = userMap[user_id] || {}

      rhow_templates.forEach((template) => {
        userMap[user_id][String(template.id)] = rdos_by_template[String(template.id)] || []
      })
    })

    return userMap
  }
)

export const contractRDOsByDateByUser: (state: GlobalState) => {
  [user_id: string]: { [date: string]: Array<RDORubyType> },
} = createSelector(
  applicableEmploymentConditionSetsByVisibleUserIdByVisibleDates,
  templateUserJoinsByEmploymentConditionSetId,
  templatesJoinsByUser,
  templateByID,
  contractRDOsByTemplateByUser,
  dailySchedulesByDate,
  visibleUserIds,
  allVisibleDatesStr,
  (state) => state.settings.start_date,
  (
    applicable_employment_condition_sets_by_user_id_by_visible_dates: {
      [date: string]: { [user_id: string]: ?EmploymentConditionSetRubyType },
    },
    template_user_joins_by_employment_condition_set_id: {
      [employment_contract_id: string]: TemplateUserJoinRubyType,
    },
    templates_joins_by_user: { [user_id: string]: Array<TemplateUserJoinRubyType> },
    template_by_id: { [template_id: string]: TemplateRubyType },
    contract_rdos_by_template_by_user: { [user_id: string]: { [template_id: string]: Array<RDORubyType> } },
    daily_schedules_by_date: { [date: string]: DailyScheduleType },
    visible_user_ids: Array<number>,
    visible_dates: Array<string>,
    start_date: moment
  ) => {
    // Purposefully written this way for perf reasons
    const contract_rdos_by_date_by_user: { [user_id: string]: { [date: string]: Array<RDORubyType> } } = {}

    visible_user_ids.forEach((user_id) => {
      contract_rdos_by_date_by_user[String(user_id)] = {}

      visible_dates.forEach((date) => {
        contract_rdos_by_date_by_user[String(user_id)][date] = []

        const applicable_employment_condition_set =
          applicable_employment_condition_sets_by_user_id_by_visible_dates[date][String(user_id)]
        // Although we have a contract there may not be RHW attached to it
        const applicable_template_user_join =
          template_user_joins_by_employment_condition_set_id[String(applicable_employment_condition_set?.id)] ||
          (templates_joins_by_user[String(user_id)] || []).find(
            (template_join) => template_join.employment_contract_id == null
          )
        if (applicable_template_user_join == null) {
          return
        }

        const applicable_template = template_by_id[String(applicable_template_user_join.roster_template_id)]
        const rhow_start = moment(applicable_template.start_date, C.DATE_FMT)

        const applicable_contract_rdos =
          contract_rdos_by_template_by_user[String(user_id)]?.[
            String(applicable_template_user_join.roster_template_id)
          ] || []
        const contract_rdos_by_day: { [day: string]: Array<RDORubyType> } = _.groupBy(applicable_contract_rdos, (rdo) =>
          moment(rdo.date, C.DATE_FMT).diff(rhow_start, "days")
        )

        const diff_from_start: number = start_date.diff(rhow_start, "days")
        const offset_from_anchor: number = HelperFunc.mod(diff_from_start, applicable_template.length)
        const map_dates_to_day = visible_dates.reduce(
          (accumulator, date, index) => ({
            ...accumulator,
            [date]: (index + offset_from_anchor) % applicable_template.length,
          }),
          ({}: { [date: string]: number })
        )

        contract_rdos_by_date_by_user[String(user_id)][date] = (
          contract_rdos_by_day[String(map_dates_to_day[date])] || []
        ).map((rdo) => ({
          ...rdo,
          user_id,
          date,
          daily_schedule_id: daily_schedules_by_date[date].id,
        }))
      })
    })

    return contract_rdos_by_date_by_user
  }
)

export const unfilledContractRDOsByDateByUser: (state: GlobalState) => {
  [userId: string]: { [date: string]: Array<RDORubyType> },
} = createSelector(
  contractRDOsByDateByUser,
  visibleRDOsByDateByUser,
  (contractRDOsByDateByUser, visibleRDOsByDateByUser) => {
    // Purposefully written this way for perf reasons
    const map: { [userId: string]: { [date: string]: Array<RDORubyType> } } = {}

    _.toPairs(contractRDOsByDateByUser).forEach(([userId, contractRDOsByDate]) => {
      map[userId] = map[userId] || {}

      _.toPairs(contractRDOsByDate).forEach(([date, contractRDOs]) => {
        map[userId][date] = contractRDOs.filter((contractRDO) => {
          const existingRDOs = visibleRDOsByDateByUser[userId]?.[date] || []
          const hasNoMatchingRDOs = !existingRDOs.some(
            (existingRDO) =>
              // start and finish are datetimes as strings e.g. "2023-04-23 00:00:00"
              // Before the check, the dates of the RDOs are mapped to be the date of the contracted RDO
              // So there is no point checking the dates match, as they always will, so we can just check times
              // by splitting the string into two, and checking the second half.
              existingRDO.start.split(" ")[1] === contractRDO.start.split(" ")[1] &&
              existingRDO.finish.split(" ")[1] === contractRDO.finish.split(" ")[1]
          )
          return hasNoMatchingRDOs
        })
      })
    })

    return map
  }
)

export const missingContractShiftsByDateByUser: (state: GlobalState) => {
  [user_id: string]: { [date: string]: Array<ScheduleType> },
} = createSelector(
  contractShiftsByDateByUser,
  visibleSchedulesByDateByUser,
  (
    contract_shifts_by_date_by_user: { [user_id: string]: { [date: string]: Array<ScheduleType> } },
    schedules_by_date_by_user: { [user_id: string]: { [date: string]: Array<ScheduleType> } }
  ) => {
    // Purposefully written this way for perf reasons
    const map: { [user_id: string]: { [date: string]: Array<ScheduleType> } } = {}

    _.toPairs(contract_shifts_by_date_by_user).forEach(([user_id, contract_shifts_by_date]) => {
      map[user_id] = map[user_id] || {}

      _.toPairs(contract_shifts_by_date).forEach(([date, contract_shifts]) => {
        map[user_id][date] = contract_shifts.filter(
          (_existing_shift) => (schedules_by_date_by_user[user_id]?.[date] || []).length === 0
        )
      })
    })
    return map
  }
)

export const customValidationObjectsByUser: (state: GlobalState) => {
  [user_id: string]: Array<CustomValidationObject>,
} = createSelector(
  (state) => state.config.employee_custom_validations_map,
  (state) => state.config.custom_validation_settings,
  (
    employee_custom_validations_map: UserCustomValidationMap,
    custom_validation_settings: CustomValidationSettings
  ): { [user_id: string]: Array<CustomValidationObject> } =>
    _.mapValues(employee_custom_validations_map, (custom_validation_setting_ids) =>
      custom_validation_setting_ids.map((id) => custom_validation_settings[String(id)]).filter((cs) => cs != null)
    )
)

export const customValidationObjectsByDateForVisibleDates: (state: GlobalState) => {
  [date: string]: Array<CustomValidationObject>,
} = createSelector(
  (state) => state.config.custom_validation_settings,
  allVisibleDates,
  (
    custom_validation_settings: CustomValidationSettings,
    visibleDates: Array<moment>
  ): { [date: string]: Array<CustomValidationObject> } => {
    const dateToValidSetting: { [date: string]: Array<CustomValidationObject> } = {}
    _.values(custom_validation_settings).map((vs) => {
      const date_ranges: Array<{ endDate: ?string, startDate: ?string }> = _.values(vs.date_filters.date_ranges)
      const applicable_day_indexes: Array<number> = vs.date_filters.selected_days
      const visibleDatesWhereSettingIsValid = visibleDates.filter((d) => {
        const is_inside_date_ranges =
          date_ranges.length === 0 ||
          _.some(
            date_ranges.map(
              (dr) =>
                (dr.startDate == null || d.isAfter(dr.startDate)) && (dr.endDate == null || d.isBefore(dr.endDate))
            )
          )
        const is_on_day_indexes =
          applicable_day_indexes.length === 0 || applicable_day_indexes.includes(d.isoWeekday() % 7)
        return is_inside_date_ranges && is_on_day_indexes
      })
      visibleDatesWhereSettingIsValid.forEach((d) => {
        const date_str = d.format(C.DATE_FMT)
        dateToValidSetting[date_str] = [...(dateToValidSetting[date_str] || []), vs]
      })
    })
    return dateToValidSetting
  }
)

export const customValidationObjectsByDateByUser: (state: GlobalState) => {
  [user_id: string]: { [date: string]: Array<CustomValidationObject> },
} = createSelector(
  customValidationObjectsByDateForVisibleDates,
  (state) => state.config.employee_custom_validations_map,
  (
    date_to_validation: { [date: string]: Array<CustomValidationObject> },
    employee_custom_validations_map: UserCustomValidationMap
  ) =>
    _.mapValues(employee_custom_validations_map, (custom_validation_setting_ids_for_user: Array<number>) =>
      _.mapValues(date_to_validation, (validation_settings: Array<CustomValidationObject>) =>
        validation_settings.filter((vs) => custom_validation_setting_ids_for_user.includes(vs.id))
      )
    )
)

export const scheduleOvertimeValidationErrors: (state: GlobalState) => {
  [sched_id: string]: Array<ScheduleValidationType>,
} = createSelector(
  visibleSchedules,
  scheduleToOvertimeAICS,
  (state) => state.validations.schedule_validation_errors,
  scheduleByID,
  userByID,
  (state) => state,
  (
    schedules: Array<ScheduleType>,
    schedule_to_overtime_aics: { [sched_id: string]: Array<AICRubyType> },
    schedule_validation_errors: { [schedule_id: string]: Array<ScheduleValidationType> },
    schedule_by_id: { [schedule_id: string]: ScheduleType },
    user_by_id: { [user_id: string]: UserType },
    state: GlobalState
  ): { [sched_id: string]: Array<ScheduleValidationType> } => {
    const new_schedule_validations: { [sched_id: string]: Array<ScheduleValidationType> } = {}
    const type = "shift_has_overtime"

    if (!ValidationHelpers.validationTypeIsEnabled(state, type)) {
      return new_schedule_validations
    }

    _.mapValues(schedule_to_overtime_aics, (value: Array<AICRubyType>, key: string) => {
      if (value.length > 0) {
        const length: number = value.map(getAICLength).reduce((a, b) => a + b, 0)
        const schedule = schedule_by_id[key] || Constants.DEFAULT_SCHEDULE
        const user = user_by_id[String(schedule.user_id)] || Constants.DEFAULT_USER
        if (schedule.id !== Constants.DEFAULT_SCHEDULE.id && user.id !== Constants.DEFAULT_USER.id) {
          const error = {
            schedule_id: Number(key),
            error: {
              message: t(`validations.message.${type}`, {
                name: user.name,
                length: Math.round(length * 100) / 100,
              }),
              name: t(`validations.name.${type}`),
              type: type,
              severity: ValidationHelpers.validationTypeIsBlocking(state, type) ? "blocking" : "warning",
              severity_fallback_sort: length,
            },
          }
          new_schedule_validations[key] = [error, ...(new_schedule_validations[key] || [])]
        }
      }
    })

    return new_schedule_validations
  }
)

export const scheduleAwardConditionErrors: (state: GlobalState) => {
  [sched_id: string]: Array<ScheduleValidationType>,
} = createSelector(
  visibleSchedules,
  scheduleToAICS,
  awardById,
  customValidationObjectsByDateByUser,
  (
    schedules: Array<ScheduleType>,
    schedule_to_aics: { [sched_id: string]: Array<AICRubyType> },
    award_by_id: { [award_id: string]: AwardType },
    custom_validation_objects_by_date_by_user: { [user_id: string]: { [date: string]: Array<CustomValidationObject> } }
  ): { [sched_id: string]: Array<ScheduleValidationType> } =>
    schedules.reduce((acc: { [sched_id: string]: Array<ScheduleValidationType> }, schedule: ScheduleType) => {
      const aics = schedule_to_aics[String(schedule.id)] || []
      const custom_validation_objects =
        custom_validation_objects_by_date_by_user[String(schedule.user_id)]?.[String(schedule.date)] || []
      const applicable_cvos = custom_validation_objects.filter((cvo) => cvo.overtime_conditions.length > 0)

      if (applicable_cvos.length > 0) {
        const type = "shift_award_ot_conditions"
        const schedule_aic_awards = aics.map((aic) => award_by_id[String(aic.ruleable_id)]).filter(Boolean)
        const awards_with_active_rules = schedule_aic_awards.filter((award) => award.active_rules.length > 0)
        if (awards_with_active_rules.length > 0) {
          applicable_cvos.map((cvo: CustomValidationObject) => {
            // validate_ot_shifts indicates that we only want
            // to flag rules that ARENT ordindary_hours
            const applicable_rules = cvo.validate_ot_shifts
              ? awards_with_active_rules.filter((award) => !award.is_ord_hours)
              : awards_with_active_rules

            const active_ot_rules_on_schedule = _.uniq(_.flatten(applicable_rules.map((award) => award.active_rules)))
            const incurred_conditions = _.intersection(cvo.overtime_conditions, active_ot_rules_on_schedule)

            if (incurred_conditions.length > 0) {
              const condition_names = incurred_conditions
                .map((c) => t(`validations.overtime_description.${c}`))
                .join(", ")
              acc[String(schedule.id)] = [
                {
                  schedule_id: schedule.id,
                  error: {
                    message: t(`validations.message.${type}`, { conditions: condition_names }),
                    name: t(`validations.name.${type}`),
                    type: type,
                    severity: cvo.block_roster_publishing ? "blocking" : "warning",
                    severity_fallback_sort: 0,
                  },
                },
              ]
            }
          })
        }
      }
      return acc
    }, {})
)

export const schedulePeriodValidationErrors: (state: GlobalState) => {
  [sched_id: string]: Array<ScheduleValidationType>,
} = createSelector(
  (state) => state,
  (state) => state.config.default_validation_field_settings,
  schedulesByUser,
  customValidationObjectsByUser,
  (
    state: GlobalState,
    default_validation_fields: DefaultValidationFieldSettings,
    schedules_by_user: { [user_id: string]: Array<ScheduleType> },
    custom_validation_objects_by_user: { [user_id: string]: Array<CustomValidationObject> }
  ): { [sched_id: string]: Array<ScheduleValidationType> } => {
    const validationsByUser = _.mapValues(schedules_by_user, (userSchedules, userId) => {
      if (userSchedules.length === 0) {
        return []
      }

      const customValidationObjectIdsToSchedules = userSchedules
        .map((schedule) => ({
          schedule: schedule,
          settings: ValidationHelpers.getCustomValidationSettingsApplicableToSchedule(
            state,
            schedule,
            schedule.user_id
          ),
        }))
        .reduce((accumulator, scheduleToSettings) => {
          scheduleToSettings.settings.forEach((setting) => {
            if (
              accumulator[setting.id] &&
              !accumulator[setting.id].some((schedule) => schedule.id === scheduleToSettings.schedule.id)
            ) {
              accumulator[setting.id].push(scheduleToSettings.schedule)
            } else if (!accumulator[setting.id]) {
              accumulator[setting.id] = [scheduleToSettings.schedule]
            }
          })

          return accumulator
        }, {})

      const applicableCustomValidationObjects = _.filter(
        state.config.custom_validation_settings,
        (value, key) => !!customValidationObjectIdsToSchedules[key]
      )

      const customValidations = applicableCustomValidationObjects.flatMap((cvo) => {
        const applicableSchedules = customValidationObjectIdsToSchedules[cvo.id]
        const customValidationIsBlocking = cvo.block_roster_publishing
        const customValidationSeverity = customValidationIsBlocking ? "blocking" : "warning"

        const validationErrors: Array<RosterValidationType> = [
          ...RuledValidations.validateMinGap(
            cvo.fields.min_gap,
            userId,
            applicableSchedules,
            customValidationSeverity,
            state
          ),
        ].map((customValidationError) => ({
          ...customValidationError,
          error: {
            ...customValidationError.error,
            message: ValidationHelpers.addCustomSettingNameToValidationMessage(
              customValidationError.error.message,
              cvo.name
            ),
            severity: cvo.block_roster_publishing ? "blocking" : customValidationError.error.severity,
          },
        }))

        return validationErrors
      })

      // no need to validate the default field if a custom is applying
      if (customValidations.length) {
        return [...customValidations]
      }

      const defaultRuledValidationsAreBlocking = ValidationHelpers.defaultRuledValidationsAreBlocking(state)
      const defaultRuledValidationsSeverity = defaultRuledValidationsAreBlocking ? "blocking" : "warning"

      return [
        ...RuledValidations.validateMinGap(
          default_validation_fields.min_gap,
          userId,
          userSchedules,
          defaultRuledValidationsSeverity,
          state
        ),
      ]
    })

    const accumulatedPeriodValidations = _.flatten(_.values(validationsByUser))

    return accumulatedPeriodValidations
      .flatMap((validationError) =>
        validationError.affected_schedules.map((scheduleId) => ({
          schedule_id: scheduleId,
          error: validationError.error,
        }))
      )
      .reduce((acc, validationError) => {
        acc[String(validationError.schedule_id)] = [validationError]

        return acc
      }, {})
  }
)

export const scheduleValidationErrors: (state: GlobalState) => { [sched_id: string]: Array<ScheduleValidationType> } =
  createSelector(
    visibleSchedules,
    (state) => state.validations.schedule_validation_errors,
    scheduleOvertimeValidationErrors,
    scheduleAwardConditionErrors,
    schedulePeriodValidationErrors,
    (
      schedules: Array<ScheduleType>,
      schedule_validation_errors: { [schedule_id: string]: Array<ScheduleValidationType> },
      schedule_overtime_validation_errors: { [schedule_id: string]: Array<ScheduleValidationType> },
      schedule_award_condition_errors: { [schedule_id: string]: Array<ScheduleValidationType> },
      schedule_period_validation_errors: { [schedule_id: string]: Array<ScheduleValidationType> }
    ): { [schedule_id: string]: Array<ScheduleValidationType> } =>
      schedules.reduce((acc, schedule) => {
        acc[String(schedule.id)] = [
          ...(schedule_validation_errors[String(schedule.id)] || []),
          ...(schedule_overtime_validation_errors[String(schedule.id)] || []),
          ...(schedule_award_condition_errors[String(schedule.id)] || []),
          ...(schedule_period_validation_errors[String(schedule.id)] || []),
        ]
        return acc
      }, {})
  )

export const fullyPublishedVisibleSchedules: (state: GlobalState) => number = createSelector(
  visibleSchedules,
  visibleSchedulesThatNeedPublishing,
  (visible_schedules: Array<ScheduleType>, unpublished_schedules: Array<ScheduleType>) =>
    visible_schedules.length - unpublished_schedules.length
)

export const rosterValidationsByScheduleID: (state: GlobalState) => {
  [schedule_id: string]: Array<RosterValidationType>,
} = createSelector(
  (state) => state.validations.roster_validation_errors,
  (roster_validation_errors: { [roster_id: string]: { [user_id: string]: Array<RosterValidationType> } }) => {
    const all_rves: Array<RosterValidationType> = _.flatten(
      _.flatten(_.values(roster_validation_errors).map((uvs) => _.values(uvs)))
    )
    return all_rves.reduce((acc, rve: RosterValidationType) => {
      rve.affected_schedules.forEach((s_id) => {
        acc[s_id] = [...(acc[s_id] || []), rve]
      })
      return acc
    }, {})
  }
)

export const payPeriodValidationsByScheduleID: (state: GlobalState) => {
  [schedule_id: string]: Array<RosterValidationType>,
} = createSelector(
  (state) => state.validations.pay_period_validation_errors,
  (pay_period_validation_errors: { [user_id: string]: Array<RosterValidationType> }) => {
    const all_pay_period_errors: Array<RosterValidationType> = _.flatten(_.values(pay_period_validation_errors))
    return all_pay_period_errors.reduce((acc, rve: RosterValidationType) => {
      rve.affected_schedules.forEach((s_id) => {
        acc[s_id] = [...(acc[s_id] || []), rve]
      })
      return acc
    }, {})
  }
)

export const validationErrorsByScheduleID: (state: GlobalState) => {
  [schedule_id: string]: Array<ValidationErrorType>,
} = createSelector(
  visibleSchedulesStrID,
  scheduleValidationErrors,
  rosterValidationsByScheduleID,
  payPeriodValidationsByScheduleID,
  (
    schedule_ids: Array<string>,
    schedule_validation_errors: { [schedule_id: string]: Array<ScheduleValidationType> },
    roster_validations_by_schedule_id: { [schedule_id: string]: Array<RosterValidationType> },
    pay_period_validations_by_schedule_id: { [schedule_id: string]: Array<RosterValidationType> }
  ) => {
    // Purposefully written this disgusting way for perf reasons
    // This way is approx 100x faster than traditional fp reducers, See PR #6565
    const map: { [schedule_id: string]: Array<ValidationErrorType> } = {}
    schedule_ids.forEach((s_id) => {
      map[s_id] = _.sortBy(
        [
          ...(schedule_validation_errors[s_id] || []).map((e) => e.error),
          ...(roster_validations_by_schedule_id[s_id] || []).map((e) => e.error),
          ...(pay_period_validations_by_schedule_id[s_id] || []).map((e) => e.error),
        ],
        (error) => (error ? ValidationHelpers.getSeverityValue(error.severity) : null)
      )
    })
    return map
  }
)

/**
 * Roster Validation Errors that affect the user and not just their schedules.
 * These could span multiple rosters but we only return the unique result since our current validation
 * structure is based around the schedules to be published.
 */
export const rosterValidationErrorsByUser: (state: GlobalState) => {
  [user_name: string]: Array<RosterValidationType>,
} = createSelector(
  (state) => state.validations.roster_validation_errors,
  (state) => state.validations.pay_period_validation_errors,
  (
    rosterValidationErrors: { [roster_id: string]: { [user_id: string]: Array<RosterValidationType> } },
    payPeriodValidationErrors: { [user_id: string]: Array<RosterValidationType> }
  ) => {
    const userValidationErrorTypes = ["preferred_hours_under", "under_rostered"]
    const userValidationErrorsForAllRosters: Array<{ [user_id: string]: Array<RosterValidationType> }> =
      _.values(rosterValidationErrors)

    const userOnlyValidationErrors = payPeriodValidationErrors
    userValidationErrorsForAllRosters.forEach((rveSet) => {
      const userIds = [...new Set(_.keys(rveSet).concat(_.keys(payPeriodValidationErrors)))]

      userIds.forEach((userId) => {
        const userRveSet = rveSet[userId] || []

        if (userRveSet.length > 0 || userOnlyValidationErrors[userId]?.length > 0) {
          const filteredSetErrors = userRveSet.filter((setErrors) => {
            const validValidationType = userValidationErrorTypes.includes(setErrors.error.type)
            const noAffectedSchedules = !setErrors.affected_schedules.length

            return validValidationType && noAffectedSchedules
          })

          if (filteredSetErrors.length > 0 || userOnlyValidationErrors[userId]?.length > 0) {
            if (!userOnlyValidationErrors[userId]) {
              userOnlyValidationErrors[userId] = []
            }

            userOnlyValidationErrors[userId] = userOnlyValidationErrors[userId].concat(filteredSetErrors || [])
          }
        }
      })
    })

    return userOnlyValidationErrors
  }
)

export const visibleValidationErrorsExcludingOvertimeScheduleIds: (state: GlobalState) => Array<number> =
  createSelector(
    visibleUserIds,
    schedulesByUser,
    validationErrorsByScheduleID,
    visibleScheduleIdsForCurrentFiltersIncludingNoTeam,
    (
      visible_user_ids: Array<number>,
      schedules_by_user: { [user_id: string]: Array<ScheduleType> },
      schedule_validation_errors: { [schedule_id: string]: Array<ValidationErrorType> },
      visible_schedule_ids: Array<number>
    ): Array<number> => {
      const user_schedule_ids = _.flatten(
        visible_user_ids.map((id) => schedules_by_user[String(id)]).filter(Boolean)
      ).map((s) => s.id)
      const visible_schedule_ids_in_current_filters = user_schedule_ids.filter((id) =>
        visible_schedule_ids.includes(id)
      )
      // Find the schedules with errors but exclude ones ONLY with OT
      // handled inside another selector -> visibleSchedulesWithOvertime
      return _.keys(schedule_validation_errors)
        .filter(
          (s) =>
            schedule_validation_errors[s].length > 0 &&
            !(
              schedule_validation_errors[s].map((s) => s.type).includes("shift_has_overtime") &&
              schedule_validation_errors[s].length === 1
            ) &&
            visible_schedule_ids_in_current_filters.includes(Number(s))
        )
        .map((s) => Number(s))
    }
  )

export const visibleValidationsByDateByUser: (state: GlobalState) => {
  [user_id: string]: { [date: string]: Array<ValidationErrorType> },
} = createSelector(
  visibleSchedulesByDateByUser,
  validationErrorsByScheduleID,
  (
    visible_schedules_by_date_by_user: { [user_id: string]: { [date: string]: Array<ScheduleType> } },
    sched_to_errors: { [schedule_id: string]: Array<ValidationErrorType> }
  ) => {
    // Purposefully written this disgusting way for perf reasons
    // This way is approx 100x faster than traditional fp reducers
    const map: { [user_id: string]: { [date: string]: Array<ValidationErrorType> } } = {}
    Object.keys(visible_schedules_by_date_by_user).forEach((u_id) => {
      const date_map: { [date: string]: Array<ValidationErrorType> } = {}
      Object.keys(visible_schedules_by_date_by_user[u_id]).forEach((date) => {
        date_map[date] = visible_schedules_by_date_by_user[u_id][date].reduce(
          (acc, s) => [...acc, ...sched_to_errors[String(s.id)]],
          []
        )
      })
      map[u_id] = date_map
    })

    return map
  }
)

export const CONSECUTIVE_SCHEDULE_SEARCH_THRESHOLD = 20

/**
 * Returns all visible schedules in (sorted) contiguous collections by user id.
 * Things to note:
 *   - Wraps a single schedule in an array if it's not a part of a contiguous collection or task
 *     based rostering is disabled.
 *   - Threshold for contiguous shifts is 59 minutes (inclusive).
 *   - Sorting into contiguous collections is skipped if the number of schedules for a user spanning
 *     3 days across the schedule being evaluated is >= CONSECUTIVE_SCHEDULE_SEARCH_THRESHOLD. This
 *     is to handle a rare use case where organisations create 'vacant' users for contracting purposes.
 */
export const visibleContiguousScheduleCollectionsByUser: (state: GlobalState) => {
  [userId: string]: Array<Array<ScheduleType>>,
} = createSelector(
  (state) => state,
  visibleSchedulesByDateByUser,
  (state, visibleSchedulesByDateByUser: { [userId: string]: { [date: string]: Array<ScheduleType> } }) =>
    _.toPairs(visibleSchedulesByDateByUser).reduce(
      (accumulator: { [userId: string]: Array<Array<ScheduleType>> }, [userId, userSchedulesByDate]) => {
        if (!ValidationHelpers.taskBasedRosteringIsEnabled(state)) {
          const allUserSchedules = _.flatten(_.values(userSchedulesByDate))
          const allUserSchedulesAsCollections = allUserSchedules.map((schedule) => [schedule])
          accumulator[userId] = allUserSchedulesAsCollections

          return accumulator
        }

        accumulator[userId] = []
        const checkedUserScheduleIds = []

        _.toPairs(userSchedulesByDate).forEach(([date, userSchedulesOnDate]) => {
          userSchedulesOnDate.forEach((schedule: ScheduleType) => {
            const previousDate = moment(schedule.date).subtract(1, "days").format(C.DATE_FMT)
            const nextDate = moment(schedule.date).add(1, "days").format(C.DATE_FMT)

            let otherSchedulesOnSameOrAdjacentDays = [
              ...(userSchedulesByDate[previousDate] || []),
              ...userSchedulesOnDate,
              ...(userSchedulesByDate[nextDate] || []),
            ].filter((otherSchedule) => otherSchedule.id !== schedule.id)

            const contiguousSchedules = []
            if (!checkedUserScheduleIds.includes(schedule.id)) {
              contiguousSchedules.push(schedule)
              checkedUserScheduleIds.push(schedule.id)
            }

            if (
              contiguousSchedules.length &&
              schedule.user_id !== Constants.DEFAULT_USER.id &&
              schedule.start != null &&
              schedule.finish != null &&
              otherSchedulesOnSameOrAdjacentDays.length < CONSECUTIVE_SCHEDULE_SEARCH_THRESHOLD
            ) {
              let currentSchedule = schedule

              const previousContiguousSchedule = ValidationHelpers.findPreviousContiguousSchedule(
                currentSchedule,
                otherSchedulesOnSameOrAdjacentDays
              )
              if (!previousContiguousSchedule) {
                while (otherSchedulesOnSameOrAdjacentDays.length > 0 && currentSchedule) {
                  currentSchedule = ValidationHelpers.findNextContiguousSchedule(
                    currentSchedule,
                    otherSchedulesOnSameOrAdjacentDays
                  )

                  if (currentSchedule) {
                    otherSchedulesOnSameOrAdjacentDays = otherSchedulesOnSameOrAdjacentDays.filter(
                      (otherSchedule) => otherSchedule.id !== currentSchedule?.id
                    )
                    contiguousSchedules.push(currentSchedule)
                    checkedUserScheduleIds.push(currentSchedule.id)
                  }
                }

                accumulator[userId].push(ValidationHelpers.sortContiguousSchedules(schedule, contiguousSchedules))
              }
            } else if (contiguousSchedules.length) {
              accumulator[userId].push(contiguousSchedules)
            }
          })
        })

        return accumulator
      },
      {}
    )
)

export const customEventsByDate: (state: GlobalState) => { [date: string]: Array<CustomEventRubyType> } =
  createSelector(
    (state) => state.custom_events,
    (custom_events: Array<CustomEventRubyType>) =>
      custom_events.reduce((acc, ce) => {
        const all_dates_str = HelperFunc.getAllDatesStr(moment(ce.start, C.DATE_FMT), moment(ce.finish, C.DATE_FMT))
        all_dates_str.forEach((d) => {
          acc[d] = [...(acc[d] || []), ce]
        })
        return acc
      }, {})
  )

export const customEventsInCurrentLocations: (state: GlobalState) => Array<CustomEventRubyType> = createSelector(
  (state) => state.custom_events,
  (state) => state.settings.selected_location_ids,
  (custom_events: Array<CustomEventRubyType>, location_ids: Array<number>) =>
    custom_events.filter(
      (ce) => ce.locations.length === 0 || _.some(location_ids, (loc_id) => ce.locations.includes(loc_id))
    )
)

export const customEventsInCurrentLocationsByDate: (state: GlobalState) => {
  [date: string]: Array<CustomEventRubyType>,
} = createSelector(customEventsInCurrentLocations, (custom_events: Array<CustomEventRubyType>) =>
  custom_events.reduce((acc, ce) => {
    const all_dates_str = HelperFunc.getAllDatesStr(moment(ce.start, C.DATE_FMT), moment(ce.finish, C.DATE_FMT))
    all_dates_str.forEach((d) => {
      acc[d] = [...(acc[d] || []), ce]
    })
    return acc
  }, {})
)

export const visibleCustomEvents: (state: GlobalState) => Array<CustomEventRubyType> = createSelector(
  allVisibleDatesStr,
  customEventsInCurrentLocationsByDate,
  (dates: Array<string>, custom_events_by_date: { [date: string]: Array<CustomEventRubyType> }) =>
    dates.reduce((acc, date) => {
      const ces_ids = (custom_events_by_date[date] || []).map((ce) => ce.id)
      return [...acc.filter((ce) => !ces_ids.includes(ce.id)), ...(custom_events_by_date[date] || [])]
    }, [])
)
export const commentsInCurrentLocations: (state: GlobalState) => Array<CommentRubyType> = createSelector(
  (state) => state.comments,
  (state) => state.settings.selected_location_ids,
  (comments: Array<CommentRubyType>, location_ids: Array<number>) =>
    comments.filter(
      (comment) =>
        comment.locations.length === 0 || _.some(location_ids, (loc_id) => comment.locations.includes(loc_id))
    )
)

export const commentsInCurrentLocationsByDate: (state: GlobalState) => { [date: string]: Array<CommentRubyType> } =
  createSelector(commentsInCurrentLocations, (comments: Array<CommentRubyType>) =>
    comments.reduce((acc, comment) => {
      const all_dates_str = HelperFunc.getAllDatesStr(
        moment(comment.start, C.DATE_FMT),
        moment(comment.finish, C.DATE_FMT)
      )
      all_dates_str.forEach((d) => {
        acc[d] = [...(acc[d] || []), comment]
      })
      return acc
    }, {})
  )
export const visibleComments: (state: GlobalState) => Array<CommentRubyType> = createSelector(
  allVisibleDatesStr,
  commentsInCurrentLocationsByDate,
  (dates: Array<string>, comments_by_date: { [date: string]: Array<CommentRubyType> }) =>
    dates.reduce((acc, date) => {
      const comments_ids = (comments_by_date[date] || []).map((comment) => comment.id)
      return [...acc.filter((comment) => !comments_ids.includes(comment.id)), ...(comments_by_date[date] || [])]
    }, [])
)

export const allDatesThatNeedDemandData: (state: GlobalState) => Array<string> = createSelector(
  allVisibleDatesStr,
  currentLocationsVisibleDatesDemandConfig,
  (dates: Array<string>, dcs: Array<CognitiveDemandConfigType>) =>
    _.uniq([
      ...dates,
      ..._.uniq(_.flatten(dcs.map((dc) => [...(dc.multiple_dates || []), ...(dc.available_dates || [])]))),
    ])
)

const dataByLocationForDates = (
  dates: Array<string>,
  demand_data: { [date: string]: { [data_stream_id: string]: Array<DateData> } },
  dss_by_location: { [location_id: string]: Array<DataStreamRubyType> },
  data_streams_by_id: { [ds_id: string]: DataStreamRubyType },
  demand_config_by_date_by_location: { [location_id: string]: { [date: string]: CognitiveDemandConfigType } }
) =>
  _.mapValues(demand_config_by_date_by_location, (configs, loc_id) =>
    _.uniqBy(
      _.flatten(
        dates.map<Array<DateData>, _>((date) => {
          const dss = dss_by_location[loc_id] || []
          return _.flatten(dss.map<Array<DateData>, _>((ds) => demand_data[date]?.[String(ds.id)] || []))
        })
      ),
      DemandData.getUniqKey
    )
  )

export const predictionsForVisibleDatesByLocation: (state: GlobalState) => { [location_id: string]: Array<DateData> } =
  createSelector(
    allVisibleDatesStr,
    predictedDemandDataByDataStreamByDate,
    dataStreamsByLocations,
    dataStreamByID,
    currentLocationsDemandConfigByDateByLocation,
    dataByLocationForDates
  )

export const actualDataForVisibleDatesByLocation: (state: GlobalState) => { [location_id: string]: Array<DateData> } =
  createSelector(
    allVisibleDatesStr,
    actualDemandDataByDataStreamByDate,
    dataStreamsByLocations,
    dataStreamByID,
    currentLocationsDemandConfigByDateByLocation,
    dataByLocationForDates
  )

const createPredictionsFromConfig = (
  dates: Array<string>,
  actual_demand_data: { [date: string]: { [data_stream_id: string]: Array<DateData> } },
  predicted_demand_data: { [date: string]: { [data_stream_id: string]: Array<DateData> } },
  forecasting_strategy: string,
  data_streams_by_location: { [location_id: string]: Array<DataStreamRubyType> },
  data_streams_by_id: { [ds_id: string]: DataStreamRubyType },
  demand_config_by_date_by_location: { [location_id: string]: { [date: string]: CognitiveDemandConfigType } },
  prediction_modifiers_by_data_stream_id_by_date: {
    [date: string]: { [ds_id: string]: Array<PredictionModifierType> },
  },
  ds_ids_not_visible: Array<number>,
  data_hours_span_by_weekday_by_location: { [location_id: string]: { [weekday: string]: SpanOfHoursType } }
) =>
  _.mapValues(demand_config_by_date_by_location, (configs, loc_id) =>
    _.uniqBy(
      _.flatten(
        dates.map<Array<DateData>, _>((date) => {
          const demand_data =
            forecasting_strategy === Constants.AVERAGE_OF_DATES ? actual_demand_data : predicted_demand_data
          const demand_config: ?CognitiveDemandConfigType = configs[date]
          const data_streams = (data_streams_by_location[String(demand_config?.location_id)] || []).filter(
            (ds) => !ds_ids_not_visible.includes(ds.id)
          )

          const demand_config_location_id = String(demand_config?.location_id)
          const weekday = String(HelperFunc.dateToWeekday(date))
          const business_hours_for_date = data_hours_span_by_weekday_by_location[demand_config_location_id]?.[weekday]
          const business_hours_span = business_hours_for_date || Constants.DEFAULT_SPAN_OF_BUSINESS_HOURS

          return HelperFunc.createModifiedPredictions(
            data_streams,
            demand_data,
            demand_config,
            date,
            prediction_modifiers_by_data_stream_id_by_date,
            forecasting_strategy,
            business_hours_span
          )
        })
      ),
      DemandData.getUniqKey
    )
  )

export const currentLocationsBusinessHoursByWeekdayByLocation: (state: GlobalState) => {
  [location_id: string]: { [weekday: string]: Array<BusinessHoursType> },
} = createSelector(currentLocationsBusinessHours, (bhs: Array<BusinessHoursType>) =>
  _.mapValues(
    _.groupBy(bhs, (bh) => bh.location_id),
    (bhs) => _.groupBy(bhs, (bh) => bh.weekday)
  )
)

export const businessHoursSpanByWeekdayByLocation: (state: GlobalState) => {
  [location_id: string]: { [weekday: string]: SpanOfHoursType },
} = createSelector(
  currentLocationsBusinessHoursByWeekdayByLocation,
  (bh_by_wd_by_loc: { [location_id: string]: { [weekday: string]: Array<BusinessHoursType> } }) =>
    _.mapValues(bh_by_wd_by_loc, (bh_by_wd: { [weekday: string]: Array<BusinessHoursType> }) =>
      _.mapValues(bh_by_wd, (v: Array<BusinessHoursType>) => ({
        start: v.length === 0 ? 0 : v.reduce((acc, bh) => (bh.start < acc ? bh.start : acc), 24 * 60),
        finish: v.length === 0 ? 24 * 60 : v.reduce((acc, bh) => (bh.finish > acc ? bh.finish : acc), 0),
      }))
    )
)

export const dataHoursSpanByWeekdayByLocation: (state: GlobalState) => {
  [location_id: string]: { [weekday: string]: SpanOfHoursType },
} = createSelector(
  businessHoursSpanByWeekdayByLocation,
  (bh_by_wd_by_loc: { [location_id: string]: { [weekday: string]: SpanOfHoursType } }) =>
    _.mapValues(bh_by_wd_by_loc, (bh_by_wd: { [weekday: string]: SpanOfHoursType }) =>
      _.mapValues(bh_by_wd, (current_day: SpanOfHoursType, wd: string) => {
        const prev_wd = Number(wd) - 1
        const refined_prev_wd = prev_wd === -1 ? 6 : prev_wd
        const previous_weekday = bh_by_wd[String(refined_prev_wd)] || current_day
        const next_wd = Number(wd) + 1
        const refined_next_wd = next_wd === 7 ? 0 : next_wd
        const next_weekday = bh_by_wd[String(refined_next_wd)] || current_day
        const start = current_day.start - (current_day.start - (previous_weekday.finish - 24 * 60)) / 2
        const finish = current_day.finish + (next_weekday.start + 24 * 60 - current_day.finish) / 2
        return {
          start: Math.round(Math.max(start, 0) / 15) * 15, // round to nearing multiple of 15
          finish: Math.round(finish / 15) * 15,
        }
      })
    )
)

export const predictionsForVisibleDatesWithTeamFilterByLocation: (state: GlobalState) => {
  [location_id: string]: Array<DateData>,
} = createSelector(
  allVisibleDatesStr,
  actualDemandDataByDataStreamByDate,
  predictedDemandDataByDataStreamByDate,
  (state) => state.config.organisation.forecasting_strategy,
  dataStreamsByLocations,
  dataStreamByID,
  currentLocationsDemandConfigByDateByLocation,
  predictionModifierByDataStreamIDByDate,
  getDataStreamsNotVisibleByTeamFilter,
  dataHoursSpanByWeekdayByLocation,
  createPredictionsFromConfig
)

const EMPTY_OBJ = {}
export const configPredictionsForVisibleDatesWithTeamFilterByLocationNoModifiers: (state: GlobalState) => {
  [location_id: string]: Array<DateData>,
} = createSelector(
  allVisibleDatesStr,
  actualDemandDataByDataStreamByDate,
  predictedDemandDataByDataStreamByDate,
  (state) => state.config.organisation.forecasting_strategy,
  dataStreamsByLocations,
  dataStreamByID,
  currentLocationsDemandConfigByDateByLocation,
  (state) => EMPTY_OBJ,
  getDataStreamsNotVisibleByTeamFilter,
  dataHoursSpanByWeekdayByLocation,
  createPredictionsFromConfig
)

export const predictionsForVisibleDates: (state: GlobalState) => Array<DateData> = createSelector(
  predictionsForVisibleDatesWithTeamFilterByLocation,
  (preds_by_loc: { [location_id: string]: Array<DateData> }) =>
    _.uniqBy(_.flatten(_.values(preds_by_loc)), DemandData.getUniqKey)
)

export const configPredictionsForVisibleDatesNoModifiers: (state: GlobalState) => Array<DateData> = createSelector(
  configPredictionsForVisibleDatesWithTeamFilterByLocationNoModifiers,
  (preds_by_loc: { [location_id: string]: Array<DateData> }) =>
    _.uniqBy(_.flatten(_.values(preds_by_loc)), DemandData.getUniqKey)
)

export const forecastingStrategyPredictionsByDateByLocation: (state: GlobalState) => {
  [location_id: string]: { [date: string]: Array<DateData> },
} = createSelector(
  predictionsForVisibleDatesWithTeamFilterByLocation,
  (predictions_by_loc: { [location_id: string]: Array<DateData> }) =>
    _.mapValues(predictions_by_loc, (predictions) => _.groupBy(predictions, (p) => p.date))
)

export const configPredictionsByDateByLocation: (state: GlobalState) => {
  [location_id: string]: { [date: string]: Array<DateData> },
} = createSelector(
  predictionsForVisibleDatesWithTeamFilterByLocation,
  (predictions_by_loc: { [location_id: string]: Array<DateData> }) =>
    _.mapValues(predictions_by_loc, (predictions) => _.groupBy(predictions, (p) => p.date))
)

export const actualDataByDateByLocation: (state: GlobalState) => {
  [location_id: string]: { [date: string]: Array<DateData> },
} = createSelector(actualDataForVisibleDatesByLocation, (actuals_by_loc: { [location_id: string]: Array<DateData> }) =>
  _.mapValues(actuals_by_loc, (actuals) => _.groupBy(actuals, (d) => d.date))
)

export const predictionsByDate: (state: GlobalState) => { [date: string]: Array<DateData> } = createSelector(
  predictionsForVisibleDates,
  (predictions: Array<DateData>) => _.groupBy(predictions, (p) => p.date)
)

export const predictionsByStatTypeByDataStreamByDate: (state: GlobalState) => {
  [date: string]: { [data_stream_id: string]: { [stat_type: string]: DateData } },
} = createSelector(predictionsByDate, (predictions_by_date: { [date: string]: Array<DateData> }) => {
  const by_ds_by_date = _.mapValues(predictions_by_date, (preds) => _.groupBy(preds, (pred) => pred.data_stream_id))
  const by_stat_type_by_ds_by_date = _.mapValues(by_ds_by_date, (by_ds) =>
    _.mapValues(by_ds, (preds) => _.groupBy(preds, (p) => p.stat_type))
  )
  return _.mapValues(by_stat_type_by_ds_by_date, (by_stat_type_by_ds) =>
    _.mapValues(by_stat_type_by_ds, (by_stat_type) => _.mapValues(by_stat_type, (preds) => preds[0]))
  )
})

const predictionToLegacyType = (
  demand_data: Array<DateData>,
  data_streams: Array<DataStreamRubyType>,
  data_stream_to_stat_type: { [data_stream_id: string]: string },
  dates: Array<string>,
  stat_type_to_stat_type: { [stat_type: string]: StatType }
) =>
  dates.reduce<{ [date: string]: AllDataStreamData }>((acc, date) => {
    acc[date] = data_streams.reduce<AllDataStreamData>((acc: AllDataStreamData, data_stream: DataStreamRubyType) => {
      const stat_type = data_stream_to_stat_type[String(data_stream.id)]
      acc[String(data_stream.id)] = {
        data_interval: C.FIFTEEN_MINUTES,
        data_stream_id: String(data_stream.id),
        data_stream_name: data_stream.name,
        data_type: stat_type_to_stat_type?.[stat_type].data_type || "sum",
        source: data_stream.source,
        stat_by_15:
          (
            demand_data.filter(
              (data) =>
                data.data_stream_id === data_stream.id &&
                data.date === date &&
                data.stat_type === data_stream_to_stat_type[String(data_stream.id)]
            )[0] || {}
          ).stat_by_15 || {},
        original_stat_by_15:
          (
            demand_data.filter(
              (data) =>
                data.data_stream_id === data_stream.id &&
                data.date === date &&
                data.stat_type === data_stream_to_stat_type[String(data_stream.id)]
            )[0] || {}
          ).original_stat_by_15 || {},
        stat_type: stat_type,
      }
      return acc
    }, {})
    return acc
  }, {})

export const configPredictionsForVisibleDatesByLegacyTypeNoModifiers: (state: GlobalState) => {
  [date: string]: AllDataStreamData,
} = createSelector(
  configPredictionsForVisibleDatesNoModifiers,
  getDataStreamsForCurrentLocations,
  dataStreamToStatType,
  allVisibleDatesStr,
  statTypeByStatType,
  predictionToLegacyType
)

export const predictionsForVisibleDatesByLegacyType: (state: GlobalState) => { [date: string]: AllDataStreamData } =
  createSelector(
    predictionsForVisibleDates,
    getDataStreamsForCurrentLocations,
    dataStreamToStatType,
    allVisibleDatesStr,
    statTypeByStatType,
    predictionToLegacyType
  )

export const demandDataByDataStreamDateStatType: (state: GlobalState) => {
  [data_stream_date_stat_type: string]: ?Array<DateData>,
} = createSelector(
  (state) => state.demand_data.actual,
  (demand_data: Array<DateData>) =>
    _.mapValues(
      _.groupBy(demand_data, (dd) => dd.data_stream_id + "~" + dd.date + "~" + dd.stat_type),
      (dds) => dds
    )
)

export const dataForDatesByLegacyType: (state: GlobalState) => { [date: string]: AllDataStreamData } = createSelector(
  demandDataByDataStreamDateStatType,
  getDataStreamsForCurrentLocations,
  dataStreamToStatType,
  allDatesThatNeedDemandData,
  statTypeByStatType,
  (
    indexed_demand_data: { [data_stream_date_stat_type: string]: ?Array<DateData> },
    dss: Array<DataStreamRubyType>,
    ds_to_stat_type: { [ds_id: string]: string },
    dates: Array<string>,
    stat_type_to_stat_type: { [stat_type: string]: StatType }
  ) =>
    dates.reduce<{ [date: string]: AllDataStreamData }>((acc, date) => {
      acc[date] = dss.reduce<AllDataStreamData>((acc: AllDataStreamData, ds: DataStreamRubyType) => {
        const stat_type = ds_to_stat_type[String(ds.id)]
        acc[String(ds.id)] = {
          data_interval: C.FIFTEEN_MINUTES,
          data_stream_id: String(ds.id),
          data_stream_name: ds.name,
          data_type: stat_type_to_stat_type?.[stat_type].data_type || "sum",
          original_stat_by_15:
            indexed_demand_data[ds.id + "~" + date + "~" + ds_to_stat_type[String(ds.id)]]?.[0]?.original_stat_by_15 ||
            {},
          source: ds.source,
          stat_by_15:
            indexed_demand_data[ds.id + "~" + date + "~" + ds_to_stat_type[String(ds.id)]]?.[0]?.stat_by_15 || {},
          stat_type: stat_type,
        }
        return acc
      }, {})
      return acc
    }, {})
)

export const manuallySetSalesByDateByLocation: (state: GlobalState) => {
  [location_id: string]: { [date: string]: number },
} = createSelector(
  (state) => state.sales_targets,
  (sales_targets: Array<SalesTargetType>) =>
    _.mapValues(
      _.groupBy(sales_targets, (st) => st.location_id),
      (sts) =>
        _.mapValues(
          _.groupBy(sts, (st) => st.date),
          (sts_for_date) => sts_for_date[0]?.target
        )
    )
)

const sumSalesPredictionByDay = (
  prediction_per_day_per_location: { [location_id: string]: { [date: string]: Array<DateData> } },
  data_hours_by_loc: { [location_id: string]: { [weekday: string]: SpanOfHoursType } }
) =>
  _.mapValues(prediction_per_day_per_location, (prediction_per_day, loc_id) => {
    const bh = data_hours_by_loc[loc_id]
    return _.mapValues(prediction_per_day, (preds) =>
      preds
        .filter((p) => p.stat_type === "sales")
        .map((p) => DemandData.sumAll(p.stat_by_15, bh[String(HelperFunc.dateToWeekday(p.date))]))
        .reduce((acc, val) => acc + val, 0)
    )
  })

export const getTotalPredictedSalesPerDayForVisibleDaysByLocation: (state: GlobalState) => {
  [location_id: string]: { [date: string]: number },
} = createSelector(
  forecastingStrategyPredictionsByDateByLocation,
  dataHoursSpanByWeekdayByLocation,
  sumSalesPredictionByDay
)

export const getTotalConfigPredictedSalesPerDayForVisibleDaysByLocation: (state: GlobalState) => {
  [location_id: string]: { [date: string]: number },
} = createSelector(configPredictionsByDateByLocation, dataHoursSpanByWeekdayByLocation, sumSalesPredictionByDay)

export const getTotalLabourBudgetByDayByLocationWithTeamFilter: (state: GlobalState) => {
  [location_id: string]: { [date: string]: number },
} = createSelector(
  actualDataByDateByLocation,
  getDataStreamsNotVisibleByTeamFilter,
  (
    actual_per_day_per_location: { [location_id: string]: { [date: string]: Array<DateData> } },
    ds_ids_not_visible: Array<number>
  ) =>
    _.mapValues(actual_per_day_per_location, (data_per_day, loc_id) =>
      _.mapValues(data_per_day, (datas) =>
        datas
          .filter((d) => d.stat_type === "labour budget" && !ds_ids_not_visible.includes(Number(d.data_stream_id)))
          .map((d) => DemandData.sumAll(d.stat_by_15, { start: 0, finish: 24 * 60 }))
          .reduce((acc, val) => acc + val, 0)
      )
    )
)

export const getPredictedSalesOrManuallySetPerDayForVisibleDaysByLocation: (state: GlobalState) => {
  [location_id: string]: { [date: string]: { manually_set: boolean, sales: number } },
} = createSelector(
  getCurrentLocations,
  allVisibleDatesStr,
  getTotalPredictedSalesPerDayForVisibleDaysByLocation,
  manuallySetSalesByDateByLocation,
  (
    locations: Array<LocationRubyType>,
    dates: Array<string>,
    pred_per_day_per_loc: { [location_id: string]: { [date: string]: number } },
    manually_set_per_day_per_loc: { [location_id: string]: { [date: string]: number } }
  ) =>
    locations.reduce((acc, loc) => {
      acc[String(loc.id)] = dates.reduce((dates_acc, date) => {
        const manually_set = manually_set_per_day_per_loc[String(loc.id)]?.[date]
        dates_acc[date] = {
          manually_set: !!manually_set,
          sales: manually_set || pred_per_day_per_loc[String(loc.id)]?.[date],
        }
        return dates_acc
      }, {})
      return acc
    }, {})
)

export const getManuallySetSalesPerDayForVisibleDays: (state: GlobalState) => { [date: string]: number } =
  createSelector(
    manuallySetSalesByDateByLocation,
    (state) => state.settings.selected_location_ids,
    (sales_by_day_by_location: { [location_id: string]: { [date: string]: number } }, loc_ids: Array<number>) =>
      _.toPairs(sales_by_day_by_location).reduce(
        (acc, [loc_id, sales_by_day]) =>
          loc_ids.includes(Number(loc_id)) ? HelperFunc.mergeAndAddStats(acc, sales_by_day) : acc,
        {}
      )
  )

export const getManuallySetSalesForAllVisibleDates: (state: GlobalState) => number = createSelector(
  getManuallySetSalesPerDayForVisibleDays,
  allVisibleDatesStr,
  (total_sales_per_day: { [date: string]: number }, visible_dates: Array<string>) =>
    visible_dates.reduce((acc, date) => acc + (total_sales_per_day[date] || 0), 0)
)

export const getPredictedSalesOrManuallySetPerDayForVisibleDays: (state: GlobalState) => {
  [date: string]: { manually_set: boolean, sales: number },
} = createSelector(
  getPredictedSalesOrManuallySetPerDayForVisibleDaysByLocation,
  allVisibleDatesStr,
  (
    sales_by_day_by_location: { [location_id: string]: { [date: string]: { manually_set: boolean, sales: number } } },
    all_dates: Array<string>
  ) => {
    const pairs: Array<[string, { [date: string]: { manually_set: boolean, sales: number } }]> =
      _.toPairs(sales_by_day_by_location)
    return pairs.reduce(
      (acc, [loc_id, sales_by_day]: [string, { [date: string]: { manually_set: boolean, sales: number } }]) => {
        all_dates.map((d) => {
          acc[d] = {
            sales: (acc[d]?.sales || 0) + (sales_by_day[d]?.sales || 0),
            manually_set: acc[d]?.manually_set || sales_by_day[d]?.manually_set,
          }
          return acc
        })
        return acc
      },
      {}
    )
  }
)

export const getJustPredictedSalesOrManuallySetPerDayForVisibleDays: (state: GlobalState) => {
  [date: string]: number,
} = createSelector(
  getPredictedSalesOrManuallySetPerDayForVisibleDays,
  (sales_by_day: { [date: string]: { manually_set: boolean, sales: number } }) =>
    _.mapValues(sales_by_day, (sales) => sales.sales)
)

export const getTotalPredictedSalesPerDayForVisibleDays: (state: GlobalState) => { [date: string]: number } =
  createSelector(
    getTotalPredictedSalesPerDayForVisibleDaysByLocation,
    (sales_by_day_by_location: { [location_id: string]: { [date: string]: number } }) =>
      _.toPairs(sales_by_day_by_location).reduce(
        (acc, [loc_id, sales_by_day]) => HelperFunc.mergeAndAddStats(acc, sales_by_day),
        {}
      )
  )

export const getTotalLabourBudgetPerDayForVisibleDaysAndVisibleLocations: (state: GlobalState) => {
  [date: string]: number,
} = createSelector(
  getTotalLabourBudgetByDayByLocationWithTeamFilter,
  (state) => state.settings.selected_location_ids,
  (budget_by_day_by_location: { [location_id: string]: { [date: string]: number } }, loc_ids: Array<number>) =>
    _.toPairs(budget_by_day_by_location).reduce(
      (acc, [loc_id, budget_by_day]) =>
        loc_ids.includes(Number(loc_id)) ? HelperFunc.mergeAndAddStats(acc, budget_by_day) : acc,
      {}
    )
)

export const getPredictedSalesOrManuallySetSalesForAllVisibleDates: (state: GlobalState) => {
  manually_set: boolean,
  sales: number,
} = createSelector(
  getPredictedSalesOrManuallySetPerDayForVisibleDays,
  allVisibleDatesStr,
  (total_sales_per_day: { [date: string]: { manually_set: boolean, sales: number } }, visible_dates: Array<string>) =>
    visible_dates.reduce(
      (acc, d) => {
        acc = {
          sales: (acc.sales || 0) + (total_sales_per_day[d]?.sales || 0),
          manually_set: acc.manually_set || total_sales_per_day[d]?.manually_set,
        }
        return acc
      },
      {
        sales: 0,
        manually_set: false,
      }
    )
)

export const getTotalPredictedSalesForAllVisibleDates: (state: GlobalState) => number = createSelector(
  getTotalPredictedSalesPerDayForVisibleDays,
  allVisibleDatesStr,
  (total_sales_per_day: { [date: string]: number }, visible_dates: Array<string>) =>
    visible_dates.reduce((acc, date) => acc + (total_sales_per_day[date] || 0), 0)
)

export const getTotalLabourBudgetPerDayForVisibleDates: (state: GlobalState) => number = createSelector(
  getTotalLabourBudgetPerDayForVisibleDaysAndVisibleLocations,
  allVisibleDatesStr,
  (total_budget_per_day: { [date: string]: number }, visible_dates: Array<string>) =>
    visible_dates.reduce((acc, date) => acc + (total_budget_per_day[date] || 0), 0)
)

export const showActualSalesInsteadOfPredicted: (state: GlobalState) => boolean = createSelector(
  getDataStreamJoinsForCurrentLocations,
  (dsjs: Array<DataStreamJoinRubyType>) => dsjs.length === 0
)

export const statsForVisibleSchedules: (state: GlobalState) => ScheduleStats = createSelector(
  visibleSchedulesForCurrentFilters,
  scheduleToAICS,
  scheduleToAICSWithOnCostMultiplierAdded,
  scheduleToOvertimeAICS,
  visibleRDOs,
  timeNotWorkedScheduleToAICS,
  getPredictedSalesOrManuallySetSalesForAllVisibleDates,
  visibleUsers,
  allVisibleDatesStr,
  getTotalLabourBudgetPerDayForVisibleDaysAndVisibleLocations,
  (
    schedules: Array<ScheduleType>,
    schedule_to_aics: { [sched_id: string]: Array<AICRubyType> },
    schedule_to_aics_with_multiplier: { [sched_id: string]: Array<AICRubyType> },
    schedule_to_overtime_aics: { [sched_id: string]: Array<AICRubyType> },
    time_not_worked_schedules: Array<RDORubyType>,
    time_not_worked_schedule_to_aics: { [time_not_worked_sched_id: string]: Array<AICRubyType> },
    sales: { manually_set: boolean, sales: number },
    users: Array<UserType>,
    visible_dates,
    maybe_labour_budget: { [date: string]: number }
  ) =>
    HelperFunc.getStatsFromSchedules(
      schedules,
      schedule_to_aics,
      time_not_worked_schedules,
      time_not_worked_schedule_to_aics,
      sales.sales,
      users,
      visible_dates[0],
      visible_dates[visible_dates.length - 1],
      1,
      schedule_to_aics_with_multiplier,
      schedule_to_overtime_aics,
      Object.values(maybe_labour_budget).reduce((sum, budget) => sum + Number(budget), 0) || 0
    )
)

export const visibleCognitiveSettings: (state: GlobalState) => { [dep_id: string]: CognitiveSettingsType } =
  createSelector(cognitiveSettingsByTeam, (cog_settings: { [dep_id: string]: CognitiveCreatorConfigurationType }) => {
    const mapFunc: (c: CognitiveCreatorConfigurationType) => CognitiveSettingsType = (c) => ({
      max_concurrent_start: c.max_concurrent_start,
      max_length: c.max_length,
      maximum_staff: c.maximum_staff,
      min_length: c.min_length,
      minimum_staff: c.minimum_staff,
      round_down_head_count: c.round_down_head_count,
      consolidate_shift_count: c.consolidate_shift_count,
      time_to_close: c.time_to_close,
      time_to_open: c.time_to_open,
      undercoverage_penalty_ratio: c.undercoverage_penalty_ratio,
    })
    return _.mapValues(cog_settings, mapFunc)
  })

export const crossDepartmentProficiencysByDesitnationDepID: (state: GlobalState) => {
  [team_id: string]: Array<CrossDepartmentProficiency>,
} = createSelector(
  (state) => state.cognitive.cross_department_proficiencys,
  (cdps: Array<CrossDepartmentProficiency>) => _.groupBy(cdps, (cdp) => cdp.destination_department_id)
)

export const visibleDepartmentData: (state: GlobalState) => Array<DepartmentType> = createSelector(
  getVisibleTeams,
  cognitiveSettingsByTeam,
  crossDepartmentProficiencysByDesitnationDepID,
  (
    teams: Array<TeamRubyType>,
    cog: { [dep_id: string]: CognitiveCreatorConfigurationType },
    cdp_by_team_id: { [team_id: string]: Array<CrossDepartmentProficiency> }
  ) =>
    teams.map((t) => ({
      assisting_team_ids: [
        ...((cog[String(t.id)] || {}).alias_department_ids || []).map(String),
        ...(cdp_by_team_id[String(t.id)] || []).map((cdp) => cdp.source_department_id).map(String),
      ],
      color: t.colour || Constants.DEFAULT_TEAM_COLOR,
      id: String(t.id),
      name: t.name,
      sort_order: t.sort_order,
      name_and_location_short_name: HelperFunc.getTeamShortName(t.name),
      short_name: HelperFunc.getTeamShortName(t.name),
    }))
)

export const visibleBusinessHoursData: (state: GlobalState) => BusinessHoursConfig = createSelector(
  currentLocationsBusinessHours,
  (bhs: Array<BusinessHoursType>) => {
    const day_view_bhs: Array<DayViewBusinessHoursType> = bhs.map((bh) => ({
      finish: bh.finish,
      id: String(bh.id),
      location_id: bh.location_id,
      start: bh.start,
      weekday: bh.weekday,
    }))
    return _.groupBy(day_view_bhs, (bh) => bh.weekday)
  }
)

export const visibleSchedulesByDate: (state: GlobalState) => { [date: string]: Array<ScheduleType> } = createSelector(
  visibleSchedules,
  (scheds: Array<ScheduleType>) => _.groupBy(scheds, (s) => s.date)
)
export const visibleSchedulesByTeamByDate: (state: GlobalState) => {
  [date: string]: { [team_id: string]: Array<ScheduleType> },
} = createSelector(visibleSchedulesByDate, (scheds_by_date: { [date: string]: Array<ScheduleType> }) =>
  _.mapValues(scheds_by_date, (scheds: Array<ScheduleType>) => _.groupBy(scheds, (s) => s.department_id))
)
export const visibleRosteredHoursByDepByDate: (state: GlobalState) => {
  [date: string]: { [team_id: string]: DepartmentWithStatsType },
} = createSelector(
  allVisibleDatesStr,
  getVisibleTeams,
  visibleSchedulesByTeamByDate,
  cognitiveSettingsByTeam,
  crossDepartmentProficiencysByDesitnationDepID,
  (
    dates: Array<string>,
    teams: Array<TeamRubyType>,
    scheds_by_team_by_date: { [date: string]: { [team_id: string]: Array<ScheduleType> } },
    cog_by_team: { [dep_id: string]: CognitiveCreatorConfigurationType },
    cdp_by_team_id: { [team_id: string]: Array<CrossDepartmentProficiency> }
  ) =>
    dates.reduce((by_date, date) => {
      by_date[date] = teams.reduce((by_team, team) => {
        by_team[String(team.id)] = {
          assisting_team_ids: [
            ...((cog_by_team[String(team.id)] || {}).alias_department_ids || []).map(String),
            ...(cdp_by_team_id[String(team.id)] || []).map((cdp) => cdp.source_department_id).map(String),
          ],
          color: team.colour || Constants.DEFAULT_TEAM_COLOR,
          id: String(team.id),
          name: team.name,
          name_and_location_short_name: HelperFunc.getTeamShortName(team.name),
          short_name: HelperFunc.getTeamShortName(team.name),
          sort_order: team.sort_order,
          stat_by_15: Schedule.by15Minutes((scheds_by_team_by_date[date] || {})[String(team.id)] || [], date),
          stat_by_15_with_assisted: {},
        }
        return by_team
      }, {})
      return by_date
    }, {})
)

export const visibleRosteredHoursWithAssistingByDepByDate: (state: GlobalState) => {
  [date: string]: { [team_id: string]: DepartmentWithStatsType },
} = createSelector(
  visibleRosteredHoursByDepByDate,
  (by_team_by_date: { [date: string]: { [team_id: string]: DepartmentWithStatsType } }) =>
    _.mapValues(by_team_by_date, (by_team: { [team_id: string]: DepartmentWithStatsType }, date: string) =>
      _.mapValues(by_team, (dep: DepartmentWithStatsType, team_id: string) => ({
        ...dep,
        stat_by_15_with_assisted: dep.assisting_team_ids.reduce(
          (acc, t_id) => merge_and_add_stats(acc, (by_team[t_id] || {}).stat_by_15 || {}),
          dep.stat_by_15
        ),
      }))
    )
)

export const visibleShiftsByDate: (state: GlobalState) => { [date: string]: Array<ShiftType> } = createSelector(
  visibleShifts,
  (scheds: Array<ShiftType>) => _.groupBy(scheds, (s) => s.date)
)
export const visibleShiftsByTeamByDate: (state: GlobalState) => {
  [date: string]: { [team_id: string]: Array<ShiftType> },
} = createSelector(visibleShiftsByDate, (shifts_by_date: { [date: string]: Array<ShiftType> }) =>
  _.mapValues(shifts_by_date, (shifts: Array<ShiftType>) => _.groupBy(shifts, (s) => s.department_id))
)

export const payCheckByUserId: (state: GlobalState) => { [userId: string]: Array<PayCheckType> } = createSelector(
  (state: GlobalState) => state.pay_checks.payChecks,
  (payChecks: Array<PayCheckType>) => _.groupBy(payChecks, (pc) => String(pc.user_id))
)
export const visibleTimesheetHoursByDepByDate: (state: GlobalState) => {
  [date: string]: { [team_id: string]: DepartmentWithStatsType },
} = createSelector(
  allVisibleDatesStr,
  getVisibleTeams,
  visibleShiftsByTeamByDate,
  cognitiveSettingsByTeam,
  crossDepartmentProficiencysByDesitnationDepID,
  (
    dates: Array<string>,
    teams: Array<TeamRubyType>,
    scheds_by_team_by_date: { [date: string]: { [team_id: string]: Array<ShiftType> } },
    cog_by_team: { [dep_id: string]: CognitiveCreatorConfigurationType },
    cdp_by_team_id: { [team_id: string]: Array<CrossDepartmentProficiency> }
  ) =>
    dates.reduce((by_date, date) => {
      by_date[date] = teams.reduce((by_team, team) => {
        by_team[String(team.id)] = {
          assisting_team_ids: [
            ...((cog_by_team[String(team.id)] || {}).alias_department_ids || []).map(String),
            ...(cdp_by_team_id[String(team.id)] || []).map((cdp) => cdp.source_department_id).map(String),
          ],
          color: team.colour || Constants.DEFAULT_TEAM_COLOR,
          id: String(team.id),
          name: team.name,
          name_and_location_short_name: HelperFunc.getTeamShortName(team.name),
          short_name: HelperFunc.getTeamShortName(team.name),
          stat_by_15: Shift.by15Minutes((scheds_by_team_by_date[date] || {})[String(team.id)] || [], date),
          stat_by_15_with_assisted: {},
        }
        return by_team
      }, {})
      return by_date
    }, {})
)

export const visibleTimesheetHoursWithAssistingByDepByDate: (state: GlobalState) => {
  [date: string]: { [team_id: string]: DepartmentWithStatsType },
} = createSelector(
  visibleTimesheetHoursByDepByDate,
  (by_team_by_date: { [date: string]: { [team_id: string]: DepartmentWithStatsType } }) =>
    _.mapValues(by_team_by_date, (by_team: { [team_id: string]: DepartmentWithStatsType }, date: string) =>
      _.mapValues(by_team, (dep: DepartmentWithStatsType, team_id: string) => ({
        ...dep,
        stat_by_15_with_assisted: dep.assisting_team_ids.reduce(
          (acc, t_id) => merge_and_add_stats(acc, (by_team[t_id] || {}).stat_by_15 || {}),
          dep.stat_by_15
        ),
      }))
    )
)

export const visibleTotalTimesheetHoursByDepByDate: (state: GlobalState) => {
  [date: string]: { [dep_id: string]: { total_hours: number, with_assisting: number } },
} = createSelector(
  visibleTimesheetHoursWithAssistingByDepByDate,
  (data: { [date: string]: { [team_id: string]: DepartmentWithStatsType } }) =>
    _.mapValues(data, (by_team: { [team_id: string]: DepartmentWithStatsType }) =>
      _.mapValues(by_team, (dep_data) => ({
        total_hours: _.toPairs(dep_data.stat_by_15).reduce((a: number, [k, v]) => a + v / 4, 0),
        with_assisting: _.toPairs(dep_data.stat_by_15_with_assisted).reduce((a: number, [k, v]) => a + v / 4, 0),
      }))
    )
)

export const visibleTimesheetHoursWithAssistingDepsByDate: (state: GlobalState) => {
  [date: string]: Array<DepartmentWithStatsType>,
} = createSelector(
  visibleTimesheetHoursWithAssistingByDepByDate,
  (by_team_by_date: { [date: string]: { [team_id: string]: DepartmentWithStatsType } }) =>
    _.mapValues(by_team_by_date, (by_team: { [team_id: string]: DepartmentWithStatsType }) => Object.values(by_team))
)

export const visibleTimesheetHoursTotalByDate: (state: GlobalState) => { [date: string]: DepartmentWithStatsType } =
  createSelector(
    visibleTimesheetHoursWithAssistingDepsByDate,
    (by_date: { [date: string]: Array<DepartmentWithStatsType> }) =>
      _.mapValues(by_date, (deps: Array<DepartmentWithStatsType>) => {
        const stat_by_15 = deps.reduce((acc, d) => merge_and_add_stats(acc, d.stat_by_15), {})
        return {
          ...DayViewConstants.DEFAULT_PROCESSED_TOTAL_TEAM_DATA,
          stat_by_15,
          stat_by_15_with_assisted: stat_by_15,
        }
      })
  )

export const visibleTotalRosteredHoursByDepByDate: (state: GlobalState) => {
  [date: string]: { [dep_id: string]: { total_hours: number, with_assisting: number } },
} = createSelector(
  visibleRosteredHoursWithAssistingByDepByDate,
  (data: { [date: string]: { [team_id: string]: DepartmentWithStatsType } }) =>
    _.mapValues(data, (by_team: { [team_id: string]: DepartmentWithStatsType }) =>
      _.mapValues(by_team, (dep_data) => ({
        total_hours: _.toPairs(dep_data.stat_by_15).reduce((a: number, [k, v]) => a + v / 4, 0),
        with_assisting: _.toPairs(dep_data.stat_by_15_with_assisted).reduce((a: number, [k, v]) => a + v / 4, 0),
      }))
    )
)

export const visibleRosteredHoursWithAssistingDepsByDate: (state: GlobalState) => {
  [date: string]: Array<DepartmentWithStatsType>,
} = createSelector(
  visibleRosteredHoursWithAssistingByDepByDate,
  (by_team_by_date: { [date: string]: { [team_id: string]: DepartmentWithStatsType } }) =>
    _.mapValues(by_team_by_date, (by_team: { [team_id: string]: DepartmentWithStatsType }) => Object.values(by_team))
)

export const visibleRosteredHoursTotalByDate: (state: GlobalState) => { [date: string]: DepartmentWithStatsType } =
  createSelector(
    visibleRosteredHoursWithAssistingDepsByDate,
    (by_date: { [date: string]: Array<DepartmentWithStatsType> }) =>
      _.mapValues(by_date, (deps: Array<DepartmentWithStatsType>) => {
        const stat_by_15 = deps.reduce((acc, d) => merge_and_add_stats(acc, d.stat_by_15), {})
        return {
          ...DayViewConstants.DEFAULT_PROCESSED_TOTAL_TEAM_DATA,
          stat_by_15,
          stat_by_15_with_assisted: stat_by_15,
        }
      })
  )

export const visibleProjectedDepartmentDataByDate: (state: GlobalState) => {
  [date: string]: Array<DepartmentWithStatsOnDateType>,
} = createSelector(
  allVisibleDatesStr,
  predictionsByStatTypeByDataStreamByDate,
  cognitiveSettingsByTeam,
  currentLocationsBusinessHoursByWeekdayByLocation,
  dataStreamJoinsByTeam,
  headCountMapByDataStreamJoinByDoWBy15Inc,
  visibleDepartmentData,
  teamToLocation,
  (
    dates: Array<string>,
    predictions: { [date: string]: { [data_stream_id: string]: { [stat_type: string]: DateData } } },
    cog_settings_by_team: { [dep_id: string]: CognitiveCreatorConfigurationType },
    business_hours: { [location: string]: { [weekday: string]: Array<BusinessHoursType> } },
    data_stream_joins_by_team: { [team_id: string]: Array<DataStreamJoinRubyType> },
    head_count_map_by_dsj_by_dow_by_15_inc: {
      [dsj_id: string]: { [dow: number]: { [by_15_inc: number]: HeadCountMapRubyType } },
    },
    teams: Array<DepartmentType>,
    team_to_loc: { [team_id: string]: LocationRubyType }
  ) =>
    dates.reduce<{ [date: string]: Array<DepartmentWithStatsOnDateType> }>((acc, date) => {
      const prediction = predictions[date] || {}
      acc[date] = teams
        .filter((t) => (data_stream_joins_by_team[String(t.id)] || []).length > 0)
        .map((t) => {
          const location = team_to_loc[t.id]
          const bh = business_hours[String(location.id)]?.[String(HelperFunc.dateToWeekday(date))]
          const by_15 = Cognitive.projectionStaffCount(
            prediction,
            cog_settings_by_team[t.id] || Constants.DEFAULT_COGNITIVE_CREATOR_CONFIGURATION,
            bh,
            data_stream_joins_by_team[String(t.id)] || [],
            head_count_map_by_dsj_by_dow_by_15_inc,
            dates
          )
          return {
            assisting_team_ids: t.assisting_team_ids,
            color: t.color,
            date,
            id: t.id,
            name: t.name,
            name_and_location_short_name: t.name_and_location_short_name,
            short_name: t.short_name,
            stat_by_15: by_15,
            sort_order: t.sort_order,
            stat_by_15_with_assisted: by_15,
          }
        })
      return acc
    }, {})
)

export const visibleIdealDepartmentDataByDate: (state: GlobalState) => {
  [date: string]: Array<DepartmentWithStatsType>,
} = createSelector(
  allVisibleDatesStr,
  demandDataForCurrentLocationsByStatTypeByDataStreamIdByDate,
  cognitiveSettingsByTeam,
  currentLocationsBusinessHoursByWeekdayByLocation,
  dataStreamJoinsByTeam,
  headCountMapByDataStreamJoinByDoWBy15Inc,
  visibleDepartmentData,
  teamToLocation,
  (
    dates: Array<string>,
    acutal_datas: { [date: string]: { [data_stream_id: string]: { [stat_type: string]: DateData } } },
    cog_settings_by_team: { [dep_id: string]: CognitiveCreatorConfigurationType },
    business_hours: { [location: string]: { [weekday: string]: Array<BusinessHoursType> } },
    data_stream_joins_by_team: { [team_id: string]: Array<DataStreamJoinRubyType> },
    head_count_map_by_dsj_by_dow_by_15_inc: {
      [dsj_id: string]: { [dow: number]: { [by_15_inc: number]: HeadCountMapRubyType } },
    },
    teams: Array<DepartmentType>,
    team_to_loc: { [team_id: string]: LocationRubyType }
  ) =>
    dates.reduce<{ [date: string]: Array<DepartmentWithStatsType> }>((acc, date) => {
      const actual_data = acutal_datas[date] || {}
      acc[date] = teams
        .filter((t) => (data_stream_joins_by_team[String(t.id)] || []).length > 0)
        .map((t) => {
          const location = team_to_loc[t.id]
          const bh = business_hours[String(location.id)]?.[String(HelperFunc.dateToWeekday(date))]
          const by_15 = Cognitive.projectionStaffCount(
            actual_data,
            cog_settings_by_team[t.id] || Constants.DEFAULT_COGNITIVE_CREATOR_CONFIGURATION,
            bh,
            data_stream_joins_by_team[String(t.id)] || [],
            head_count_map_by_dsj_by_dow_by_15_inc,
            dates
          )
          return {
            assisting_team_ids: t.assisting_team_ids,
            color: t.color,
            id: t.id,
            name: t.name,
            name_and_location_short_name: t.name_and_location_short_name,
            short_name: t.short_name,
            stat_by_15: by_15,
            sort_order: t.sort_order,
            stat_by_15_with_assisted: by_15,
          }
        })
      return acc
    }, {})
)

export const visibleProjectedTotalDataByDate: (state: GlobalState) => { [date: string]: DepartmentWithStatsType } =
  createSelector(
    visibleProjectedDepartmentDataByDate,
    (data: { [date: string]: Array<DepartmentWithStatsOnDateType> }) =>
      _.mapValues(data, (deps: Array<DepartmentWithStatsType>) =>
        deps.reduce(
          (acc: DepartmentWithStatsType, d: DepartmentWithStatsType) => {
            acc.stat_by_15 = merge_and_add_stats(acc.stat_by_15, d.stat_by_15)
            return acc
          },
          { ...DayViewConstants.DEFAULT_PROCESSED_TOTAL_TEAM_DATA, stat_by_15: {} }
        )
      )
  )

export const visibleTotalRecommendedHoursByDepByDate: (state: GlobalState) => {
  [date: string]: { [dep_id: string]: number },
} = createSelector(
  getVisibleTeams,
  visibleProjectedDepartmentDataByDate,
  (teams: Array<TeamRubyType>, data: { [date: string]: Array<DepartmentWithStatsOnDateType> }) =>
    _.mapValues(data, (deps: Array<DepartmentWithStatsType>, date: string) => {
      const deps_by_team_id: { [dep_id: string]: DepartmentWithStatsType } = _.mapValues(
        _.groupBy(deps, (d) => d.id),
        (dd) => dd[0]
      )
      const total_by_team_id: { [dep_id: string]: number } = _.mapValues(
        deps_by_team_id,
        (d: DepartmentWithStatsType) => _.toPairs(d.stat_by_15).reduce((a: number, [k, v]) => a + v / 4, 0)
      )
      return total_by_team_id
    })
)

export const visibleTotalRecommendedHoursByDate: (state: GlobalState) => { [date: string]: number } = createSelector(
  visibleTotalRecommendedHoursByDepByDate,
  (data: { [date: string]: { [dep_id: string]: number } }) =>
    _.mapValues(data, (by_dep: { [dep_id: string]: number }) => _.values(by_dep).reduce((a, n) => a + n, 0))
)

export const visibleTotalRecommendedHours: (state: GlobalState) => number = createSelector(
  visibleTotalRecommendedHoursByDate,
  (data: { [date: string]: number }) => _.values(data).reduce((a, n) => a + n, 0)
)

export const teamsWithDataStreamJoins: (state: GlobalState) => Array<number> = createSelector(
  (state) => state.data_stream_joins,
  (dsjs: Array<DataStreamJoinRubyType>) =>
    dsjs.filter((dsj) => dsj.data_streamable_type === "Department").map((dsj) => dsj.data_streamable_id)
)

export const visibleHoursRange: (state: GlobalState) => Array<number> = createSelector(
  (state) => state.settings.visible_hours,
  (vh: VisibleHoursType) => _.range(vh.start_half_hour, vh.finish_half_hour, 0.5)
)

export const minimumDateRangeThatNeedsLoading: (state: GlobalState) => [moment, moment] = createSelector(
  allVisibleDates,
  (state) => state.config.roster_week_start_day,
  (dates: Array<moment>, dow: number) => {
    const min_date = _.min(dates).clone()
    const min_date_wd = min_date.isoWeekday() % 7
    const max_date = _.max(dates).clone()
    const max_date_wd = max_date.isoWeekday() % 7
    const start_weekday = dow
    // This weird way of doing modulo allows you to do 'correct' modulos on negative numbers
    const end_weekday = (dow + 6) % 7
    const days_before_to_fetch = (((min_date_wd - start_weekday) % 7) + 7) % 7
    // We add an extra day onto this so we can validate against shift/rdo clashes if we roster past midnight
    // And also so we can show 'ghosted' shifts in staff view day view for the next day.
    const days_after_to_fetch = ((((end_weekday - max_date_wd) % 7) + 7) % 7) + 1
    const new_min_date = min_date.subtract(days_before_to_fetch, "days")
    const new_max_date = max_date.add(days_after_to_fetch, "days")
    return [new_min_date, new_max_date]
  }
)

export const datesInVisibleStartPayPeriod: (state: GlobalState) => Array<string> = createSelector(
  (state) => state.settings.start_date,
  (state) => state.config.recent_pay_period_start,
  (state) => state.config.roster_week_start_day,
  (state) => state.config.pay_period_length,
  (state) => state.config.show_weekends,
  (start: moment, pp_start: moment, dow: number, pay_period_length: number, show_weekends: boolean) => {
    const current_offset = ((start.diff(pp_start, "days") % pay_period_length) + pay_period_length) % pay_period_length
    const new_start = start.clone().subtract(current_offset, "days")
    const new_finish = new_start.clone().add(pay_period_length - 1, "days")
    const dates = HelperFunc.getAllDates(new_start, new_finish)
    const weekend_filter_dates = dates.filter((d) => d.isoWeekday() < 6 || show_weekends)
    // Only return the filtered dates if there are any
    return (weekend_filter_dates.length > 1 ? weekend_filter_dates : dates).map((d) => d.format(C.DATE_FMT))
  }
)

export const datesInVisibleStartPayPeriodForWeek: (state: GlobalState) => Array<string> = createSelector(
  datesInVisibleStartPayPeriod,
  startStr,
  (dates: Array<string>, currentDay: string) => {
    if (dates.length === 7) {
      return dates
    } else if (dates.length === 14) {
      const index = dates.findIndex((d) => d === currentDay) || 0
      return index < 7 ? dates.slice(0, 7) : dates.slice(7)
    } else {
      return dates
    }
  }
)

export const numberOfPayPeriodsVisible: (state: GlobalState) => number = createSelector(
  (state) => state.settings.start_date,
  (state) => state.config.recent_pay_period_start,
  allVisibleDatesStr,
  (state) => state.config.pay_period_length,
  (start: moment, pp_start: moment, dates: Array<string>, pay_period_length: number) => {
    const current_offset = ((start.diff(pp_start, "days") % pay_period_length) + pay_period_length) % pay_period_length
    return Math.ceil((dates.length + current_offset) / pay_period_length)
  }
)

export const isPredictiveWorkforceDisabled: (state: GlobalState) => boolean = createSelector(
  getDataStreamJoinsForCurrentLocations,
  (dsjs: Array<DataStreamJoinRubyType>) => dsjs.length === 0
)

export const positionGroupsWithUsersIncludingPositionsWithoutGroups: (state: GlobalState) => Array<GroupInfoType> =
  createSelector(
    (state) => state.static.positions,
    (state) => state.static.position_groups,
    userIdsByPositionGroupId,
    usersInCurrentFilters,
    (
      positions: Array<PositionType>,
      position_groups: Array<PositionGroupType>,
      user_ids_by_position_group_id: { [position_group_id: string]: Array<string> },
      users: Array<UserType>
    ) => {
      const user_ids = users.map((user) => String(user.id))
      const visible_user_ids_by_position_group_id = _.mapValues(user_ids_by_position_group_id, (position_user_ids) =>
        position_user_ids.filter((user_id) => user_ids.includes(user_id))
      )

      const positions_without_position_group_id: Array<PositionType> = positions.filter(
        (pos) => pos.position_group_id === null
      )
      const position_groups_including_positions_without_position_group_id = position_groups.concat(
        positions_without_position_group_id.map((pos) => ({ id: "no-position-group" + String(pos.id), name: pos.name }))
      )
      const position_groups_with_users = position_groups_including_positions_without_position_group_id.filter(
        (pos_group) => (visible_user_ids_by_position_group_id[String(pos_group.id)] || []).length > 0
      )

      const sorted_positions: Array<GroupInfoType> = _.sortBy(
        position_groups_with_users.map<GroupInfoType, _>((pos_group) => ({
          sort_order: null,
          id: String(pos_group.id),
          name: pos_group.name,
        })),
        (group) => group.name
      )
      return [
        ...sorted_positions,
        {
          sort_order: null,
          id: "-1",
          name: t("default.position_name"),
        },
      ]
    }
  )

export const visibleGroups: (state: GlobalState) => Array<GroupInfoType> = createSelector(
  (state) => state.view_options.group,
  visibleTeamGroups,
  getVisibleTeams,
  getCurrentLocations,
  locationByID,
  (state) => state.config.shift_slots,
  positionGroupsWithUsersIncludingPositionsWithoutGroups,
  (
    group: GroupType,
    team_groups: Array<TeamGroupType>,
    teams: Array<TeamRubyType>,
    locations: Array<LocationRubyType>,
    location_by_id: { [location_id: string]: LocationRubyType },
    shift_slots: Array<ShiftSlotType>,
    position_groups: Array<GroupInfoType>
  ) => {
    switch (group) {
      case "location": {
        const sorted_tgs: Array<GroupInfoType> = _.sortBy(
          locations.map<GroupInfoType, _>((loc) => ({
            sort_order: null,
            icon: "location-on",
            id: String(loc.id),
            name: loc.name,
          })),
          (group) => group.name
        )
        return [
          ...sorted_tgs,
          {
            sort_order: null,
            id: "-1",
            name: t("default.location_name"),
            icon: "location-on",
          },
        ]
      }
      case "team_group": {
        const sorted_tgs: Array<GroupInfoType> = _.sortBy(
          _.sortBy(
            team_groups.map<GroupInfoType, _>((tg) => {
              const name = tg.name
              const with_loc_name =
                locations.length > 1
                  ? (location_by_id[String(tg.location_id)]?.name || t("no_location")) + " · " + name
                  : name
              return {
                sort_order: tg.sort_order,
                color: tg.colour,
                id: String(tg.id),
                name: with_loc_name,
              }
            }),
            (group) => group.name
          ),
          (group) => group.sort_order
        )
        return [
          ...sorted_tgs,
          {
            sort_order: null,
            id: "-1",
            name: t("default.team_group_name"),
            color: Constants.DEFAULT_TEAM_COLOR,
          },
        ]
      }
      case "shift": {
        const sorted_shift_slots: Array<GroupInfoType> = _.sortBy(
          _.sortBy(
            shift_slots.map<GroupInfoType, _>((shift_slot) => ({
              sort_order: shift_slot.sort_order,
              icon: shift_slot.colour ? null : "view-agenda",
              color: shift_slot.colour,
              id: String(shift_slot.id),
              name: shift_slot.name,
            })),
            (group) => group.name
          ),
          (group) => group.sort_order
        )
        return [
          ...sorted_shift_slots,
          {
            sort_order: null,
            id: "-1",
            name: t("default.shift_slot_name"),
            icon: "view-agenda",
          },
        ]
      }
      case "team": {
        const sorted_ts = _.sortBy(
          _.sortBy(
            teams.map<GroupInfoType, _>((team) => {
              const name = team.department_group_name ? team.department_group_name + " · " + team.name : team.name
              const with_loc_name =
                locations.length > 1
                  ? (location_by_id[String(team.location_id)]?.name || t("no_location")) + " · " + name
                  : name
              return {
                sort_order: team.sort_order,
                name: with_loc_name,
                id: String(team.id),
                color: team.colour || Constants.DEFAULT_TEAM_COLOR,
              }
            }),
            (group) => group.name
          ),
          (group) => group.sort_order
        )
        return [
          ...sorted_ts,
          {
            sort_order: Infinity,
            id: "-1",
            name: t("default.team_name"),
            color: Constants.DEFAULT_TEAM_COLOR,
          },
        ]
      }
      case "position": {
        return position_groups
      }
      default:
        // Group by is set to none, so there is only one 'group'
        return [
          {
            sort_order: 1,
            id: "null",
            name: "",
          },
        ]
    }
  }
)

export const groupById: (state: GlobalState) => { [group_id: string]: GroupInfoType } = createSelector(
  visibleGroups,
  (groups: Array<GroupInfoType>) =>
    _.mapValues(
      _.groupBy(groups, (g) => g.id),
      (gs) => gs[0]
    )
)

export const allGroupsCollapsed: (state: GlobalState) => boolean = createSelector(
  visibleGroups,
  (state) => state.view_options.expanded_groups,
  (groups: Array<GroupInfoType>, expanded_groups: Array<string>) => {
    const groupIds = groups.map((g) => g.id)
    return groupIds.filter((id) => expanded_groups.includes(id)).length === 0
  }
)

export const leaveTypes: (state: GlobalState) => Array<AwardType> = createSelector(
  (state) => state.config.awards,
  (awards: Array<AwardType>) => awards.filter((a) => a.is_leave === true)
)

export const teamsByGroup: (state: GlobalState) => { [group_id: string]: Array<TeamRubyType> } = createSelector(
  (state) => state.view_options.group,
  teamsByLocation,
  teamByTeamGroupID,
  teamByID,
  getVisibleTeams,
  shiftSlotById,
  (
    group: GroupType,
    teams_by_location: { [location_id: string]: Array<TeamRubyType> },
    teams_by_team_group: { [team_group_id: string]: Array<TeamRubyType> },
    team_by_id: { [team_id: string]: TeamRubyType },
    getVisibleTeams: Array<TeamRubyType>,
    shift_slots_by_id: { [shift_slot_id: string]: ShiftSlotType }
  ) => {
    switch (group) {
      case "location": {
        return teams_by_location
      }
      case "team_group":
        return teams_by_team_group
      case "team":
        return _.mapValues(team_by_id, (t) => [t])
      case "shift":
        return _.mapValues(shift_slots_by_id, (t) => [...getVisibleTeams])
      default:
        return {}
    }
  }
)

export const timeNotWorkedSchedulesByGroup = (
  time_not_worked_schedules: Array<RDORubyType>,
  team_by_id: { [team_id: string]: TeamRubyType },
  team_group_by_id: { [team_group_id: string]: TeamGroupType },
  team_to_location: { [team_id: string]: LocationRubyType },
  user_ids_by_position: { [position_id: string]: Array<string> },
  group: GroupType
): {| [group_id: string]: Array<RDORubyType> |} => {
  switch (group) {
    case "location":
      return {
        [Constants.DEFAULT_TEAM_GROUP.id]: [],
        ..._.mapValues(team_group_by_id, (t) => []),
        ..._.groupBy(
          time_not_worked_schedules,
          (s) => team_to_location[String(s.department_id)]?.id || Constants.DEFAULT_LOCATION.id
        ),
      }
    case "team_group":
      return {
        [Constants.DEFAULT_TEAM_GROUP.id]: [],
        ..._.mapValues(team_group_by_id, (t) => []),
        ..._.groupBy(
          time_not_worked_schedules,
          (s) => (team_by_id[String(s.department_id)] || {}).department_group_id || Constants.DEFAULT_TEAM_GROUP.id
        ),
      }
    case "team":
      return {
        [Constants.DEFAULT_TEAM.id]: [],
        ..._.mapValues(team_by_id, (t) => []),
        ..._.groupBy(time_not_worked_schedules, (s) => s.department_id),
      }
    case "shift": // Don't group by shift slots for time not worked
      return { null: time_not_worked_schedules }
    case "position":
      const user_time_not_worked_schedules = _.groupBy(time_not_worked_schedules, (s) => s.user_id)
      return {
        [Constants.DEFAULT_POSITION.id]: [],
        ..._.mapValues(user_ids_by_position, (user_ids) =>
          user_ids.map((user_id) => user_time_not_worked_schedules[user_id] || []).flat()
        ),
      }
    default:
      return { null: time_not_worked_schedules }
  }
}

export const schedulesByGroup = (
  schedules: Array<ScheduleType>,
  team_by_id: { [team_id: string]: TeamRubyType },
  team_group_by_id: { [team_group_id: string]: TeamGroupType },
  shift_slots_by_id: { [shift_slot_id: string]: ShiftSlotType },
  shift_slots: Array<ShiftSlotType>,
  team_to_location: { [team_id: string]: LocationRubyType },
  user_ids_by_position: { [position_id: string]: Array<string> },
  group: GroupType
): {| [group_id: string]: Array<ScheduleType> |} => {
  switch (group) {
    case "location":
      return {
        [Constants.DEFAULT_TEAM_GROUP.id]: [],
        ..._.mapValues(team_group_by_id, (t) => []),
        ..._.groupBy(schedules, (s) => team_to_location[String(s.department_id)]?.id || Constants.DEFAULT_LOCATION.id),
      }
    case "team_group":
      return {
        [Constants.DEFAULT_TEAM_GROUP.id]: [],
        ..._.mapValues(team_group_by_id, (t) => []),
        ..._.groupBy(
          schedules,
          (s) => (team_by_id[String(s.department_id)] || {}).department_group_id || Constants.DEFAULT_TEAM_GROUP.id
        ),
      }
    case "team":
      return {
        [Constants.DEFAULT_TEAM.id]: [],
        ..._.mapValues(team_by_id, (t) => []),
        ..._.groupBy(schedules, (s) => s.department_id),
      }
    case "shift":
      return {
        [Constants.DEFAULT_TEAM.id]: [],
        ..._.mapValues(shift_slots_by_id, (t) => []),
        ...Schedule.groupByShiftSlots(schedules, shift_slots),
      }
    case "position":
      const user_schedules = _.groupBy(schedules, (s) => s.user_id)
      return {
        [Constants.DEFAULT_POSITION.id]: [],
        ..._.mapValues(user_ids_by_position, (user_ids) =>
          user_ids.map((user_id) => user_schedules[user_id] || []).flat()
        ),
      }
    default:
      return { null: schedules }
  }
}

export const visibleTimeNotWorkedSchedulesByGroupId: (state: GlobalState) => {
  [team_group_id: string]: Array<RDORubyType>,
} = createSelector(
  visibleRDOs,
  teamByID,
  teamGroupById,
  teamToLocation,
  userIdsByPositionGroupId,
  (state) => state.view_options.group,
  timeNotWorkedSchedulesByGroup
)

export const visibleSchedulesByGroupId: (state: GlobalState) => { [team_group_id: string]: Array<ScheduleType> } =
  createSelector(
    visibleSchedules,
    teamByID,
    teamGroupById,
    shiftSlotById,
    (state) => state.config.shift_slots,
    teamToLocation,
    userIdsByPositionGroupId,
    (state) => state.view_options.group,
    schedulesByGroup
  )

export const visibleSchedulesInCurrentFiltersByGroupId: (state: GlobalState) => {
  [group_id: string]: Array<ScheduleType>,
} = createSelector(
  visibleSchedulesForCurrentFiltersIncludingNoTeam,
  teamByID,
  teamGroupById,
  shiftSlotById,
  (state) => state.config.shift_slots,
  teamToLocation,
  userIdsByPositionGroupId,
  (state) => state.view_options.group,
  schedulesByGroup
)

export const visibleSchedulesByDateByGroupId: (state: GlobalState) => {
  [team_group_id: string]: { [date: string]: Array<ScheduleType> },
} = createSelector(
  visibleSchedulesInCurrentFiltersByGroupId,
  (schedules_by_group: { [team_group_id: string]: Array<ScheduleType> }) =>
    _.mapValues(schedules_by_group, (schedules) => _.groupBy(schedules, (s) => s.date))
)

export const schedulesByDateByGroup = (
  schedules_by_group_id: { [date: string]: { [group_id: string]: Array<ScheduleType> } },
  schedules: Array<ScheduleType>,
  user_ids_by_position_by_date: { [date: string]: { [position_id: string]: Array<string> } },
  group: GroupType
): {| [date: string]: { [group_id: string]: Array<ScheduleType> } |} => {
  switch (group) {
    case "position":
      const with_schedules = {
        ..._.transform(user_ids_by_position_by_date, (result, position_id_to_user_ids, date) => {
          const user_schedules: { [user_id: string]: Array<ScheduleType> } = _.groupBy(
            schedules.filter((schedule) => schedule.date === date),
            ({ user_id }) => user_id
          )
          result[date] = _.mapValues(position_id_to_user_ids, (user_ids) =>
            user_ids.map((user_id) => user_schedules[user_id] || []).flat()
          )
        }),
      }
      return with_schedules
    default:
      return schedules_by_group_id
  }
}
export const visibleSchedulesInCurrentFiltersByGroupIdByDate: (state: GlobalState) => {
  [date: string]: { [group_id: string]: Array<ScheduleType> },
} = createSelector(
  visibleSchedulesInCurrentFiltersByGroupId,
  (schedules_by_group_id: {
    [group_id: string]: Array<ScheduleType>,
  }): { [date: string]: { [group_id: string]: Array<ScheduleType> } } =>
    _.transform(
      schedules_by_group_id,
      (result, schedules, group_id) => {
        if (!schedules.length) {
          result["-1"] = {}
          result["-1"][group_id] = []
        }
        _.forEach(schedules, (schedule) => {
          const date = schedule.date
          if (!result[date]) {
            result[date] = {}
          }
          if (!result[date][group_id]) {
            result[date][group_id] = []
          }
          result[date][group_id].push(schedule)
        })
      },
      {}
    )
)

export const visibleSchedulesInCurrentFiltersByDateByGroupId: (state: GlobalState) => {
  [date: string]: { [group_id: string]: Array<ScheduleType> },
} = createSelector(
  visibleSchedulesInCurrentFiltersByGroupIdByDate,
  visibleSchedulesForCurrentFiltersIncludingNoTeam,
  userIdsByPositionGroupIdByDate,
  (state) => state.view_options.group,
  schedulesByDateByGroup
)

export const dateDrivenSchedulesByGroupID = (schedules_by_date_by_group: {
  [date: string]: { [group_id: string]: Array<ScheduleType> },
}): { [string]: Array<ScheduleType> } =>
  _.transform(
    schedules_by_date_by_group,
    (result, value, key) =>
      _.mergeWith(result, value, (objSchedules: Array<ScheduleType>, srcSchedules: Array<ScheduleType>) => {
        if (_.isArray(objSchedules)) {
          return objSchedules.concat(srcSchedules)
        }
      }),
    {}
  )

export const rosteredScheduleHoursByGroup: (state: GlobalState) => {
  [group_id: string]: { total_hours: number, with_assisting: number },
} = createSelector(
  visibleSchedulesInCurrentFiltersByDateByGroupId,
  simpleScheduleByID,
  (
    schedules_by_date_by_group: { [date: string]: { [group_id: string]: Array<ScheduleType> } },
    simple_schedule_by_id: { [schedule_id: string]: SimpleScheduleType }
  ): { [group_id: string]: { total_hours: number, with_assisting: number } } => {
    const groupedDatedScheduleData: { [string]: Array<ScheduleType> } =
      dateDrivenSchedulesByGroupID(schedules_by_date_by_group)

    return _.mapValues(groupedDatedScheduleData, (schedules) => {
      const mins = Schedule.sumRosteredHoursForSimpleSchedules(
        schedules.map((s) => simple_schedule_by_id[String(s.id)] || Constants.DEFAULT_SIMPLE_SCHEDULE)
      )
      return {
        total_hours: mins / 60,
        with_assisting: mins / 60,
      }
    })
  }
)

export const visibleDateDrivenSchedulesInCurrentFiltersByGroupId: (state: GlobalState) => {
  [group_id: string]: Array<ScheduleType>,
} = createSelector(visibleSchedulesInCurrentFiltersByDateByGroupId, dateDrivenSchedulesByGroupID)

export const visibleDateDrivenSchedulesByUserByGroupId: (state: GlobalState) => {
  [team_group_id: string]: { [user_id: string]: Array<ScheduleType> },
} = createSelector(
  visibleDateDrivenSchedulesInCurrentFiltersByGroupId,
  (schedules_by_group_id: { [team_group_id: string]: Array<ScheduleType> }) =>
    _.mapValues(schedules_by_group_id, (ss) => _.groupBy(ss, (s) => s.user_id))
)

export const visibleSchedulesByUserByGroupId: (state: GlobalState) => {
  [team_group_id: string]: { [user_id: string]: Array<ScheduleType> },
} = createSelector(
  visibleSchedulesInCurrentFiltersByGroupId,
  (schedules_by_group_id: { [team_group_id: string]: Array<ScheduleType> }) =>
    _.mapValues(schedules_by_group_id, (ss) => _.groupBy(ss, (s) => s.user_id))
)

export const schedulesByDateByPersonByGroup: (state: GlobalState) => {
  [group_id: string]: { [user_id: string]: { [date: string]: Array<ScheduleType> } },
} = createSelector(
  visibleDateDrivenSchedulesByUserByGroupId,
  (schedules_by_person_by_group: { [group_id: string]: { [user_id: string]: Array<ScheduleType> } }) =>
    _.mapValues(schedules_by_person_by_group, (schedules_by_person) =>
      _.mapValues(schedules_by_person, (schedules) => _.groupBy(schedules, (s) => s.date))
    )
)

export const visibleTimeNotWorkedSchedulesByUserByGroupId: (state: GlobalState) => {
  [team_group_id: string]: { [user_id: string]: Array<RDORubyType> },
} = createSelector(
  visibleTimeNotWorkedSchedulesByGroupId,
  (time_not_worked_schedules_by_group_id: { [team_group_id: string]: Array<RDORubyType> }) =>
    _.mapValues(time_not_worked_schedules_by_group_id, (rdos) => _.groupBy(rdos, (rdo) => rdo.user_id))
)

export const selectedSchedules: (state: GlobalState) => Array<ScheduleType> = createSelector(
  schedulesByDateByPersonByGroup,
  (state) => state.focus.selected_schedule_container_data,
  (
    schedules_by_date_by_person_by_group: {
      [group_id: string]: { [user_id: string]: { [date: string]: Array<ScheduleType> } },
    },
    maybe_selected_schedule_date: ?SelectedScheduleContainerDataType
  ) => {
    if (maybe_selected_schedule_date == null) {
      return EMPTY_ARR
    } else {
      const selected_schedule_date = maybe_selected_schedule_date
      const all_schedules =
        schedules_by_date_by_person_by_group[String(selected_schedule_date.group_id)]?.[
          String(selected_schedule_date.user_id)
        ]?.[selected_schedule_date.date] || EMPTY_ARR
      if (selected_schedule_date.user_id === Constants.DEFAULT_USER.id && selected_schedule_date.schedule_id != null) {
        return all_schedules.filter((s) => selected_schedule_date.schedule_id === s.id)
      } else {
        return all_schedules
      }
    }
  }
)

export const schedulesForDateByGroup = (state: GlobalState, date: string, group_id: ?string): Array<ScheduleType> => {
  const EMPTY_ARR = []
  if (!group_id || typeof group_id !== "string") {
    return visibleSchedulesForCurrentFiltersIncludingNoTeamByDate(state)[date] || EMPTY_ARR
  }
  return visibleSchedulesInCurrentFiltersByDateByGroupId(state)[date]?.[group_id] || EMPTY_ARR
}

export const selectedScheduleIDs: (state: GlobalState) => Array<number> = createSelector(
  selectedSchedules,
  (selected_schedules: Array<ScheduleType>) => selected_schedules.map((s) => s.id)
)

export const schedulesToRenderByUserByGroup: (state: GlobalState) => {
  [group_id: string]: { [user_id: string]: Array<ScheduleType> },
} = createSelector(
  visibleSchedulesByUser,
  visibleSchedulesByUserByGroupId,
  (
    schedules_by_person: { [user_id: string]: Array<ScheduleType> },
    schedules_by_user_by_group_id: { [team_group_id: string]: { [user_id: string]: Array<ScheduleType> } }
  ) =>
    _.mapValues(schedules_by_user_by_group_id, (sched_by_user) => ({
      ...schedules_by_person,
      [Constants.DEFAULT_USER.id]: sched_by_user[String(Constants.DEFAULT_USER.id)] || [],
    }))
)
export const dayAfterCurrent: (state: GlobalState) => string = createSelector(finishStr, (date: string) =>
  HelperFunc.addDaysToDate(date, 1)
)

// We add in the next days schedules so we can visualise them inside the day view staff view
export const schedulesToRenderForDayViewByUserByGroup: (state: GlobalState) => {
  [group_id: string]: { [user_id: string]: Array<ScheduleType> },
} = createSelector(
  schedulesToRenderByUserByGroup,
  schedulesByDateByUser,
  dayAfterCurrent,
  (
    schedules_to_render_by_user_by_group: { [group_id: string]: { [user_id: string]: Array<ScheduleType> } },
    schedules_by_date_by_user: { [user_id: string]: { [date_str: string]: Array<ScheduleType> } },
    date_after: string
  ) =>
    _.mapValues(schedules_to_render_by_user_by_group, (sched_by_user, group_id) =>
      _.mapValues(sched_by_user, (schedules, user_id) => [
        ...(schedules_by_date_by_user[user_id]?.[date_after] || []),
        ...schedules,
      ])
    )
)

export const schedulesToRenderByDateByPersonByGroup: (state: GlobalState) => {
  [group_id: string]: { [user_id: string]: { [date: string]: Array<ScheduleType> } },
} = createSelector(
  schedulesToRenderByUserByGroup,
  (schedules_by_person_by_group: { [group_id: string]: { [user_id: string]: Array<ScheduleType> } }) =>
    _.mapValues(schedules_by_person_by_group, (schedules_by_person) =>
      _.mapValues(schedules_by_person, (schedules) => _.groupBy(schedules, (s) => s.date))
    )
)

export const mostCardsInSingleDayByUser: (state: GlobalState) => { [user_id: string]: number } = createSelector(
  visibleSchedulesByDateByUser,
  visibleUnavailabilityByDateByUser,
  visibleRDOsByDateByUser,
  visibleLeaveByDateByUser,
  visibleUsers,
  allVisibleDatesStr,
  (
    schedules_by_date_by_person: { [user_id: string]: { [date: string]: Array<ScheduleType> } },
    visible_unavailability_by_date_by_user: { [user_id: string]: { [date: string]: Array<UnavailabilityRubyType> } },
    visible_rdos_by_date_by_user: { [user_id: string]: { [date: string]: Array<RDORubyType> } },
    visible_leave_by_date_by_user: { [user_id: string]: { [date: string]: Array<LeaveRequestRubyType> } },
    visible_users: Array<UserType>,
    dates: Array<string>
  ) =>
    visible_users.reduce((acc, u) => {
      const u_id = String(u.id)
      const eachDay = dates.map(
        (date) =>
          (schedules_by_date_by_person[u_id]?.[date] || []).length +
          (visible_unavailability_by_date_by_user[u_id]?.[date] || []).length +
          (visible_rdos_by_date_by_user[u_id]?.[date] || []).length +
          (visible_leave_by_date_by_user[u_id]?.[date] || []).length
      )
      acc[u.id] = _.max(eachDay)
      return acc
    }, {})
)

export const sortedUsers: (state: GlobalState) => Array<UserType> = createSelector(
  visibleUsers,
  (state) => state.view_options.sort_staff,
  (visibleUsers: Array<UserType>, sort: SortStaffOptions) => User.sort(visibleUsers, sort)
)

export const userSortOrder: (state: GlobalState) => { [user_id: string]: number } = createSelector(
  sortedUsers,
  (sortedUsers: Array<UserType>) => _.fromPairs(sortedUsers.map((user, indx) => [String(user.id), indx]))
)

export const staffViewRows: (state: GlobalState) => Array<RowType> = createSelector(
  visibleGroups,
  visibleUsersByGroupId,
  (state) => state.view_options.expanded_groups,
  (state) => state.view_options.sort_staff,
  (
    visibleGroups: Array<GroupInfoType>,
    visibleUsersByGroup: { [team_group_id: string]: Array<UserType> },
    expandedGroups: Array<string>,
    sort: SortStaffOptions
  ) => {
    if (visibleGroups.length === 1 && visibleGroups[0].id === "null") {
      return [
        ...User.sort(visibleUsersByGroup["null"] || [], sort).map((u) => ({
          type: "user",
          id: String(u.id),
          groupId: "null",
        })),
        {
          type: "user",
          id: String(Constants.DEFAULT_USER.id),
          groupId: "null",
        },
        {
          type: "createShiftRow",
          id: "null",
          groupId: "null",
        },
      ]
    } else {
      return [
        // Only Show the groupBy header if there are actually groups to show
        visibleGroups.length === 1 && visibleGroups[0].id === "null"
          ? null
          : {
              type: "groupingHeader",
              id: "0",
              groupId: "0",
            },
        ...visibleGroups.flatMap((g) => [
          {
            type: "groupHeader",
            id: g.id,
            groupId: g.id,
          },
          ...(!expandedGroups.includes(g.id)
            ? []
            : User.sort(visibleUsersByGroup[String(g.id)] || [], sort).map((u) => ({
                type: "user",
                id: String(u.id),
                groupId: g.id,
              }))),
          !expandedGroups.includes(g.id)
            ? null
            : {
                type: "user",
                id: String(Constants.DEFAULT_USER.id),
                groupId: g.id,
              },
          expandedGroups.includes(g.id)
            ? {
                type: "createShiftRow",
                id: g.id,
                groupId: g.id,
              }
            : null,
        ]),
      ].filter(Boolean)
    }
  }
)

export const userRowVisibleByGroup: (state: GlobalState) => { [group_id: string]: { [user_id: string]: boolean } } =
  createSelector(
    visibleUnavailabilityByUser,
    visibleLeaveByUser,
    visibleDateDrivenSchedulesByUserByGroupId,
    (state) => state.view_options.hide_people_with_no_shifts,
    (state) => state.view_options.only_show_time_off,
    visibleUsersByGroupId,
    (
      unavailabilityByUser: { [user_id: string]: Array<UnavailabilityRubyType> },
      leaveByUser: { [user_id: string]: Array<LeaveRequestRubyType> },
      schedules: { [team_group_id: string]: { [user_id: string]: Array<ScheduleType> } },
      hide_people_with_no_shifts: boolean,
      only_show_time_off: boolean,
      users_by_group_id: { [team_group_id: string]: Array<UserType> }
    ) =>
      _.mapValues(users_by_group_id, (users, group_id) =>
        users.reduce(
          (acc, u) => {
            const no_time_off =
              (leaveByUser[String(u.id)] || []).length + (unavailabilityByUser[String(u.id)] || []).length === 0
            const hidden =
              hide_people_with_no_shifts &&
              (schedules[group_id]?.[String(u.id)] || []).length === 0 &&
              (!only_show_time_off || no_time_off)
            acc[String(u.id)] = !hidden
            return acc
          },
          {
            "-1": true,
          }
        )
      )
  )

export const rowHeights: (state: GlobalState) => Array<number> = createSelector(
  staffViewRows,
  mostCardsInSingleDayByUser,
  schedulesToRenderByDateByPersonByGroup,
  userRowVisibleByGroup,
  allVisibleDatesStr,
  (state) => state.loading.loading_dates.length > 0,
  (state) => state.view_options.collapse_multiple_cards,
  (
    rows: Array<RowType>,
    cards_by_user: { [user_id: string]: number },
    schedules_by_date_by_person_by_group: {
      [group_id: string]: { [user_id: string]: { [date: string]: Array<ScheduleType> } },
    },
    user_row_visible_by_group: { [group_id: string]: { [user_id: string]: boolean } },
    dates: Array<string>,
    loading: boolean,
    collapseMultipleCards: boolean
  ) =>
    rows.map((row) => {
      switch (row.type) {
        case "groupingHeader":
          return 40
        case "groupHeader":
          return 44
        case "user":
          let cardCount: number = 0
          if (user_row_visible_by_group[String(row.groupId)]?.[row.id] === false) {
            return 0
          }
          if (row.id === String(Constants.DEFAULT_USER.id)) {
            cardCount = _.max(
              dates.map<number, _>(
                (date) =>
                  (schedules_by_date_by_person_by_group[row.groupId]?.[String(Constants.DEFAULT_USER.id)]?.[date] || [])
                    .length
              )
            )
          } else {
            cardCount = cards_by_user[String(row.id)]
          }
          if (cardCount !== 0 && collapseMultipleCards) {
            cardCount = 1
          }
          return Math.max(cardCount === 0 ? 30 : 50 + (cardCount - 1) * 42, loading ? 50 : 0)
        case "createShiftRow":
        default:
          return 50
      }
    })
)

export const minCardWidth: (state: GlobalState) => number = createSelector(
  largeCards,
  (state) => state.view_options.show_teams_on_small_cards,
  (state) => state.settings.print_mode,
  (large_cards: boolean, show_teams: boolean, print_mode: boolean) => {
    const small = 3 * 16
    const medium = 4.5 * 16
    const large = 9.5 * 16
    if (print_mode) {
      return small
    } else if (large_cards) {
      return large
    } else if (show_teams) {
      return medium
    } else {
      return small
    }
  }
)

export const minStaffViewWidth: (state: GlobalState) => number = createSelector(
  minCardWidth,
  allVisibleDatesStr,
  numberOfPayPeriodsVisible,
  (minCardWidth: number, allVisibleDatesStr: Array<string>, pay_periods: number) =>
    minCardWidth * allVisibleDatesStr.length + 6 * 16 + (pay_periods - 1) * 12
)

export const isHighlighting: (state: GlobalState) => boolean = createSelector(
  (state) => state.view_options.highlight_validation_errors,
  (state) => state.view_options.highlight_unpublished_shifts,
  (state) => state.view_options.highlight_overtime,
  (state) => state.view_options.highlight_shifts_needing_acceptance,
  (state) => state.view_options.highlight_vacant_shifts,
  (
    highlight_validation_errors: boolean,
    highlight_unpublished_shifts: boolean,
    highlight_overtime: boolean,
    highlight_shifts_needing_acceptance: boolean,
    highlight_vacant_shifts: boolean
  ) =>
    highlight_validation_errors ||
    highlight_unpublished_shifts ||
    highlight_overtime ||
    highlight_shifts_needing_acceptance ||
    highlight_vacant_shifts
)

export const scheduleHighlighted: (state: GlobalState) => { [schedule_id: string]: boolean } = createSelector(
  visibleScheduleByID,
  (state) => state.view_options.highlight_validation_errors,
  (state) => state.view_options.highlight_unpublished_shifts,
  (state) => state.view_options.highlight_overtime,
  (state) => state.view_options.highlight_shifts_needing_acceptance,
  (state) => state.view_options.highlight_vacant_shifts,
  visibleValidationErrorsExcludingOvertimeScheduleIds,
  visibleSchedulesIdsThatNeedPublishing,
  scheduleIdsWithOvertime,
  scheduleIdsPendingAcceptanceForVisibleUsers,
  vacantScheduleIds,
  (
    visible_schedule_by_id: { [schedule_id: string]: ScheduleType },
    highlight_validation_errors: boolean,
    highlight_unpublished_shifts: boolean,
    highlight_overtime: boolean,
    highlight_shifts_needing_acceptance: boolean,
    highlight_vacant_shifts: boolean,
    schedule_ids_with_errors: Array<number>,
    schedule_ids_that_need_publishing: Array<number>,
    schedule_ids_with_overtime: Array<number>,
    visible_schedule_ids_pending_acceptance: Array<number>,
    vacant_schedule_ids: Array<number>
  ) =>
    _.mapValues(
      visible_schedule_by_id,
      (s) =>
        (highlight_validation_errors && schedule_ids_with_errors.includes(s.id)) ||
        (highlight_unpublished_shifts && schedule_ids_that_need_publishing.includes(s.id)) ||
        (highlight_overtime && schedule_ids_with_overtime.includes(s.id)) ||
        (highlight_shifts_needing_acceptance && visible_schedule_ids_pending_acceptance.includes(s.id)) ||
        (highlight_vacant_shifts && vacant_schedule_ids.includes(s.id))
    )
)

export const partiallyHiddenByDateByPersonByGroup: (state: GlobalState) => {
  [group_id: string]: { [user_id: string]: { [date: string]: boolean } },
} = createSelector(
  schedulesToRenderByDateByPersonByGroup,
  scheduleHighlighted,
  visibleGroups,
  visibleUsers,
  allVisibleDatesStr,
  (state) => state.view_options.highlight_unpublished_shifts,
  visibleDailyScheduleJoinsThatNeedPublishingByDateByUser,
  (
    schedules_by_date_by_person_by_group: {
      [group_id: string]: { [user_id: string]: { [date: string]: Array<ScheduleType> } },
    },
    highlighted: { [sched_id: string]: boolean },
    visibleGroups: Array<GroupInfoType>,
    visibleUsers: Array<UserType>,
    dates: Array<string>,
    highlight_unpublished_shifts: boolean,
    daily_schedule_joins_that_need_publishing_by_date_by_user: {
      [user_id: string]: { [date: string]: UserDailyScheduleJoinType },
    }
  ) =>
    visibleGroups.reduce((acc1, group) => {
      acc1[group.id] = visibleUsers.reduce((acc2, user) => {
        acc2[String(user.id)] = dates.reduce((acc3, date) => {
          const schedules = schedules_by_date_by_person_by_group[group.id]?.[String(user.id)]?.[date] || []
          // The day / user combo is highlighted if any schedule inside is highlighted or if we're highlighting cells that need publishing
          acc3[date] =
            schedules.every((s) => !highlighted[String(s.id)]) &&
            !(
              highlight_unpublished_shifts &&
              daily_schedule_joins_that_need_publishing_by_date_by_user[String(user.id)]?.[date] != null
            )
          return acc3
        }, {})
        return acc2
      }, {})
      return acc1
    }, {})
)

export const predictedChartData: (state: GlobalState) => Array<GenericChartDataType> = createSelector(
  predictionsByDate,
  startStr,
  dataStreamByID,
  (
    predictionsByDate: { [date: string]: Array<DateData> },
    date: string,
    dataStreamByID: { [id: string]: DataStreamRubyType }
  ) => {
    const predictions = predictionsByDate[date] || []
    return predictions.map((prediction) => ({
      chart_type: "solidBar",
      color: Constants.CHART_COLORS.predicted_color,
      data: prediction.stat_by_15,
      group: "demand_data",
      type: "predicted",
      key: DemandData.getUniqKeyForBreakdown(prediction),
      name: t("chart.predicted_data_stream", { name: dataStreamByID[String(prediction.data_stream_id)]?.name }),
      y_axis_key: prediction.stat_type,
    }))
  }
)

export const noTimesBreakLengthForDate: (state: GlobalState) => number = createSelector(
  activeUserIdsForCurrentPeriod,
  getVisibleTeams,
  visibleSchedulesByTeamByDate,
  startStr,
  (
    active_user_ids: Array<number>,
    visible_teams: Array<TeamRubyType>,
    scheds_by_date_by_team: { [date: string]: { [team_id: string]: Array<ScheduleType> } },
    date: string
  ) => {
    const visible_team_ids = visible_teams.map((d) => d.id)
    const scheds_by_team = scheds_by_date_by_team[date]
    if (!scheds_by_team || !visible_team_ids) {
      return 0
    }

    let hours = 0

    for (const team_id in scheds_by_team) {
      if (team_id !== Constants.DEFAULT_TEAM.id && !visible_team_ids.includes(Number(team_id))) {
        continue
      }
      const scheds = scheds_by_team[team_id]
      const sum = scheds
        .map((sched) => {
          if (sched.user_id !== Constants.DEFAULT_USER.id && !active_user_ids.includes(sched.user_id)) {
            return 0
          }
          return Schedule.automaticBreakLengthInPeriod(sched) || 0
        })
        .reduce((acc, num) => acc + num, 0)
      hours += sum
    }
    return hours
  }
)

export const actualChartData: (state: GlobalState) => Array<GenericChartDataType> = createSelector(
  demandDataForCurrentLocationsByDate,
  startStr,
  dataStreamByID,
  (
    demandDataForCurrentLocationByDate: { [date: string]: Array<DateData> },
    date: string,
    dataStreamByID: { [id: string]: DataStreamRubyType }
  ) => {
    const data = demandDataForCurrentLocationByDate[date] || []
    return data.map((datum) => ({
      chart_type: "solidBar",
      color: Constants.CHART_COLORS.actual_color,
      data: datum.stat_by_15,
      group: "demand_data",
      type: "actual",
      line_type: "stepAfter",
      key: DemandData.getUniqKeyForBreakdown(datum),
      name: t("chart.actual_data_stream", { name: dataStreamByID[String(datum.data_stream_id)]?.name }),
      y_axis_key: datum.stat_type,
    }))
  }
)
const extractGenericChartDataFromProcessed = (
  data: Array<DepartmentWithStatsType>,
  chartType: ChartTypes,
  color: string,
  type: string,
  key: string
): Array<GenericChartDataType> => {
  const teams = data.map((team: DepartmentWithStatsType) => ({
    chart_type: chartType,
    color: color,
    data: team.stat_by_15,
    key: String(team.id),
    group: "team",
    type: type,
    line_type: "stepAfter",
    name: t("chart.team_name." + type, { name: team.name }),
    y_axis_key: "staff_count",
  }))
  const total_data = teams.reduce((acc, team) => merge_and_add_stats(team.data, acc), {})
  const total: GenericChartDataType = {
    chart_type: chartType,
    color: color,
    data: total_data,
    key: "total",
    group: "team",
    type: type,
    line_type: "stepAfter",
    name: t("chart." + key),
    y_axis_key: "staff_count",
  }
  return [total, ...teams]
}
export const rosteredChartData: (state: GlobalState) => Array<GenericChartDataType> = createSelector(
  visibleRosteredHoursByDepByDate,
  startStr,
  dataStreamByID,
  (
    visibleRosteredHoursByDepByDate: { [date: string]: { [team_id: string]: DepartmentWithStatsType } },
    date: string,
    dataStreamByID: { [id: string]: DataStreamRubyType }
  ) => {
    // $FlowFixMe bleh
    const data: Array<DepartmentWithStatsType> = Object.values(
      visibleRosteredHoursByDepByDate[date] || Object.freeze({})
    )
    return extractGenericChartDataFromProcessed(
      data,
      "solidLine",
      Constants.CHART_COLORS.rostered_color,
      "rostered",
      Constants.CHART_KEYS.total_rostered_staff
    )
  }
)

export const timesheetChartData: (state: GlobalState) => Array<GenericChartDataType> = createSelector(
  visibleTimesheetHoursByDepByDate,
  startStr,
  dataStreamByID,
  (
    visibleTimesheetHoursByDepByDate: { [date: string]: { [team_id: string]: DepartmentWithStatsType } },
    date: string,
    dataStreamByID: { [id: string]: DataStreamRubyType }
  ) => {
    // $FlowFixMe bleh
    const data: Array<DepartmentWithStatsType> = Object.values(
      visibleTimesheetHoursByDepByDate[date] || Object.freeze({})
    )
    return extractGenericChartDataFromProcessed(
      data,
      "solidLine",
      Constants.CHART_COLORS.timesheet_color,
      "timesheet",
      Constants.CHART_KEYS.total_timesheet_staff
    )
  }
)

export const recommendedRosteredChartData: (state: GlobalState) => Array<GenericChartDataType> = createSelector(
  visibleProjectedDepartmentDataByDate,
  startStr,
  dataStreamByID,
  (
    visibleProjectedDepartmentDataByDate: { [date: string]: Array<DepartmentWithStatsOnDateType> },
    date: string,
    dataStreamByID: { [id: string]: DataStreamRubyType }
  ) => {
    // $FlowFixMe bleh
    const data: Array<DepartmentWithStatsType> = Object.values(
      visibleProjectedDepartmentDataByDate[date] || Object.freeze({})
    )
    return extractGenericChartDataFromProcessed(
      data,
      "dottedLine",
      Constants.CHART_COLORS.rostered_color,
      "recommended",
      Constants.CHART_KEYS.total_recommended_staff
    )
  }
)

export const idealRosteredChartData: (state: GlobalState) => Array<GenericChartDataType> = createSelector(
  visibleIdealDepartmentDataByDate,
  startStr,
  dataStreamByID,
  (
    visibleIdealDepartmentDataByDate: { [date: string]: Array<DepartmentWithStatsType> },
    date: string,
    dataStreamByID: { [id: string]: DataStreamRubyType }
  ) => {
    // $FlowFixMe bleh
    const data: Array<DepartmentWithStatsType> = Object.values(
      visibleIdealDepartmentDataByDate[date] || Object.freeze({})
    )
    return extractGenericChartDataFromProcessed(
      data,
      "dottedLine",
      Constants.CHART_COLORS.timesheet_color,
      "ideal",
      Constants.CHART_KEYS.total_ideal_staff
    )
  }
)

export const toolChartData: (state: GlobalState) => Array<GenericChartDataType> = createSelector(
  visibleIdealDepartmentDataByDate,
  startStr,
  dataStreamByID,
  (
    visibleIdealDepartmentDataByDate: { [date: string]: Array<DepartmentWithStatsType> },
    date: string,
    dataStreamByID: { [id: string]: DataStreamRubyType }
  ) => {
    // $FlowFixMe bleh
    const data: Array<DepartmentWithStatsType> = Object.values(
      visibleIdealDepartmentDataByDate[date] || Object.freeze({})
    )
    return extractGenericChartDataFromProcessed(
      data,
      "dottedLine",
      Constants.CHART_COLORS.timesheet_color,
      "ideal",
      Constants.CHART_KEYS.total_ideal_staff
    )
  }
)

export const loadingDemand: (state: GlobalState) => boolean = createSelector(
  (state) => state.loading.requests_in_progress,
  (state) => state.loading.loading_dates,
  (requests: RequestsInProgressType, dates: Array<string>) =>
    dates.length > 0 ||
    requests.filter((r) => r.type === "loading_actual_demand" || r.type === "loading_predicted_demand").length > 0
)

export const loadingPredictedDemand: (state: GlobalState) => boolean = createSelector(
  (state) => state.loading.requests_in_progress,
  (state) => state.loading.loading_dates,
  (requests: RequestsInProgressType, dates: Array<string>) =>
    dates.length > 0 || requests.filter((r) => r.type === "loading_predicted_demand").length > 0
)

export const loadingLocationData: (state: GlobalState) => boolean = createSelector(
  (state) => state.loading.requests_in_progress,
  (requests: RequestsInProgressType) => requests.filter((r) => r.type === "loading_location_data").length > 0
)

export const loadingDataPromise: (state: GlobalState) => Promise<mixed> = createSelector(
  (state) => state.loading.requests_in_progress,
  (requests: RequestsInProgressType) => Promise.all(requests.filter((r) => r.type === "loading").map((r) => r.promise))
)

export const loadingStateRequests: (state: GlobalState) => RequestsInProgressType = createSelector(
  (state) => state.loading.requests_in_progress,
  (requests: RequestsInProgressType) => {
    const requests_we_care_about = ["saving", "creating", "loading"]
    return requests.filter((r) => requests_we_care_about.includes(r.type))
  }
)
