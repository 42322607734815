/* @flow */

import * as React from "react"
import { t as globalT } from "helpers/i18n"
import * as Routes from "helpers/routes"
import Modal, { Header } from "../../components/Modal"
import Icon from "../../components/Icon"
import type { WidgetActionDelegator } from "../widgets"
import Text from "../../components/Text"
import Button from "../../components/Button"
import WidgetBox from "./WidgetBox"
import styles from "./styles.module.scss"

const t = (key, ...args) => globalT(`js.dashboard.widgets.${key}`, ...args)

type JqueryWidget = { codeName: string, id: string }
type ReactWidget = { codeName: string, delegator: WidgetActionDelegator }
type ReportWidget = { codeName: string, description: string, iconName: string | null, id: string, name: string }

type Delegators = {|
  liveInsights: WidgetActionDelegator,
|}

export type Props = {|
  delegators: Delegators,
|}

const getHiddenWidgets = (
  delegators: Delegators
): {| jqueryWidgets: Array<JqueryWidget>, reactWidgets: Array<ReactWidget>, reportWidgets: Array<ReportWidget> |} => {
  const jqueryWidgets = [
    { id: "#dailyshiftsummary", codeName: "daily_shift_summary" },
    { id: "#widget-live-feed", codeName: "live_feed" },
    { id: "#mytimeclocks", codeName: "my_timeclocks" },
    { id: "#orgsummary", codeName: "org_summary" },
    { id: "#rightnow", codeName: "right_now" },
    { id: "#tasklist", codeName: "task_list" },
    { id: "#birthday", codeName: "birthday" },
    { id: "#leave_calendar", codeName: "leave_calendar" },
  ].filter((w) => window.$(w.id).parents(".widget").hasClass("widget-hidden"))

  const reactWidgets = [{ codeName: "live_insights", delegator: delegators.liveInsights }].filter(
    (w) => !w.delegator.isWidgetVisible()
  )

  const embeddedReportWidgets = window
    .$("[data-embedded-report-id]")
    .toArray()
    .filter((el) => window.$(el).parents(".widget").hasClass("widget-hidden"))
    .map((el) => ({
      codeName: "embedded_report",
      description: el.dataset.embeddedReportDescription,
      id: el.dataset.embeddedReportId,
      name: el.dataset.embeddedReportName,
    }))

  const savedReportWidgets = window
    .$("[data-saved-report-id]")
    .toArray()
    .filter((el) => window.$(el).parents(".widget").hasClass("widget-hidden"))
    .map((el) => ({
      codeName: "saved_report",
      description: el.dataset.savedReportDescription,
      id: el.dataset.savedReportId,
      name: el.dataset.savedReportName,
      iconName: "insert-chart-outlined",
    }))

  return {
    jqueryWidgets,
    reactWidgets,
    reportWidgets: [...embeddedReportWidgets, ...savedReportWidgets],
  }
}

export default function AddNewWidget(props: Props): React.Node {
  const [availableWidgets, setAvailableWidgets] = React.useState({
    jqueryWidgets: [],
    reactWidgets: [],
    reportWidgets: [],
  })
  const [isHovered, setHovered] = React.useState(false)
  const [isModalOpen, setModalOpen] = React.useState(false)

  const plusClick = () => {
    setAvailableWidgets(getHiddenWidgets(props.delegators))
    setModalOpen(true)
  }

  const showJqueryWidget = (widgetId: string) => {
    setModalOpen(false)
    window.$(widgetId).widget("show_widget")
  }

  const showReportWidget = (widgetId: string, codeName: string) => {
    setModalOpen(false)
    if (codeName === "embedded_report") {
      window.$(`[data-embedded-report-id="${widgetId}"]`).widget("show_widget")
    } else {
      window.$(`[data-saved-report-id="${widgetId}"]`).widget("show_widget")
    }
  }

  const showReactWidget = (delegator: WidgetActionDelegator) => {
    setModalOpen(false)
    delegator.showWidget()
  }

  const renderJqueryWidgetBox = (w) => (
    <WidgetBox codeName={w.codeName} key={w.codeName} onClick={() => showJqueryWidget(w.id)} />
  )

  const renderReactWidgetBox = (w) => (
    <WidgetBox codeName={w.codeName} key={w.codeName} onClick={() => showReactWidget(w.delegator)} />
  )

  const renderReportWidgetBox = (w) => (
    <WidgetBox
      codeName={w.codeName}
      description={w.description}
      iconName={w.iconName}
      key={w.id}
      name={w.name}
      onClick={() => showReportWidget(w.id, w.codeName)}
    />
  )

  return (
    <React.Fragment>
      <Modal fixedMaxHeight fixedTop onExit={() => setModalOpen(false)} open={isModalOpen} size="l" yOverflow>
        <Header titleText={t("empty.title")} />
        <div className={styles.boxContainer}>
          {availableWidgets.jqueryWidgets.map(renderJqueryWidgetBox)}
          {availableWidgets.reactWidgets.map(renderReactWidgetBox)}
          {availableWidgets.reportWidgets.map(renderReportWidgetBox)}
          {!availableWidgets.reactWidgets.length && !availableWidgets.jqueryWidgets.length && (
            <div className={styles.emptyWidgets}>
              <Text align="center" color="lightest" type="small">
                {t("empty.all_widgets_enabled")}
              </Text>
            </div>
          )}
          <div className={styles.widgetOptions}>
            <div className={styles.suggestion}>
              <Button
                iconLeft="how-to-vote"
                label={t("empty.suggest_idea")}
                link="https://tanda.canny.io/new-ideas"
                newTab
                type="theme"
                weight="secondary"
              />
            </div>
            <div className={styles.suggestion}>
              <Button
                iconLeft="add-to-homescreen"
                label={t("empty.add_custom")}
                link={Routes.reports_platform_store_index_path()}
                type="theme"
              />
            </div>
          </div>
        </div>
      </Modal>
      <div
        className={styles.container}
        onClick={plusClick}
        onKeyPress={plusClick}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        role="button"
        tabIndex={-1}
      >
        <Icon color={isHovered ? "primary" : "grey"} size="xl" type="add" />
        <Text align="center" animateColor color={isHovered ? "primary" : "lightest"}>
          Add Widget
        </Text>
      </div>
    </React.Fragment>
  )
}
